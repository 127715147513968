/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.QuickDeliveryItems {
    @include desktop {
        margin-bottom: 3em;
    }
    @include mobile {
        margin-bottom: 3em;
    }
    .ExpandableContent-Button {
        @include mobile {
            background-color: antiquewhite;
            padding: 14px !important;
            border-radius: 10px;
            margin: 5px;
            width: auto;
        }
    }

    &-GroupHeading {
        @include desktop {
            margin-top: 1em;
            margin-bottom: 1em;
            font-size: 16px;
            margin-inline: 1em;
            color: var(--primary-alt-base-color);
            font-weight: 700;
            padding-block: 10px;
            padding-left: 7px;
            letter-spacing: 1px;
            text-transform: capitalize;
            background-color: rgb(235, 235, 235);
            border-radius: 5px;
        }
    }


    &-DesktopItemsInnerContainer,
    &-MobileItemsInnerContainer {
        @include desktop {
            flex-wrap: wrap;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
            margin-inline: 1em;
            column-gap: 10px;
            row-gap: 30px;
            padding-block: 10px;
        }
    }
    &-DesktopItemsWrapper,
    &-MobileItemsWrapper {
        border-radius: 10px;
        padding: 10px;
        @include desktop {
            box-shadow: 0px 0px 10px rgb(206, 206, 206);
            max-width: 200px;
            min-width: 200px;
            height: 100%;
        }
        @include mobile {
            padding: 20px 10px 10px 15px;
            margin: 10px;
            box-shadow: 0px 0px 10px rgb(230, 230, 230);
            // height: 200px;
        }
    }

    &-MobileItemsInnerWrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &-MobileItemsImageAddAction {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    &-OffersIcon {
        position: fixed;
        top: 13px;
        right: 20px;
        z-index: 100000;
    }

    &-Picture {
        align-self: center;
        @include desktop {
            height: 96px;
        }

        @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 130px;
            height: 150px;
            border: 1px solid rgb(237, 237, 237);
            border-radius: 10px;
            margin-bottom: 10px;
        }
        img {
            object-position: top;
            @include mobile {
                width: 100% !important;
                height: 120px !important;
                padding: 10px;
            }
        }

        &_isMobileLayout {
            width: 64px;
            height: 64px;
        }
    }

    &-MobileItemInfo {
        @include mobile {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: baseline;
        }
    }

    &-PureVeg {
        margin-bottom: 5px;
        @include desktop {
            text-align: center;
            position: absolute;
            top: 5px;
            right: 5px;
        }
        svg {
            border-radius: 2px;
        }
    }

    &-ItemName {
        font-size: 13px;
        letter-spacing: 1px;
        text-align: center;
        line-height: 20px;
        font-weight: 600;
        @include mobile {
            text-align: left;
            font-size: 14px;
        }
    }

    &-ItemWeight {
        font-size: 13px;
        letter-spacing: 1px;
        text-align: center;
        color: rgb(128, 128, 128);
        line-height: 20px;
        font-weight: 600;
        @include mobile {
            text-align: left;
            font-size: 12px;
        }
    }

    &-PriceWrapper {
        @include mobile {
            margin-bottom: 15px;
        }
    }
    .ProductPrice {
        @include desktop {
            font-size: 15px;
        }
        @include mobile {
            font-size: 15px;
        }
    }
    .ProductPrice-HighPrice {
        color: gray;
    }

    &-ItemDescription {
        font-size: 13px;
        letter-spacing: 1px;
        text-align: center;
        color: rgb(171, 170, 170);
        line-height: 20px;
        @include desktop {
            margin-bottom: 4em;
        }
        @include mobile {
            text-align: left;
            font-size: 11px;
        }
    }

    &-MatchedQuantityWrapper {
        @include desktop {
            width: 70%;
            transition: all 0.1s linear;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--primary-light-color);
            padding: 5px 0;
            border-radius: 10px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -1.5em;
            animation: match 0.2s linear;
        }
        @include mobile {
            // background-color: white;
            // transition: all 0.1s linear;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 10px;
            position: absolute;
            bottom: 15px;
            animation: match 0.4s ease-in-out;
        }
    }

    &-MatchedAddQty {
        border: none !important;
        @include desktop {
            // width: 30px !important;
            height: 30px !important;
            &:hover {
                cursor: pointer;
            }
        }
        @include mobile {
            border: 1px solid var(--primary-alt-base-color) !important;
            border-radius: 50% !important;
        }
    }

    &-MatchedRemoveQty {
        border: none !important;
        @include desktop {
            // width: 30px !important;
            height: 30px !important;
            &:hover {
                cursor: pointer;
            }
        }
        @include mobile {
            border: 1px solid var(--primary-alt-base-color) !important;
            border-radius: 50% !important;
        }
    }

    &-UnMatchedQuantityWrapper {
        @include desktop {
            transition: all 0.1s linear;
            width: 70%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--primary-alt-light-color);
            padding: 5px 0;
            border-radius: 10px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 1em;
            animation: unmatch 0.2s linear;
        }
        @include mobile {
            // transition: all 0.1s linear;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 15px;
            width: 100%;
            animation: unmatch 0.4s ease-in-out;
        }
    }

    &-UnMatchedAddQty {
        @include desktop {
            width: 100%;
            border: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px !important;
            font-size: 15px;
            color: white;
            letter-spacing: 1px;
            font-weight: 600;
            cursor: pointer;
            border &:hover {
                cursor: pointer;
            }
        }
        @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px !important;
            height: 25px !important;
            background-color: var(--primary-alt-dark-color);
            border-radius: 50% !important;
        }
    }

    &-AddAllProductToCartContainer {
        @include desktop {
            position: fixed;
            width: 100%;
            background-color: white;
            bottom: 0px;
            z-index: 1000000;
        }
        @include mobile {
            position: fixed;
            width: 100%;
            background-color: white;
            bottom: 0px;
            z-index: 1000000;
        }
    }

    &-AddAllProductToCart {
        @include desktop {
            cursor: pointer;
            width: 100%;
            background-color: var(--primary-base-color);
            color: white;
            padding: 10px 20px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            height: var(--button-height);
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                background-color: var(--primary-base-color);
                color: white;
            }
        }
        @include mobile {
            cursor: pointer;
            width: 100%;
            background-color: var(--primary-base-color);
            color: white;
            padding: 10px 20px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            height: var(--button-height);
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                background-color: var(--primary-base-color);
                color: white;
            }
        }
    }

    &-addToCartProcessing {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            @include desktop {
                top: 2px;
            }
            @include mobile {
                top: 1px;
            }
        }
    }

    &-GoToCartBottomPanelContainer {
        position: fixed;
        bottom: 0px;
        z-index: 101;
        background-color: var(--primary-base-color);
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 15px;
        padding-block: 5px;
        border-radius: 10px;
        transition: all 0.1s linear;
        animation: cartDetail 0.1s linear;
    }

    &-CartDetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    &-CartItemsCount {
        font-size: 16px;
        font-weight: 700;
        color: white;
        letter-spacing: 1px;
        margin-bottom: 5px;
    }

    &-CartGrandPrice {
        font-size: 16px;
        font-weight: 700;
        color: white;
        letter-spacing: 1px;
    }

    &-GoToNext {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 16px;
            font-weight: 700;
            color: white;
            letter-spacing: 1px;
            margin-right: 10px;
        }
    }

    &-OffersSectionContainer {
        display: flex;
        padding: 20px 10px;
        overflow-x: scroll;
    }

    &-OfferContainer {
        width: auto;
        box-shadow: 0px 0px 5px lightgray;
        padding: 10px 15px;
        margin: 0px 5px;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &-OfferItemIcon {
        margin-right: 10px;
    }

    &-OfferTitle {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: capitalize;
        white-space: nowrap;
        margin-bottom: 5px;
        text-align: left;
    }

    &-OfferSubTitle {
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: gray;
        white-space: nowrap;
        text-align: left;
    }

    [type="number"] {
        background-color: transparent;
        width: 40px;
        min-width: 30px;
        padding: 0px;

        @include desktop {
            color: white;
        }
        @include mobile {
            color: var(--primary-alt-light-color);
        }
    }

    [type="number"] ~ button:hover svg {
        @include desktop {
            fill: white;
        }
        @include mobile {
            fill: var(--primary-alt-light-color);
        }
    }
    [type="number"] ~ button {
        @include mobile {
            width: 25px !important;
            height: 25px !important;
        }
    }
}

@keyframes match {
    from {
        opacity: 0;
        // transform: scale(0);
    }
    to {
        opacity: 1;
        // transform: scale(1);
    }
}
@keyframes unmatch {
    from {
        opacity: 0;
        // transform: scale(0);
    }
    to {
        opacity: 1;
        // transform: scale(1);
    }
}

@keyframes cartDetail {
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
