:root {
    --primary-alt-base-color: #a04f1a;
    --primary-alt-dark-color: #923e16;
    --primary-alt-light-color: #ba8416;

    //Auth Extension Component Variables
    --auth-otp-border-color-black-rgb: 0, 0, 0;
    --auth-form-wrapper-bg-color: #e2e4c2;
    --auth-form-border-color: 45, 78, 51;
    --auth-signup-form-progress-bar-bg-color: #a04f1a;
}
