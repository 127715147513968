/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.SteviaQuizResult {
    @include desktop {
        margin-block: 4em;
    }
    @include mobile {
        margin-top: 7em;
        margin-bottom: 15em;
        margin-inline: auto;
    }
    &-MainContainer {
        @include desktop {
            min-height: 100px;
        }

        @include mobile {
            margin-inline: auto;
            min-height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
    &-SteviaIngredientsInfoContainer,
    &-SteviaUnUsedIngredientsInfoContainer {
        display: grid;
        column-gap: 20px;
        row-gap: 20px;
        margin-inline: 20px;
        height: 100%;

        @include desktop {
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            margin-bottom: 5em;
        }
        @include mobile {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }
    }
    &-SteviaIngredientsInfoHeading {
        font-size: 18px;
        letter-spacing: 1.5px;
        font-weight: 600;
        text-align: center;
        @include desktop {
            margin-top: 4em;
            margin-bottom: 25px;
            &:after {
                content: "";
                height: 2px;
                width: 100px;
                background-color: var(--primary-light-color);
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        @include mobile {
            margin-top: 3em;
            margin-bottom: 25px;
            &:after {
                content: "";
                height: 2px;
                width: 100px;
                background-color: var(--primary-light-color);
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    &-SteviaIngredientsInfoWrapper,
    &-SteviaUnUsedIngredientsInfoWrapper {
        @include desktop {
            background-color: beige;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        }
        @include mobile {
            background-color: beige;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        }

        h3 {
            letter-spacing: 1px;
            font-weight: 600;
            font-size: 17px;
            color: var(--primary-alt-base-color);
            font-family: "Riffic";
            &:after {
                content: "";
                height: 2px;
                width: 60px;
                background-color: var(--primary-light-color);
                position: absolute;
                bottom: -5px;
                left: 0px;
            }
        }
        p {
            letter-spacing: 0.5px;
            font-size: 15px;
            text-align: justify;
            line-height: 25px;
            margin-block: 2em;
        }
    }

    &-SteviaIngredientsTagsContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
        span {
            text-align: center;
            padding: 10px 15px;
            background-color: rgb(240, 223, 220);
            border-radius: 40px;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.5px;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
        }
    }

    &-EmptyMessageContainer {
        width: 70%;
        min-height: 100px;
        background-color: beige;
        padding: 25px 10px;
        border-radius: 20px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            margin: 0;
            font-size: 15px;
            letter-spacing: 1px;
            font-weight: 600;
            color: var(--primary-alt-base-color);
            text-transform: capitalize;
            text-align: center;
        }
    }

    &-IndicatorContainer {
        h3 {
            font-family: "Riffic";
            letter-spacing: 3px;
            text-align: center;
            font-size: 30px;
        }
    }

    &-Indicator {
        display: flex;
        justify-content: space-around;
        padding: 10px 0;
        background: #222;
        border-radius: 20px;
        border: solid 5px #333;
        margin-inline: auto;
        @include desktop {
            width: 300px;
        }
        @include mobile {
            width: 250px;
        }
    }
    &-IndicatorHeadingWrapper {
        padding: 15px;
        width: 400px;
        border-radius: 15px;
        margin-inline: auto;
        min-height: 70px;
        @media screen and (max-width: 450px) {
            width: 350px;
        }

        h4 {
            text-align: center;
            color: black;
            letter-spacing: 0.5px;
            line-height: 30px;
            font-size: 15px;
            font-weight: 600;
        }
    }

    &-SafestSteviaContainer {
        @include desktop {
            padding: 10px 0px 20px 0px;
            text-align: center;
            padding-top: 5em;
            border-top: 1px dashed var(--primary-alt-light-color);
            button {
                cursor: pointer;
                width: 500px;
                height: 45px;
                background-color: var(--primary-light-color);
                color: white;
                font-size: 16px;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-weight: 500;
                padding: 0 20px;
                border-radius: 10px;
                box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5),
                    inset 0px 0px 10px rgba(0, 0, 0, 0.4);
                transition: all 0.1s;
                &:hover,
                &:active {
                    transform: scale(0.99);
                    box-shadow: 0px 0px 0px transparent,
                        inset 0px 0px 10px rgba(0, 0, 0, 0.4);
                }
            }
        }
        @include mobile {
            backdrop-filter: blur(4px);
            background-color: rgba(0, 0, 0, 0.2);
            position: fixed;
            bottom: 50px;
            width: 100%;
            padding: 10px 0px 20px 0px;
            text-align: center;
            left: 0;
            button {
                width: 70%;
                height: 40px;
                background-color: var(--primary-light-color);
                color: white;
                font-size: 13px;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-weight: 700;
                padding: 0 20px;
                border-radius: 10px;
                box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5),
                    inset 0px 0px 10px rgba(0, 0, 0, 0.4);
                transition: all 0.1s;
                &:active {
                    transform: scale(0.99);
                    box-shadow: 0px 0px 0px transparent,
                        inset 0px 0px 10px rgba(0, 0, 0, 0.4);
                }
            }
        }
    }
}
.Safe,
.Moderate,
.UnSafe {
    border-radius: 50%;
    opacity: 0.2;
    transition: all;
    @include desktop {
        width: 75px;
        height: 75px;
    }
    @include mobile {
        width: 60px;
        height: 60px;
    }
}

.Safe {
    background: green;
    background-size: 5px 5px;
    background-image: radial-gradient(lime, transparent);
    border: dotted 2px lime;
    box-shadow: 0 0 20px #111 inset, 0 0 10px lime;
}

.Moderate {
    background: yellow;
    background-size: 5px 5px;
    background-image: radial-gradient(orange, transparent);
    border: dotted 2px yellow;
    box-shadow: 0 0 20px #111 inset, 0 0 10px yellow;
    opacity: 0.2;
}

.UnSafe {
    background: red;
    background-size: 5px 5px;
    background-image: radial-gradient(brown, transparent);
    border: dotted 2px red;
    box-shadow: 0 0 20px #111 inset, 0 0 10px red;
    opacity: 0.2;
}

.greenActive {
    animation: 1s green reverse infinite;
}

.yellowActive {
    animation: 1s yellow reverse infinite;
}

.redActive {
    animation: 1s red reverse infinite;
}

// indicator heading container
.safeHeadingContainer {
    background-color: rgba(0, 128, 0, 0.4);
}
.moderateHeadingContainer {
    background-color: rgba(255, 165, 0, 0.4);
}
.unSafeHeadingContainer {
    background-color: rgba(255, 0, 0, 0.4);
}

// indicator heading
.safeHeading {
    color: green;
}
.moderateHeading {
    color: orange;
}
.unSafeHeading {
    color: red;
}

@keyframes red {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

@keyframes yellow {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

@keyframes green {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}
