/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.FieldForm {
    label {
        @include desktop {
            letter-spacing: 0.5px;
            font-size: 13px;
            min-width: calc(100% - 20px);
        }
        @include mobile {
            letter-spacing: 0.5px;
            font-size: 13px;
        }
    }

    input {
        width: 100%;
        border-radius: 5px !important;
        &[type='checkbox'],
        &[type='radio'] {
            width: 0;
        }

        + label {
            min-width: auto;
        }
    }

    &-Fields {
        margin-block-end: 12px;

        @include mobile {
            margin-block-end: 14px;
        }
    }
}
