/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-height: calc(
        var(--footer-content-height) + var(--footer-copyright-height)
    );
    --footer-content-height: 220px;
    --footer-copyright-height: 50px;
    --footer-nav-height: 48px;
    --footer-total-height: calc(
        env(safe-area-inset-bottom) + var(--footer-nav-height)
    );
}
html {
    scroll-behavior: smooth;
}

.Footer {
    $column-count: 4;
    z-index: 11;

    @include mobile{
        padding-bottom: 40px;
    }
    .Image_ratio_square {
        padding: 0;
    }
    &-PrimaryStrip {
        width: 100%;
        height: 5px;
        background-color: var(--primary-base-color);
        opacity: 0.8;
    }
    &-PrimaryAltStrip {
        width: 100%;
        height: 4px;
        background-color: var(--primary-alt-light-color);
        opacity: 0.8;
    }
    &-CopyrightContentWrapper {
        background-color: var(--secondary-base-color);
    }

    &-CopyrightContent {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: var(--footer-copyright-height);
        padding-block-end: 10px;
    }

    &-Copyright {
        font-size: 12px;
        text-align: center;
        color: var(--secondary-dark-color);
        padding-inline: 16px;

        a {
            color: inherit;
            font-weight: 400;
            font-size: 12px;

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--primary-base-color);
    }

    &-Columns {
        @include desktop {
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr 1fr;
            column-gap: 10px;
            padding: 30px 25px !important;
        }
        @include tablet {
            padding-inline: 30px;
        }
    }

    &-Column {
        width: 100%;
    }

    &-ColumnTitle {
        margin: 0;
        margin-block-end: 15px;
        color: var(--primary-alt-light-color);
        margin-block-end: 20px;
        font-size: 15px;
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;
        &_direction_horizontal {
            flex-direction: row;
        }
    }

    &-ColumnItem {
        color: white;
        margin-block-end: 12px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 25px;
        &:last-of-type {
            margin: 0;
        }

        &:hover {
            color: var(--primary-alt-light-color);
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 16px !important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            padding-inline-start: 100px;
        }
    }
    &-ConfigContent {
        cursor: default;
        &:hover {
            color: white !important;
        }
        span {
            line-height: 30px;
            letter-spacing: 0.5px;
        }
    }

    &-BrandTrust {
        display: grid;
        background-color: white;
        align-items: center;
        column-gap: 10px;
        padding: 20px 10px;
        @include desktop {
            grid-template-columns: 1fr 0.09fr 1fr 0.09fr 1fr;
        }
        @include mobile {
            grid-template-columns: 1fr 0.01fr 1fr 0.01fr 1fr;
        }

        .imgBrandIcon {
            text-align: center;
            .natural {
                width: 40%;
                @include desktop {
                    min-width: 174px;
                    min-height: 174px;
                }
                @include mobile {
                    min-width: 90px;
                    min-height: 90px;
                }
            }
        }
        .imgPlusIcon {
            text-align: center;

            .plus {
                width: 100%;
                @include desktop {
                    min-width: 40px;
                    min-height: 40px;
                }
                @include mobile {
                    min-width: 15px;
                    min-height: 15px;
                }
            }
        }
    }

    &-PaymentMethods {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        .imgPaymentMethod {
            text-align: center;
            .razorpay {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
            .upi {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
            .gpay {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
            .phonepe {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
            .paytm {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
            .rupay {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
            .mastercard {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
            .maestro {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
            .americanexpress {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
            .visa {
                filter: grayscale(100%);
                width: 100%;
                min-width: 70px;
                min-height: 70px;
                &:hover {
                    filter: grayscale(0);
                    cursor: pointer;
                }
            }
        }
    }
    &-PaymentMethodText {
        background-color: rgb(13, 38, 81);
        text-align: center;

        h3,
        h4 {
            color: white;
            line-height: 35px;
            letter-spacing: 1px;
            font-weight: 400;
            margin: 0;
        }
    }

    &-ScrollToTop {
        position: fixed;
        right: 10px;
        bottom: 80px;
        z-index: 1000;
    }
}
