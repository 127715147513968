/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CmsPage {
    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            // margin-block-start: calc(var(--header-total-height) + 14px);
            // margin-top: 0;
        }
    }
    @include mobile {
        margin-top: 0 !important;
        bottom: 60px;
        top: 0px;
    }

    &-Content {
        .cms-content {
            @include default-list;
        }

        h2 {
            @include mobile {
                text-align: center;
            }
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        //padding-inline: 32px;
        // margin-block-start: calc(var(--header-total-height) + 12px);
        margin-block-end: var(--header-nav-height);
        margin-inline: auto;

        @include mobile {
            // margin-block-start: calc(var(--header-total-height) + 14px);
        }

        @include desktop {
            margin-block-start: 24px;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }
    }

    &-Heading {
        margin-block-end: 18px;

        @include mobile {
            margin-block-end: 21px;
            display: none;
        }
    }

    &-LifelyWellnessProgramContainer {
        background-color: var(--primary-light-color);
        margin-block: 1em;
        padding-block: 20px;
        width: 100%;
    }

    &-LifelyWellnessBtnContainer {
        img {
            width: 130px;
            height: 130px;
            @include mobile {
                width: 100px;
                height: 100px;
            }
        }
        h1 {
            color: white;
            font-family: "riffic";
            font-size: 30px;
            letter-spacing: 1px;
            @include mobile {
                font-size: 25px;
            }
        }
    }

    &-LifelyWellnessBtn {
        background-color: var(--primary-alt-light-color);
        cursor: pointer;
        color: white;
        text-transform: uppercase;
        padding-inline: 20px;
        padding-block: 10px;
        border-radius: 30px;
        margin-top: 1em;
        border: 2px solid white;
        font-weight: 700;
        font-size: 11px;
        @include desktop {
            font-size: 14px;
        }
    }

    &-ReferLifelyBtnContainer {
        @include desktop {
            position: fixed;
            bottom: 30px;
            z-index: 100;
            left: 10px;
        }

        @include mobile {
            background-color: var(--primary-alt-light-color);
            margin-top: 1em;
            margin-bottom: 1em;
            padding-top: 10px;
            padding-bottom: 15px;
            text-align: center;
            h2 {
                color: white;
                letter-spacing: 3px;
                text-transform: uppercase;
                font-size: 24px;
                font-family: "riffic";
            }
        }
    }

    &-ReferLifelyBtn {
        @include desktop {
            cursor: pointer;
            height: 50px;
            background-color: var(--primary-alt-base-color);
            color: white;
            padding-inline: 30px;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4),
                inset 0px 0px 9px 5px rgba(0, 0, 0, 0.3);
            transition: all 0.1s linear;
            &:hover {
                transform: scale(1.05);
            }
        }

        @include mobile {
            cursor: pointer;
            width: 100%;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        position: unset;
        // margin-block: 16px 40px;

        @include desktop {
            margin-block: -30px 15px;
        }
        @include mobile {
            margin-block: -20px 10px;
        }
    }

    h3 {
        @include mobile {
            font-size: 14px;
        }
    }

    .widget {
        // overflow-x: scroll;
    }

    //PRIVACY POLICY PAGE STYLING START

    .privacy-policy {
        padding: 10px 3em;
        h1 {
            letter-spacing: 2px;
            line-height: 25px;
            text-align: center;
            @include mobile {
                text-align: start;
                line-height: 30px;
            }
            margin-bottom: 2em;
            color: var(--primary-alt-base-color);
        }
        .message {
            span {
                font-size: 15px;
                letter-spacing: 0.5px;
                line-height: 25px;
                color: grey;
            }
        }
        p {
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 30px;
        }
        h2 {
            letter-spacing: 2px;
            line-height: 30px;
            color: var(--primary-light-color);
            display: inline-block;
            margin-bottom: 2em;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                bottom: -10px;
                left: 0;
                border-bottom: 2px solid var(--primary-alt-light-color);
            }
        }
        ul {
            li {
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 30px;
                &::before {
                    content: "\2713";
                    color: var(--primary-alt-light-color);
                    font-size: 1.1em;
                }
            }
        }
        thead {
            th {
                font-size: 15px;
                letter-spacing: 1px;
                line-height: 25px;
                color: var(--primary-alt-light-color);
            }
        }
        tbody {
            th {
                font-size: 15px;
                letter-spacing: 1px;
                line-height: 25px;
                color: var(--primary-base-color);
            }
            td {
                font-size: 15px;
                letter-spacing: 0.5px;
                line-height: 25px;
            }
        }
    }
    //PRIVACY POLICY PAGE STYLING END

    //REFUND RETURN CANCELLLATION POLICY PAGE START
    .refund-return-cancellation-policy {
        padding: 10px 3em;
        h1 {
            letter-spacing: 2px;
            line-height: 25px;
            text-align: center;
            margin-bottom: 2em;
            color: var(--primary-alt-base-color);
        }
        h3 {
            letter-spacing: 2px;
            line-height: 30px;
            color: var(--primary-light-color);
            display: inline-block;
            margin-bottom: 2em;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                bottom: -10px;
                left: 0;
                border-bottom: 2px solid var(--primary-alt-light-color);
            }
        }
        ul {
            li {
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 30px;
                &::before {
                    content: "\2713";
                    color: var(--primary-alt-light-color);
                    font-size: 1.1em;
                    left: -2%;
                }
            }
        }
    }
    //REFUND RETURN CANCELLLATION POLICY PAGE END
}
