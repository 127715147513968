/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.DeliveryOptions {
    &-DeliveryOptionsMainContainer {
        padding: 10px;
        margin-top: 2em;
    }

    &-OrderNow {
        @include desktop {
            margin-top: 2em;
        }
        margin-top: 5em;
    }

    &-OrderNowAnotherOptionsHeading {
        font-size: 15px;
        font-weight: 500;
        color: rgb(164, 164, 164);
        letter-spacing: 1px;
        border-bottom: 1px solid rgb(193, 193, 193);
        padding-bottom: 0.5em;
        text-align: center;
    }

    &-OrderNowActionBlockWrapper {
        @include mobile {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 10px;
        }
        @include desktop {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-OrderOnline,
    &-OfflineStore {
        cursor: pointer;
        color: white;
        letter-spacing: 2px;
        box-shadow: 0px 5px 5px rgb(157, 157, 157);
        transition: all 0.2s linear;
        border-radius: 30px;
        font-weight: 600;
        padding: 13px 10px;
        font-size: 16px;
    }

    &-OfflineStore {
        @include desktop {
            width: 100%;
        }
    }

    &-ClearInputFieldContainer {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
        width: 30px;
        height: 50px;
        min-width: 30px;
        min-height: 50px;
        cursor: pointer;
    }

    &-ClearInputField {
        background-color: rgba(154, 154, 154, 0.8);
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    &-OrderOnline {
        background-image: linear-gradient(to top, #e13744, #df8086);
        &:hover {
            background-image: linear-gradient(to top, #e13744 50%, #df8086 80%);
        }
    }

    &-OfflineStore {
        background-image: linear-gradient(to top, #30bf71, #8adba0);
        &:hover {
            background-image: linear-gradient(to top, #30bf71 50%, #8adba0 80%);
        }
    }

    &-NotifyUs {
        border: none;
        color: white;
        letter-spacing: 2px;
        font-weight: 600;
        font-size: 12px;
        background-color: var(--primary-light-color);
        padding: 13px 10px;
        box-shadow: 0px 5px 5px rgb(157, 157, 157);
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &:hover {
            background-color: var(--primary-light-color) !important;
            color: white !important;
        }
    }
}
