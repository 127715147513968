/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.LifelyStory-in {
    //colors
    --grey-color: rgb(110, 108, 108);
    --unhealthy-bg-color: #f6ede8;
    --healthy-bg-color: beige;

    //font-size
    --p-font-size: 18px;
    --span-font-size: 18px;
    --li-font-size: 18px;
    --h2-font-size: 18px;
    --h3-font-size: 20px;

    // line height
    --h2-line-height: 30px;
    --h3-line-height: 27px;
    --span-line-height: 27px;
    --p-line-height: 27px;
    --li-line-height: 27px;

    //letter spacing
    --span-letter-spacing: 1px;
    --p-letter-spacing: 1px;
    --li-letter-spacing: 1px;
    --h2-letter-spacing: 1.5px;
    --h3-letter-spacing: 1.5px;

    padding: 10px 0px;
    margin-top: 5%;
    @import "../../../style/custom/LifelyStoryProgressBar.style.scss";
    .Heading {
        text-align: center;
        padding: 10px;
        h1 {
            // font-size: 20px;
            margin-bottom: 1em;
            color: var(--primary-alt-dark-color);
            letter-spacing: var(--h2-letter-spacing);
            line-height: var(--h2-line-height);
            text-shadow: 3px 3px 2px rgba(0,0,0,0.4);
        }
        span {
            color: var(--grey-color);
            font-size: var(--span-font-size);
            letter-spacing: var(--span-letter-spacing);
            line-height: var(--span-line-height);
        }
    }

    #section1 {
        //********************************* .S1B1, .S1B3 ,.S1B5, .S1B6 *******************************************
        min-height: 100vh;
        .s1b1,
        .s1b3,
        .s1b5,
        .s1b6 {
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100vh;
            .StorySectionContent {
                display: grid;
                grid-template-columns: 2fr 2fr;
                .ImageContent {
                    display: flex;
                    justify-content: center;
                    padding: 0px 25px;
                    img {
                        width: 70%;
                        // height:100%;
                    }
                }
            }
        }
        .s1b1,
        .s1b2,
        .s1b3,
        .s1b4,
        .s1b5,
        .s1b6 {
            .TextContent {
                padding: 20px;
                align-self: center;
                span {
                    font-size: 18px;
                    line-height: 40px;
                    letter-spacing: 2px;
                    font-weight: 100 !important;
                    color: var(--grey-color);
                }
                h2 {
                    color: var(--grey-color);
                    font-size: 30px;
                    line-height: 50px;
                    letter-spacing: 3px;
                    font-weight: 100 !important;
                }
                p {
                    bottom: -20px;
                    color: var(--grey-color);
                    font-size: var(--p-font-size);
                    letter-spacing: var(--p-letter-spacing);
                    line-height: var(--p-line-height);
                }
            }
        }

        .s1b1 {
            .imgWorld {
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 70%;
                }

                .imgSugerCubes {
                    width: 100%;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 50%;
                    }
                }
            }
        }

        .s1b3 {
            .CmsFooter p {
                text-align: center;
                color: var(--primary-alt-base-color);
                font-size: 20px;
                letter-spacing: 2px;
                line-height: var(--p-line-height);
            }
            .StorySectionContent {
                .TextContent {
                    .listBtn {
                        letter-spacing: 2px;
                        margin-top: 30px;
                        border-radius: 5px;
                        background-color: transparent;
                        color: var(--primary-alt-base-color);
                        border-color: var(--primary-alt-base-color);
                        transition: all 0.2s linear;

                        &:hover {
                            transform: translateY(-10px) !important;
                            box-shadow: 2px 10px 15px 0px rgb(121, 121, 121),
                                -2px 10px 10px 0px rgb(121, 121, 121);
                            background-color: var(--primary-alt-base-color);
                            color: var(--color-white);
                        }
                    }
                }
            }
        }
        .s1b5 {
            height: auto;
            .StorySectionContent {
                flex-direction: column-reverse;
                .TextContent {
                    justify-self: center;
                    background: none;
                    border: none;
                    span {
                        color: var(--grey-color);
                        font-size: var(--span-font-size);
                        letter-spacing: var(--span-letter-spacing);
                        line-height: var(--span-line-height);
                    }
                    .listBtn {
                        letter-spacing: 2px;
                        margin-top: 30px;
                        border-radius: 5px;
                        background-color: transparent;
                        color: var(--primary-alt-base-color);
                        border-color: var(--primary-alt-base-color);
                        transition: all 0.2s linear;

                        &:hover {
                            transform: translateY(10px) !important;
                            box-shadow: 2px 10px 15px 0px rgb(121, 121, 121),
                                -2px 10px 10px 0px rgb(121, 121, 121);
                            background-color: var(--primary-alt-base-color);
                            color: var(--color-white);
                        }
                    }
                }
            }
            .IconContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin: 5em 0;

                .s1b5Icon1,
                .s1b5Icon2,
                .s1b5Icon3,
                .s1b5Icon4,
                .s1b5Icon5,
                .s1b5Icon6 {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin: 13px 0;
                    .IconBox {
                        min-width: 137px;
                        min-height: 100px;
                        img {
                            box-shadow: -3px -3px 5px rgb(255, 255, 255),
                                3px 3px 5px rgb(160, 154, 150);
                            border-radius: 30px;
                            padding: 10px;
                            min-width: 100px;
                            min-height: 100px;
                            width: 70%;
                            transition: all 0.2s linear;
                            &:hover {
                                box-shadow: -3px -3px 5px rgb(255, 255, 255) inset,
                                    3px 3px 5px rgb(160, 154, 150) inset;
                            }
                        }
                    }
                    .IconText {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        h3 {
                            margin: 0;
                            font-weight: 400 !important;
                            font-size: var(--h3-font-size);
                            letter-spacing: var(--h3-letter-spacing);
                            line-height: var(--h3-line-height);
                        }
                        p {
                            margin: 0;
                            color: var(--grey-color);
                            font-size: var(--p-font-size);
                            letter-spacing: var(--p-letter-spacing);
                            line-height: var(--p-line-height);
                        }
                    }
                }
            }
        }

        //********************************* .S1B6 *******************************************
        .s1b6 {
            height: auto;

            .CmsFooter p {
                text-align: center;
                color: var(--primary-alt-base-color);
                font-size: 20px;
                letter-spacing: 2px;
                line-height: var(--p-line-height);
            }
            .IconContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin: 5em 0;
                .s1b6Icon1,
                .s1b6Icon2,
                .s1b6Icon3,
                .s1b6Icon4,
                .s1b6Icon5 {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin: 20px 0;
                    .IconBox {
                        min-width: 137px;
                        min-height: 100px;
                        img {
                            box-shadow: -3px -3px 5px rgb(255, 255, 255),
                                3px 3px 5px rgb(160, 154, 150);
                            border-radius: 30px;
                            padding: 10px;
                            min-width: 100px;
                            min-height: 100px;
                            width: 70%;
                            transition: all 0.2s linear;
                            &:hover {
                                width: 69%;
                                box-shadow: -3px -3px 5px rgb(255, 255, 255) inset,
                                    3px 3px 5px rgb(160, 154, 150) inset;
                            }
                        }
                    }
                    .IconText {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        h3 {
                            margin: 0;
                            font-weight: 400 !important;
                            font-size: var(--h3-font-size);
                            letter-spacing: var(--h3-letter-spacing);
                            line-height: var(--h3-line-height);
                        }
                        p {
                            margin: 0;
                            color: var(--grey-color);
                            font-size: var(--p-font-size);
                            letter-spacing: var(--p-letter-spacing);
                            line-height: var(--p-line-height);
                        }
                    }
                }
            }
        }

        //********************************* .S1B2, .S1B4 *******************************************

        .s1b2,
        .s1b4 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100vh;
            padding: 20px 30px;
            .StorySectionContent {
                display: grid;
                grid-template-columns: 2fr 2fr;
                .ImageContent {
                    display: flex;
                    justify-content: center;
                    padding: 0px 25px;
                    img {
                        width: 70%;
                    }
                }
            }
        }
    }

    //******************************************** SECTION-2 ***********************************

    #section2 {
        .s2b1,
        .s2b2,
        .s2b3 {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100vh;
            padding: 20px 30px;
            .StorySectionContent {
                display: grid;
                grid-template-columns: 2fr 2fr;
                .ImageContent {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding: 0px 25px;
                    img {
                        width: 70%;
                        align-self: center;
                    }
                    p {
                        line-height: 30px;
                    }
                }

                .TextContent {
                    padding: 20px;
                    align-self: center;
                    h2 {
                        color: var(--grey-color);
                        font-size: 30px;
                        line-height: 50px;
                        letter-spacing: 3px;
                        font-weight: 100 !important;
                    }
                    p {
                        color: var(--grey-color);
                        font-size: var(--p-font-size);
                        letter-spacing: var(--p-letter-spacing);
                        line-height: var(--p-line-height);
                    }
                    ul {
                        li {
                            color: var(--grey-color);
                            font-size: var(--li-font-size);
                            letter-spacing: var(--li-letter-spacing);
                            line-height: var(--li-line-height);
                            &::before {
                                content: "\2713";
                                position: relative;
                                left: 0;
                                color: var(--primary-light-color);
                                font-size: 1.1em;
                            }
                        }
                    }
                }
            }
            .CmsFooter {
                margin-top: 30px;
                bottom: 20px;
                .RoundShape {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    justify-content: space-evenly;
                    align-items: center;
                    padding-inline: 25px;
                    @media screen and (min-width: 811px) and (max-width: 900px) {
                        grid-template-columns: repeat(3, 2fr);
                    }
                    .s2b1Round1,
                    .s2b1Round2,
                    .s2b1Round3,
                    .s2b1Round4,
                    .s2b1Round5 {
                        padding: 5px;
                        text-align: center;
                        p {
                            padding-top: 20px;
                            text-align: center;
                            height: 50px;
                            color: var(--grey-color);
                            font-size: var(--p-font-size);
                            letter-spacing: var(--p-letter-spacing);
                            line-height: var(--p-line-height);
                        }
                        img {
                            width: auto;
                        }
                    }
                }
            }
        }
        .s2b1 {
            height: auto;
            .StorySectionContent {
                margin-block: 2em;
            }
        }
        .s2b2,
        .s2b3 {
            span {
                display: block;
                padding: 20px 0;
                text-align: center;
                color: var(--primary-base-color);
                font-size: 26px;
                letter-spacing: 3px;
                line-height: var(--span-line-height);
            }
        }
    }

    //******************************************** SECTION-3 ***********************************

    #section3 {
        .s3b1 {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100vh;
            padding: 20px 30px;
            .StorySectionContent {
                display: grid;
                grid-template-columns: 2fr 2fr;
                .TextContent {
                    padding: 20px;
                    align-self: center;
                    justify-self: center;

                    h2 {
                        color: var(--grey-color);
                        font-size: 30px;
                        line-height: 50px;
                        letter-spacing: 3px;
                        font-weight: 100 !important;
                    }

                    p {
                        font-size: 18px;
                        line-height: 40px;
                        letter-spacing: 2px;
                        font-weight: 100 !important;
                        color: var(--grey-color);
                    }

                    span {
                        bottom: -20px;
                        color: var(--grey-color);
                        font-size: var(--p-font-size);
                        letter-spacing: var(--p-letter-spacing);
                        line-height: var(--p-line-height);
                    }
                }
                .ImageContent {
                    align-self: center;
                    text-align: center;
                    img {
                        width: 70%;
                    }
                }
            }
            .CmsFooter {
                h2 {
                    color: var(--grey-color);
                    font-size: 18px;
                    line-height: 30px;
                    letter-spacing: 3px;
                    text-align: center;
                }
            }
        }
        .s3b2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100vh;
            padding: 20px 10px;
            .StorySectionContent {
                padding: 5px 0px;
                margin-top: 10px;
                display: grid;
                grid-template-columns: 2.5fr 0.01fr 2.5fr;
                .TitleContent {
                    padding: 0px 25px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    .TitlePart {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .IngredientCount {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background-color: var(--primary-alt-light-color);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    h3 {
                        text-align: center;
                        font-size: var(--h3-font-size);
                        line-height: var(--h3-line-height);
                        font-weight: 100 !important;
                        color: white;
                    }
                    h2 {
                        text-align: center;
                        font-size: var(--h2-font-size);
                        letter-spacing: 3px;
                        line-height: var(--h2-line-height);
                        font-weight: 100 !important;
                    }

                    p {
                        color: var(--grey-color);
                        font-size: var(--p-font-size);
                        letter-spacing: var(--p-letter-spacing);
                        line-height: var(--p-line-height);
                    }

                    .IngredientPart {
                        --p-font-size-ingredients: 16px;

                        display: grid;
                        margin-top: 10px;
                        grid-column-gap: 60px;
                        grid-template-columns: repeat(3, 1fr);
                        grid-row-gap: 30px;
                        .ImgWrapper {
                            width: 100px;
                            height: 100px;
                        }
                        .s3b2Round1,
                        .s3b2Round2,
                        .s3b2Round3,
                        .s3b2Round4,
                        .s3b2Round5,
                        .s3b2Round6 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            img {
                                background-color: beige;
                                padding: 10px;
                                border-radius: 50%;
                            }
                            p {
                                padding-top: 15px;
                                text-align: center;
                                color: var(--grey-color);
                                font-size: var(--p-font-size-ingredients);
                                letter-spacing: var(--p-letter-spacing);
                                line-height: var(--p-line-height);
                            }
                        }
                    }

                    .ImageContent {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        img {
                            width: 70%;
                            align-self: center;
                        }
                    }
                }
                .VerticalLine {
                    border-left: 2px solid rgba(163, 163, 163, 0.2);
                }
                .TextContent {
                    padding: 0px 25px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    .ImprovesPart {
                        h2 {
                            text-align: center;
                            margin-bottom: 10px;
                            color: var(--primary-light-color);
                            font-size: 18px;
                            letter-spacing: 2px;
                            font-weight: 100 !important;
                        }
                        .ImprovesHeadingHorizontalLine {
                            margin-bottom: 3em;
                            height: 3px;
                            width: 0%;
                            background-color: var(--primary-alt-light-color);
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        .Improves {
                            display: grid;
                            grid-template-columns: 2fr 4fr;
                            .imgbgImprove {
                                width: 100%;
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img {
                                    width: 50%;
                                }
                            }
                            .imgthumbsUp {
                                width: 100%;
                                position: absolute;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                top: 20%;
                                img {
                                    width: 30%;
                                }
                            }
                            .ImprovesList {
                                ul {
                                    list-style-type: none;
                                    li {
                                        color: var(--grey-color);
                                        font-size: var(--li-font-size);
                                        letter-spacing: var(
                                            --li-letter-spacing
                                        );
                                        line-height: var(--li-line-height);
                                        &::before {
                                            content: "\2713";
                                            position: relative;
                                            left: 0;
                                            color: var(--primary-light-color);
                                            font-size: 1.1em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ControlsPart {
                        h2 {
                            text-align: center;
                            margin-bottom: 10px;
                            color: var(--primary-light-color);
                            font-size: 18px;
                            letter-spacing: 2px;
                            font-weight: 100 !important;
                        }

                        .ControlsHeadingHorizontalLine {
                            margin-bottom: 3em;
                            height: 3px;
                            width: 0%;
                            background-color: var(--primary-alt-light-color);
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        .Controls {
                            display: grid;
                            grid-template-columns: 2fr 4fr;
                            .imgbgControl {
                                width: 100%;
                                position: relative;
                                display: flex;
                                justify-content: center;
                                img {
                                    width: 50%;
                                    // height:100%;
                                }
                            }
                            .imgthumbsDown {
                                width: 100%;
                                position: absolute;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                top: 25%;
                                img {
                                    width: 30%;
                                }
                            }
                            .ControlsList {
                                ul {
                                    li {
                                        color: var(--grey-color);
                                        font-size: var(--li-font-size);
                                        letter-spacing: var(
                                            --li-letter-spacing
                                        );
                                        line-height: var(--li-line-height);
                                        &::before {
                                            content: "\21AF";
                                            position: relative;
                                            left: 0;
                                            color: var(--primary-light-color);
                                            font-size: 1.1em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //****************************************SECTION 4**************************************

    #section4 {
        .s4b1 {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100vh;
            padding: 15px 10px;
            background-image: radial-gradient(
                circle,
                #fffffd 25%,
                #fdfdf4,
                #fafaec,
                #f8f8e3,
                #f5f5db 25%
            );
            .HorizontalLine {
                height: 2px;
                width: 0%;
                background-color: var(--primary-alt-light-color);
                left: 50%;
                transform: translateX(-50%);
            }
            .HeroRibbon {
                right: -31%;
                width: 70%;
                position: relative;
                background-color: var(--primary-light-color);
                text-align: center;
                padding: 5px 0px;
                span {
                    display: block;
                    text-align: center;
                    padding: 6.5px 5px;
                    color: var(--color-white);
                    font-size: var(--span-font-size);
                    font-family: "Riffic";
                    line-height: var(--span-line-height);
                    letter-spacing: 3px;
                    text-transform: uppercase;
                }
                &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    border: 25px solid var(--primary-light-color);
                    bottom: 0px;
                    left: -3em;
                    border-right-width: 1.5em;
                    border-left-color: transparent;
                }
            }
            .HeroineRibbon {
                left: 0%;
                width: 70%;
                position: relative;
                background-color: var(--primary-alt-light-color);
                text-align: center;
                padding: 5px 0px;
                span {
                    font-family: "Riffic";
                    display: block;
                    text-align: center;
                    padding: 6.5px 5px;
                    color: var(--color-white);
                    font-size: var(--span-font-size);
                    line-height: var(--span-line-height);
                    letter-spacing: 3px;
                    text-transform: uppercase;
                }
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    border: 25px solid var(--primary-alt-light-color);
                    bottom: 0px;
                    right: -2em;
                    border-left-width: 1.5em;
                    border-right-color: transparent;
                }
            }

            .s4b1spantag {
                margin-bottom: 5px;
                display: block;
                text-align: center;
                color: var(--grey-color);
                font-size: var(--span-font-size);
                letter-spacing: var(--span-letter-spacing);
                line-height: var(--span-line-height);
                letter-spacing: 2px;
                font-size: 20px;
            }
            h2 {
                text-align: center;
                margin-block: 20px;
                color: var(--primary-base-color);
                font-size: var(--h2-font-size);
                // line-height: var(--h2-line-height);
                text-transform: uppercase;
                font-weight: 100;
                letter-spacing: 3px;
            }
            .s4b1SuperHero {
                display: grid;
                grid-template-columns: 2fr 1fr 2fr;
                justify-content: center;
                align-items: center;
                .LifelyHero {
                    text-align: right;
                    img {
                        width: 60%;
                    }
                }
                .LifelyHeroine {
                    text-align: left;
                    img {
                        width: 60%;
                    }
                }
            }
            .s4b1SuperHeroin {
                display: grid;
                grid-template-columns: 2fr 2fr;
                justify-content: center;
                align-items: center;
            }
            .ShopNowContent {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .superFoodBtn {
                    // margin-top: 1em;
                    border-radius: 30px;
                    width: fit-content;
                    letter-spacing: 2px;
                    background-color: var(--primary-alt-light-color);
                    border-width: 1px;
                    color: white;
                    border-color: var(--primary-alt-light-color);
                    transition: all 0.2s linear;
                    &:hover {
                        transform: translateY(-5px);
                        background-color: var(--primary-alt-base-color);
                        color: var(--color-white);
                        box-shadow: 0px 7px 10px rgb(162, 162, 162);
                    }
                }
                .SuperFoodStore {
                    padding: 10px 5px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .UnhealthySectionBackground:nth-child(2n + 1) {
        background-image: radial-gradient(
            circle,
            #ffffff,
            #f9f6f9,
            #f6ecef,
            #f3e3e2,
            #ecdbd3
        );
    }
    .UnhealthySectionBackground:nth-child(2n) {
        background-image: radial-gradient(
            circle,
            #ffffff,
            #f6f6f6,
            #eeeeee,
            #e5e5e5,
            #dddddd
        );
    }
    .healthySectionBackground:nth-child(2n + 1) {
        background-image: radial-gradient(
            circle,
            #fffffd,
            #fdfdf4,
            #fafaec,
            #f8f8e3,
            #f5f5db
        );
    }
    .healthySectionBackground:nth-child(2n) {
        background-image: radial-gradient(
            circle,
            #ffffff,
            #f6f6f6,
            #eeeeee,
            #e5e5e5,
            #dddddd
        );
    }
}
