/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.hide-element {
    display: none;
}
.Image.img-wposition {
    padding-bottom: 0 !important;
    height: auto;
    overflow: visible;
    width: auto;
    .Image-Image {
        position: static;
        object-fit: unset;
    }
}

//OTP Input styling START

.otpInputContainerUpdateMob {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 420px) and (max-width: 811px) {
        display: flex !important;
        justify-content: flex-start;
    }
}

.otpInputContainerUpdateMob > div {
    display: block !important;
    min-height: 55px;
    max-width: 55px;
    height: 55px;
    @include mobile {
        margin: 1px;
        input {
            padding: 15px;
            min-width: 100%;
        }
    }
    @media screen and (min-width: 420px) and (max-width: 811px) {
        min-height: 55px;
        min-width: 55px;
        height: 55px;
        width: 55px;
    }
    @include desktop {
        margin: 1px;
        input {
            min-width: 100%;
            padding: 15px;
        }
    }
}
.otpInputErrorUpdateMob {
    border-color: #ff9494;
    box-shadow: 0 0 2px 0px #ff0000;
}

.otpInputUpdateMob {
    font-size: 20px;
    border-radius: 10px;
    @include desktop {
        width: 51px !important;
    }
    @include mobile {
        width: 47px !important;
    }
}

.otpInputFocusUpdateMob {
    box-shadow: 0 0 5px 0.2rem rgba(186, 132, 22, 0.5);
    border-color: rgba(186, 132, 22, 0.5) !important;
}

//OTP Input styling END

.react-tel-input .selected-flag:before {
    border: none;
}
.react-tel-input .form-control:focus {
    box-shadow: 0 0 5px 0.2rem rgba(186, 132, 22, 0.5) !important;
    border-color: rgba(186, 132, 22, 0.5) !important;
}
.UpdateMobileNumber {
    &-InnerWrapper {
        padding: 2rem 0;
        @include mobile {
        }
        @include desktop {
            padding: 5rem 0;
        }
        .readonly {
            pointer-events: none;
        }
        &.UpdateMobileNo {
            padding: 0;
        }
    }
    &-FormWrapper {
        font-size: 12px;
        @include desktop {
        }
        @include mobile {
            margin-bottom: 6rem;
        }
        input,
        textarea,
        select {
            width: 100%;
        }
        .Field_type_checkbox,
        .Field_type_radio {
            input {
                width: auto;
            }
        }
        .Field-Message {
            margin-top: 0.8rem;
            display: block;
        }
    }
    &-Fieldset {
        align-self: center;
        @include desktop {
            top: 25px;
            min-height: 180px;
            padding: 0 9rem;
        }
        .hideMobileField {
            display: none;
        }
        .readonly {
            pointer-events: none;
        }
        .Mobile-Field {
            .control {
                position: relative;
                display: inline-block;
                width: 100%;
                &.verified {
                    &:before {
                        @include mobile {
                            --rightcheck-color: #fff;
                            content: "";
                            position: absolute;
                            pointer-events: none;
                            background: var(--rightcheck-color);
                            width: 0.24rem;
                            height: 0.24rem;
                            box-shadow: 2px 0 0 var(--rightcheck-color),
                                4px 0 0 var(--rightcheck-color),
                                4px -2px 0 var(--rightcheck-color),
                                4px -4px 0 var(--rightcheck-color),
                                4px -6px 0 var(--rightcheck-color),
                                4px -8px 0 var(--rightcheck-color),
                                4px -10px 0 var(--rightcheck-color);
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                            right: 24px;
                            top: 52px;
                            z-index: 20;
                        }
                    }
                    &:after {
                        @include mobile {
                            content: "";
                            position: absolute;
                            pointer-events: none;
                            background: var(--primary-success-color);
                            width: 20px;
                            height: 20px;
                            right: 10px;
                            top: 42px;
                            border-radius: 50%;
                            z-index: 10;
                        }
                    }
                }
            }
            input {
                width: 100%;
                border-radius: 0;
                border: 1px solid var(--input-border-color);
                @include desktop {
                    height: 40px;
                    color: black;
                    letter-spacing: 1px !important;
                }
                @include mobile {
                    height: 50px;
                    color: black;
                    // font-weight: 700 !important;
                    font-size: 20px !important;
                    letter-spacing: 1px !important;
                }
            }
            .react-tel-input {
                font-family: "Muli", sans-serif;
                font-size: 18px;
                margin-top: 30px;

                .flag-dropdown {
                    background: transparent;
                    border-radius: 10px;
                    border: none !important;
                }
                .special-label {
                    background: none;
                    padding: 0;
                    left: 0;
                    top: -30px;
                    display: block;
                    @include desktop {
                        font-size: 15px !important;
                        letter-spacing: 0.5px !important;
                    }
                    @include mobile {
                        font-size: 16px !important;
                        letter-spacing: 0.5px !important;
                        font-weight: 600 !important;
                    }
                }
            }
        }
        .Country-Flag {
            position: absolute;
            top: 33px;
            left: 1px;
            z-index: 10;
            cursor: pointer;
            img {
                max-width: 26px;
                width: auto;
            }
            .Field_type_select {
                margin: 0;
                select {
                    width: 70px;
                    padding: 11px 0 11px 8px;
                    border: 0;
                }
                .FieldSelect-Option {
                    padding: 5px 10px;
                    line-height: 1.4;
                    max-height: unset;
                }
                .FieldSelect {
                    &:after {
                        right: 10px;
                    }
                }
            }
        }
        .Mobile-OtpField {
            @include mobile {
                margin-top: 4em;
                width: 100%;
            }
            .Mobile-OtpFieldLabel {
                margin-bottom: 10px;
                margin-top: 15px;

                @include desktop {
                    font-size: 15px;
                    letter-spacing: 1px;
                }
                @include mobile {
                    font-size: 15px;
                    letter-spacing: 1px;
                    font-weight: 700;
                }
            }
            .Mobile-otpResend {
                @include mobile {
                    // margin-top: 2em;
                    float: right;
                    right: 0;
                    top: -90px;
                }
            }
            .Resend-Count {
                letter-spacing: 1px;
                font-weight: 600;
                @include desktop {
                    right: 0px;
                    top: -80px;
                    position: absolute;
                }
            }
            .Resend-Link {
                cursor: pointer;
                letter-spacing: 1px;
                font-weight: 600;
            }
        }
        .Change-Number {
            margin-top: 10px;
            @include desktop {
                position: absolute;
                margin-top: 5px;
                top: -5px;
                right: 0;
            }
            @include mobile {
                position: absolute;
                margin-top: 5px;
                top: -5px;
                right: 0;
            }
            .Button {
                font-size: 15px;
            }
        }
        .Field-Divider {
            margin: 1rem 0;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
    &-Actions {
        margin-top: 2rem;
        .Button {
            letter-spacing: 2px;
            width: 100%;
            @include desktop {
                border-radius: 5px;
                &:hover {
                    border-radius: 5px;
                }
            }
        }

        @include mobile {
            position: fixed;
            // bottom: calc(var(--footer-navbar-height) - 1px);
            bottom: 0;
            // margin-bottom: 11px;
            left: 0;
            width: 100%;
            z-index: 90;
        }
    }
    &-Addition {
        text-align: center;
        margin: 1.5rem 0 0;
    }

    &-OtpSendVerify {
        cursor: pointer;
        border: none;
        @include desktop {
            background-color: var(--primary-alt-light-color);
            padding: 15px 20px;
            width: 100%;
            color: white;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 1px;
            border-radius: 10px;
            margin-top: 20px;
            &:hover {
                background-color: var(--primary-alt-light-color);
                color: white;
            }
        }
        @include mobile {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border: none;
            margin-top: 1em;
            color: white;
            background-color: var(--primary-light-color);
            box-shadow: 0px 0px 10px grey;
            transition: all 0.1s linear;
            transform: scale(1);
            width: 100%;
            position: fixed;
            bottom: 0px;
            left: 0;
            height: 50px;
            &:hover {
                background-color: var(--primary-light-color);
                color: white;
            }
        }
    }
}
