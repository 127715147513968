/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.RewardShareSave {
    &-Wrapper {
        padding: 0;
    }

    &-Notification {
        background-color: #ecfdff;
        padding: 1rem;
        @include desktop {
            margin-bottom: 3.5rem;
        }
        p {
            margin-bottom: 0;
        }
    }
    &-Card {
        // margin-top: 2.5rem;
    }
    &-CardTitle {
        border-bottom: 1px solid var(--expandable-content-divider-color);
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: 1px;
        color: grey;
        @include mobile {
            font-size: 1.6rem;
        }
    }
    &-CardContent {
        li {
            margin-bottom: 1rem;
            line-height: 1.42857143;
            padding-left: 2.5rem;
            &::before {
                top: 0;
                left: 0;
            }
        }
    }
    &-Title {
        margin-bottom: 20px;
    }
    &-TabTitle {
        font-weight: 700;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
        color: var(--primary-light-color);
        margin: 0px;
        &::after {
            content: "";
            border-bottom: 2px solid var(--primary-alt-light-color);
            width: 25%;
            position: relative;
            bottom: 0;
            left: 0;
            margin-top: 0.5em;
            @include mobile {
                width: 40%;
            }
        }
    }
    &-ReferralLink {
        word-break: break-all;
    }
    Button {
        cursor: copy;
        background-color: transparent;
        padding: 0;
        border: none;
        &:hover {
            background-color: transparent !important;
            padding: 0 !important;
            border: none !important;
        }
    }

    &-ShareLinkWrapper {
        display: flex;
        flex-wrap: wrap;
        @include mobile {
            margin-left: 10px;
            justify-content: center;
            column-gap: 20px;
        }
    }

    &-CopyLink {
        box-shadow: 0px 0px 5px lightgray inset, 0px 0px 5px lightgray;
        border-radius: 5px;
    }

    &-WhatsappLinkWrapper {
        @include mobile {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
        @include desktop {
            display: none;
        }
    }
}
.ShareCopyLink {
    :before {
        content: "Copied";
        position: absolute;
        background: rgb(190, 218, 197);
        padding: 8px 10px;
        color: rgb(82, 82, 82);
        border-radius: 5px;
        letter-spacing: 1px;
        font-size: 15px;
        font-weight: 700;
        opacity: 0;
        transform: translateY(-5px);
        z-index: 1;
        @include desktop {
            bottom: -45px;
            right: -10px;
        }
        @include mobile {
            top: -40px;
            right: -30px;
        }
    }
    :after {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background: rgb(190, 218, 197);
        opacity: 0;
        transform: translateY(-5px) rotate(45deg);
        @include desktop {
            bottom: -23px;
            right: 15px;
        }
        @include mobile {
            top: -15px;
            right: 10px;
        }
    }
}
.ShareCopyLink.active {
    :before {
        opacity: 1;
        transform: translateY(0px);
    }
    :after {
        opacity: 1;
        transform: translateY(0px) rotate(45deg);
    }
}
