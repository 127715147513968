/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.RewardMyPoints {
    .ExpandableContent-Button {
        @include mobile {
            background-color: antiquewhite;
            padding: 14px !important;
            border-radius: 10px;
        }
    }
    &-Wrapper {
        padding: 0;
    }
    .Balance-SubTitle {
        // font-size: 18px;
        margin-top: 0;
        margin-bottom: 10px;
        font-weight: 700;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;
        color: var(--primary-light-color);
        &::after {
            content: "";
            border-bottom: 2px solid var(--primary-alt-light-color);
            width: 35%;
            position: relative;
            bottom: 0;
            left: 0;
            margin-top: 0.5em;
            margin-bottom: 1em;
            @include mobile {
                width: 50%;
            }
        }
    }
    &-Balance {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: end;
        align-items: flex-end;
        margin-bottom: 25px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        width: fit-content;
        padding: 20px;
        border-radius: 5px;
        @include desktop {
            // position: absolute;
            // top: -123px;
            // right: 0;
            // box-shadow: none;
        }
        @include mobile {
            margin-inline: auto;
            width: 100%;
            justify-content: center;
            margin-bottom: 0;
        }
        .Balance-Amount {
            display: -ms-flexbox;
            display: flex;
            align-items: flex-end;
            -ms-flex-align: end;
        }
        .Points {
            font-size: 36px;
            font-weight: 700;
            line-height: 36px;
            color: rgb(48, 191, 113);
            @include mobile {
                font-size: 26px;
                line-height: 26px;
            }
        }
        .Unit {
            margin-left: 10px;
            margin-bottom: 5px;
            font-size: 15px;
            letter-spacing: 1px;
        }
        .Balance-Right {
            padding-left: 10px;
        }
        .Earn-Logo {
            max-width: 30px;
        }
    }
    &-Notification {
        background-color: rgb(251, 243, 243);
        border-radius: 5px;
        padding: 1rem;
        @include desktop {
            margin-bottom: 3.5rem;
        }
        p {
            margin-bottom: 0;
            letter-spacing: 0.5px;
            b {
                color: var(--primary-alt-light-color);
                padding-inline: 5px;
                font-size: 15px;
            }
        }
    }
    &-Card {
        margin-top: 2.5rem;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 5px lightgray inset, 0px 0px 5px lightgray;
    }
    &-CardTitle {
        border-bottom: 1px solid var(--expandable-content-divider-color);
        margin-bottom: 25px;
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: 2px;
        color: var(--primary-alt-base-color);
        @include mobile {
            letter-spacing: 1px;
            margin-top: 3.5rem;
            font-size: 1.6rem;
        }
    }
    &-CardContent {
        li {
            margin-bottom: 1rem;
            line-height: 1.42857143;
            padding-left: 2.5rem;
            letter-spacing: 1px;
            &::before {
                top: 0;
                left: 0;
            }
        }
    }
}
