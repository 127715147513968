/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --navigation-tabs-height: calc(50px + env(safe-area-inset-bottom));
    --navigation-tabs-background: var(--color-white);
    --navigation-tabs-color: var(--secondary-dark-color);
    --footer-navbar-height: 55px !important;
}

.NavigationTabs {
    --header-background: var(--navigation-tabs-background);
    --header-icon-stroke-width: 2px;
    position: fixed;
    inset-block-end: -1px;
    inset-inline-start: 0;
    width: 100%;
    height: var(--footer-navbar-height);
    z-index: 100;
    background-color: var(--header-background);
    padding-block-end: env(safe-area-inset-bottom);
    transform: translateY(0);
    transition: transform 200ms cubic-bezier(0.47, 0, 0.745, 0.715);

    .hideOnScroll & {
        transform: translateY(100%);
    }

    @include mobile {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-block-start: 1px solid var(--primary-divider-color);
    }

    @include desktop {
        display: none;
    }

    &_isHidden {
        display: none;

        @at-root .hiddenNavigationTabs {
            --navigation-tabs-height: env(safe-area-inset-bottom);
        }
    }
    &_disableNavBar {
        display: none;

        @at-root .hiddenNavigationTabs {
            --navigation-tabs-height: env(safe-area-inset-bottom);
        }
    }

    &-Nav {
        // height: var(--footer-nav-height);
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 12px;
        z-index: 20;
        max-width: var(--content-wrapper-width);
        margin: auto;

        @include mobile {
            padding: 0 14px;
        }
    }

    &-Button {
        --dynamic-width: 40px;
        flex-grow: 1;
        width: 100%;
        height: var(--footer-navbar-height);
        display: inherit;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        &:before {
            content: "";
            width: var(--dynamic-width);
            height: 2px;
            background-color: none;
            border-radius: 10px;
        }
        &_isActive {
            &::before {
                position: relative;
                animation: activeStrip 0.2s linear;
                content: "";
                width: var(--dynamic-width);
                height: 2px;
                background-color: var(--primary-base-color);
                border-radius: 50px;
                @keyframes activeStrip {
                    from {
                        top: 2px;
                        transform: scale(0);
                    }
                    to {
                        top: 0px;
                        transform: scale(1);
                    }
                }
            }
        }
    }
    &-Icon {
        font-weight: bold;
    }
    &-RippleBtn {
        background-position: center;
        transition: all 0.3s;
        &:hover {
            background: radial-gradient(
                    circle,
                    transparent 1%,
                    rgb(223, 223, 223) 1%
                )
                center/15000%;
        }
        &:active {
            background-color: rgb(223, 223, 223);
            background-size: 100%;
            transition: all 0s;
        }
    }
}
