/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ResetButton {
    &-Button {
        width: 100%;
        @include mobile {
            width: fit-content;
            padding: 15px 20px !important;
            height: 0;
            border-radius: 25px;
            color: white !important;
            background-color: var(--primary-alt-light-color);
            border-color: var(--primary-alt-light-color);
            transition: all 0.2s linear;
            letter-spacing: 1px;
            font-size: 12px !important;
        }
        @include desktop {
            border-radius: 10px;
            border-color: var(--primary-alt-light-color);
            transition: all 0.2s linear;
            letter-spacing: 1px;
            &:hover {
                border-radius: 10px !important;
                border-color: var(--primary-alt-light-color) !important;
                letter-spacing: 1px;
                transform: scale(1.03);
            }
        }
    }
}
