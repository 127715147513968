/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.RewardPointsApply {
    @include desktop {
        background-color: white;
        border-radius: 5px;
        padding: 20px;
    }

    @include mobile {
        margin: 10px 0;
        padding: 10px;
        border-radius: 15px;
        background-color: #e5efe5;
    }
    &-Title {
        margin-top: 0px;
        margin-bottom: 21px;
        letter-spacing: 1px;
        @include mobile {
            display: none;
        }
    }
    &-Content {
        @include mobile {
            margin-bottom: 20px;
        }
        .ExpandableContent-Button {
            @include desktop {
                display: none;
            }
            @include mobile {
                background-color: antiquewhite;
                padding: 14px !important;
                border-radius: 10px;
            }
        }
    }
    &-MessageSuccess {
        margin: 0 0 15px;
        padding: 14px 20px 12px 25px;
        display: block;
        font-size: 1.3rem;
        background: #e5efe5;
        color: var(--primary-success-color);
        padding-left: 45px;
        position: relative;
        &:before {
            --rightcheck-color: #fff;
            content: "";
            position: absolute;
            pointer-events: none;
            background: var(--rightcheck-color);
            width: 0.24rem;
            height: 0.24rem;
            box-shadow: 2px 0 0 var(--rightcheck-color),
                4px 0 0 var(--rightcheck-color),
                4px -2px 0 var(--rightcheck-color),
                4px -4px 0 var(--rightcheck-color),
                4px -6px 0 var(--rightcheck-color),
                4px -8px 0 var(--rightcheck-color),
                4px -10px 0 var(--rightcheck-color);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            left: 14px;
            top: 19px;
            z-index: 20;
        }
        &:after {
            content: "";
            position: absolute;
            pointer-events: none;
            background: var(--primary-success-color);
            width: 20px;
            height: 20px;
            left: 10px;
            top: 9px;
            border-radius: 50%;
            z-index: 10;
        }
    }
    &-FormDescription {
        font-size: 14px;
        margin-bottom: 1rem;
        letter-spacing: 1px;
        color: rgb(163, 163, 163);
        @include mobile {
            margin-block: 10px;
            text-align: center;
        }
        strong {
            color: var(--primary-alt-base-color);
            letter-spacing: 1px;
            font-size: 15px;
        }
    }
    &-Fields {
        > .Field {
            margin-top: 0;
            margin-bottom: 20px;
            input {
                width: 100%;
            }
        }
        .RewardPointsApply-Input {
            width: 70%;
            @include mobile {
                width: 100%;
                text-align: center;
                input {
                    width: 60%;
                    text-align: center !important;
                    border-radius: 30px !important;
                    letter-spacing: 1px;
                    font-size: 15px !important;
                    font-weight: bold !important;
                }
            }
            @include desktop {
                input {
                    border-radius: 10px !important;
                    letter-spacing: 1px;
                    font-size: 15px !important;
                    font-weight: bold !important;
                }
            }
            button {
                display: none;
            }
        }
        .RewardAllPoint {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            letter-spacing: 1px;
            @include mobile {
                justify-content: center;
            }
            margin-block: 20px;
            font-size: 14px;
            .Field_type_checkbox {
                margin-top: 0;
            }
            strong {
                color: var(--primary-alt-base-color);
            }
        }
        .RewardPointsApply-Input[type="number"] {
            --input-type-number-width: 46px;
            border: 1px solid var(--input-border-color);
            border-radius: var(--input-border-radius);
            color: var(--input-color);
            padding: var(--input-padding);
            pointer-events: unset;
            order: 2;
            text-align: left;
            font-size: 14px;
            font-weight: normal;
        }
    }

    &-AppliedPointInfo {
        text-align: center;
        letter-spacing: 1px;
        color: var(--primary-light-color);
        font-weight: bold;
        padding: 10px 0;
        border-radius: 40px;
        @include mobile {
            font-size: 13px;
            background-color: white;
        }
        @include desktop {
            background-color: rgb(229, 239, 229);
            font-size: 15px;
        }
    }

    &-AppliedPointNumber {
        color: var(--primary-alt-base-color);
        font-weight: bold !important;
        margin-inline: 5px;
        @include mobile {
            font-size: 16px;
        }
        @include desktop {
            font-size: 17px;
        }
    }

    &-Actions {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        @include mobile {
            margin-bottom: 0;
            justify-content: space-around;
        }
        .Button {
            letter-spacing: 2px;
            width: 45%;
            border-radius: 5px;
            @include mobile {
                border-radius: 30px;
                font-size: 14px !important;
            }
            &:hover {
                border-radius: 5px;
                @include mobile {
                    border-radius: 30px;
                    background-color: var(--primary-light-color) !important;
                }
            }
        }
        .Button + .Button {
            margin-left: 10px;
            @include mobile {
                margin-left: 0;
            }
        }
    }
}
.CartUseRewardPoints {
    &-Wrapper {
        .RewardPointsApply {
            padding: 1.5rem 0 1rem;
            @include desktop {
                border-bottom: 1px solid var(--primary-divider-color);
            }
            &-Content {
                .ExpandableContent-Button {
                    @include desktop {
                        display: block;
                    }
                }
            }
            &-Title {
                display: none;
            }
            &-Fields {
                .RewardPointsApply-Input {
                    width: 100%;
                    margin-bottom: 5px;
                }
                .RewardAllPoint {
                    margin-bottom: 10px;
                }
            }
            &-Actions {
                .Button {
                    height: auto;
                    font-size: 1.2rem;
                    padding: 10px 10px 6px 10px;
                    &:not([disabled]):hover,
                    &:not([disabled]):focus {
                        height: auto;
                        padding: 10px 10px 6px 10px;
                    }
                }
                .Button + .Button {
                    margin-left: 5px;
                    @include mobile {
                        margin-left: 0;
                        margin-top: 5px;
                    }
                }
            }
        }
        .ExpandableContent {
            &-Button {
                @include desktop {
                    padding-top: 14px;
                    padding-bottom: 14px;
                    border-bottom: 1px solid var(--primary-divider-color);
                    cursor: pointer;
                    position: relative;
                }
                &:after {
                    @include desktop {
                        content: "+";
                        font-weight: bold;
                        font-size: 20px;
                        position: absolute;
                        right: 0;
                        top: 14px;
                        z-index: 1;
                    }
                }
                &_isContentExpanded {
                    &:after {
                        @include desktop {
                            content: "-";
                        }
                    }
                }
            }
            &-Heading {
                @include desktop {
                    font-size: 14px;
                    padding-right: 14px;
                    cursor: pointer;
                }
            }
            &-Content {
                @include desktop {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                    margin-top: 0;
                }
                &_isContentExpanded {
                    @include desktop {
                        opacity: 1;
                        max-height: 100%;
                    }
                }
            }
        }
    }
}
