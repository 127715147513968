/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}
.IngredientAttributes {
    &-IngredientAttributeRow {
        padding: 10px 0;
        border-radius: 15px;
        margin: 20px 0;
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 0.001fr 4fr;
            align-items: center;
            justify-content: center;
            background-image: radial-gradient(
            circle at center center,
            beige,
            rgba(43, 76, 50, 0.4)
        );
            box-shadow: 0px 2px 20px 0px rgb(75, 75, 75);
        }
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-image: radial-gradient(
            circle at center center,
            beige,
            rgba(43, 76, 50, 0.4)
        );
            box-shadow: 0px 0px 20px 0px rgb(79, 79, 79);
        }
    }
    &-IngredientAttributeIconHeading {
        h3 {
            text-align: center;
            margin: 10px 0 !important;
            letter-spacing: 1px;
            color: black;
        }
        @include desktop {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        @include mobile {
            text-align: center;
            h3 {
                background-color: var(--primary-alt-dark-color);
                color: white;
                padding: 7px 0;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.5px;
            }
        }
    }
    &-IngredientAttributeIcon {
        @include desktop {
            width: 100%;
            text-align: center;
            img {
                padding: 10px;
                // background-color: antiquewhite;
                border-radius: 50%;
                width: 50%;
            }
        }
        @include mobile {
            width: 100%;
            text-align: center;
            img {
                padding: 10px;
                width: 30%;
                // background-color: antiquewhite;
                border-radius: 50%;
            }
        }
        @include narrow-tablet {
            width: 100%;
            text-align: center;
            img {
                padding: 10px;
                width: 30%;
                border-radius: 50%;
            }
        }
    }
    &-VerticalLine {
        @include desktop {
            border-right: 1px solid rgba(43, 76, 50, 0.4);
            height: 100%;
        }
    }
    &-IngredientAttributeInfo {
        @include desktop {
            left: 5%;
            width: 90%;
            ul {
                li {
                    &::before {
                        content: "\2713";
                        font-size: 20px;
                        left: -3%;
                        top: 9px;
                        color: black;
                    }
                    p {
                        font-size: 17px;
                        line-height: 30px;
                        margin: 0;
                        color: black;
                    }
                }
            }
        }
        @include mobile {
            left: 20px;
            width: 85%;
            ul {
                li {
                    &::before {
                        content: "\2713";
                        font-size: 25px;
                        left: -10%;
                        top: 7px;
                        color: var(--primary-base-color);
                    }
                    p {
                        font-size: 17px;
                        line-height: 30px;
                        margin: 0;
                        color: var(--primary-base-color);
                    }
                }
            }
        }
    }
    &-ErrorHandler {
        text-align: center;
        top: 100px;
        p {
            font-size: 18px;
            background-color: rgba(43, 76, 50, 0.3);
            line-height: 22px;
            font-weight: 600;
            letter-spacing: 3px;
            font-family: "Riffic";
            padding: 10px;
            color: var(--primary-alt-base-color);
            @include mobile {
                line-height: 30px;
            }
        }
    }
}
