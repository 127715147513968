/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CheckLocationAvailability {
    @include mobile {
        padding-inline: 10px;
        margin-top: 2em;
    }
    &-DetectDeliveryLocationContainer {
        @include desktop {
            span {
                color: gray;
                top: 7px;
                left: 5px;
                letter-spacing: 1px;
            }
        }
        @include mobile {
            margin-bottom: 3em;
        }
    }

    &-DetectDeliveryLocationInnerContainer {
        display: flex;
        align-items: center;
        border-radius: 10px;
        @include mobile {
            column-gap: 10px;
        }
    }

    &-DetectCurrentLocationButton {
        cursor: pointer;
        padding: 15px 20px;
        transition: all 0.2s linear;
        background-image: linear-gradient(
            to top,
            rgb(186, 132, 22),
            rgb(206, 178, 120)
        );
        display: flex;
        justify-content: center;
        align-items: center;
        @include desktop {
            letter-spacing: 2px;
            box-shadow: 0px 5px 5px rgb(157, 157, 157);
            font-size: 16px;
            font-weight: 600;
            color: white;
            &:hover {
                background-image: linear-gradient(
                    to top,
                    rgb(186, 132, 22),
                    rgb(206, 178, 120) 80%
                );
                box-shadow: 0px 15px 10px rgb(157, 157, 157);
                color: white;
                transform: translateY(-5px) !important;
            }
        }
        @include mobile {
        }
    }

    &-ClearInputFieldContainer {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
        width: 30px;
        height: 50px;
        min-width: 30px;
        min-height: 50px;
        cursor: pointer;
    }

    &-ClearInputField {
        background-color: rgba(154, 154, 154, 0.8);
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
}
// CURRENT LOCATION SECTION STYLE START
.isDetectedCurrentLocationAction {
    border-radius: 50%;
    padding: 0;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
}
.isDetectedInnerContainer {
    justify-content: space-between;
    border-radius: 30px;
    @include desktop {
        padding-right: 5px;
        box-shadow: 0px 5px 20px rgb(201, 201, 201);
        background-color: white;
    }
}

.isNotDetectedInnerContainer {
    justify-content: space-between;
}
// CURRENT LOCATION SECTION STYLE END

// AUTOCOMPLETE LOCATION SECTION STYLE START
.autoCompleteLocationMainOuterContainer {
    @include desktop {
        width: 90%;
    }
}
@include mobile {
    .currentLocationBtnEnabled {
        width: 100%;
    }
    .currentLocationBtnDisabled {
        width: 100%;
    }
}

.inputShakingAnimationClass {
    animation: shakeInput 0.1s 3 linear alternate;
    -webkit-animation: shakeInput 0.1s 3 linear alternate;
}

.beforeDetectLocationInputClass,
.afterDetectLocationInputClass {
    border-radius: 15px;
    letter-spacing: 1px;
    color: black;
    font-weight: 600;
    @include desktop {
        padding: 15px;
        border: none !important;
        font-size: 14px;
    }
    &:focus {
        @include desktop {
            border: none !important;
        }
    }
    @include mobile {
        border-radius: 10px;
        padding: 15px 8px 15px 8px;
        font-size: 14px;
    }
}
.beforeDetectLocationInputClass {
    @include desktop {
        width: 97%;
        background-color: white;
    }
    @include mobile {
        width: 100%;
        padding: 15px 8px 15px 8px;
        border: 1px solid rgb(187, 187, 187);
        color: black;
    }
}

.afterDetectLocationInputClass {
    @include desktop {
        width: 97%;
        padding: 15px 0px 15px 15px;
        background-color: transparent;
    }
    @include mobile {
        width: 100%;
        border-radius: 10px;
    }
}

.autocomplete-dropdown-container {
    position: absolute;
    z-index: 4;
    max-height: 400px;
    overflow-y: scroll;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgb(218, 218, 218);

    @include desktop {
        width: 400px;
    }
    @include mobile {
        width: 100%;
    }
}

.activeAddressDropDownClass {
    @include mobile {
        padding: 10px;
    }
}
.inActiveAddressDropDownClass {
}

.activeGeoLocationAddress,
.inActiveGeoLocationAddress {
    padding: 10px 10px;
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 500;
    line-height: 1.5;
    transition: all 0.1s linear;
    border-bottom: 1px solid rgb(223, 223, 223);
    @include desktop {
        padding-bottom: 20px;
    }
    &:last-child {
        border: none !important;
    }
}

.activeGeoLocationAddress {
    background-color: rgb(227, 227, 227);
    font-weight: 700;
    transform: scale(1.02);
    @include desktop {
        span {
            color: black;
        }
    }
    @include mobile {
        color: black;
    }
}
// AUTOCOMPLETE LOCATION SECTION STYLE END
