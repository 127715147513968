/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.SteviaIngredientCheckList {
    @include desktop {
        max-width: 500px;
        margin-block: 4em;
        margin-inline: auto;
    }
    @include mobile {
        margin-top: 6em;
        margin-bottom: 15em;
        max-width: 400px;
        margin-inline: auto;
    }
    &-MainContainer {
        @include desktop {
            background-color: beige;
            border-radius: 20px;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 20px;
        }

        @include mobile {
            background-color: beige;
            border-radius: 20px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
            margin-inline: auto;
        }

        @media screen and (max-width: 450px) {
            width: 90%;
        }
    }

    &-InstructionContainer {
        min-height: 100px;
        margin-bottom: 15px;
        padding: 10px 15px;
        h4 {
            color: var(--primary-alt-base-color);
            letter-spacing: 0.5px;
            line-height: 30px;
            font-size: 15px;
            font-style: italic;
            font-weight: 600;

            @include desktop {
                text-align: justify;
            }
            @include mobile {
                text-align: justify;
            }
        }
        img {
            @include desktop {
                width: 300px;
                height: 300px;
            }
        }
    }

    &-SteviaIngredientMainContainer {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: baseline;
        row-gap: 20px;
        column-gap: 20px;

        @include desktop {
            padding: 25px 30px;
        }
        @include mobile {
            padding: 10px 10px 15px 10px;
        }

        span {
            font-size: 15px;
            letter-spacing: 0.5px;
            font-weight: 600;
            text-transform: capitalize;
            top: 1px;
        }

        [type="checkbox"] {
            min-width: 20px;
            min-height: 20px;
            width: 40px;
            height: 25px;
            cursor: pointer;
            border: 1px solid var(--input-border-color);
            margin-bottom: 0px;
            appearance: button;
            -moz-appearance: button;
            -webkit-appearance: button;
            &:checked {
                accent-color: var(--primary-light-color);
            }
        }
    }

    &-SteviaIngredientInnerCheckbox {
        display: flex;
        justify-content: baseline;
        align-items: center;
    }

    &-MoveStepActionContainer {
        display: flex;
        justify-content: space-between;
        @include mobile {
            position: fixed;
            bottom: 45px;
            width: 100%;
            padding: 10px 10px 20px 10px;
            text-align: center;
            left: 0;
            backdrop-filter: blur(4px);
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &-MoveBackStepAction,
    &-MoveNextStepAction {
        button {
            cursor: pointer;
            height: 40px;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 700;
            color: white;
            border-radius: 10px;
            transition: all 0.1s ease-in-out;
            @include desktop {
                margin-top: 2em;
                padding: 0px 20px;
            }
            @include mobile {
                padding: 0px 30px;
                &:active {
                    transform: scale(0.99);
                    box-shadow: 0px 0px 0px transparent,
                        inset 0px 0px 10px rgba(0, 0, 0, 0.4);
                }
            }
            &:hover {
                transform: scale(0.99);
                box-shadow: 0px 0px 0px transparent,
                    inset 0px 0px 10px rgba(0, 0, 0, 0.4);
            }
        }
    }
    &-MoveNextStepAction {
        button {
            background-color: var(--primary-alt-light-color);
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4),
                inset 0px 0px 10px rgba(0, 0, 0, 0.4);
            &:disabled {
                cursor: not-allowed;
                box-shadow: 0px 0px 0px transparent,
                    inset 0px 0px 10px rgba(0, 0, 0, 0.2);
                background-color: rgb(207, 178, 115);
                transform: scale(0.99);
            }
            &:hover {
                &:disabled {
                    transform: scale(0.99);
                }
            }
        }
    }
    &-MoveBackStepAction {
        button {
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4),
                inset 0px 0px 10px rgba(0, 0, 0, 0.4);
            background-color: var(--primary-light-color);
        }
    }

    &-EmptyMessageContainer {
        width: 100%;
        min-height: 100px;
        background-color: beige;
        padding: 25px 10px;
        border-radius: 20px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            margin: 0;
            font-size: 15px;
            letter-spacing: 1px;
            font-weight: 600;
            color: var(--primary-alt-base-color);
            text-transform: capitalize;
            text-align: center;
        }
    }
}
