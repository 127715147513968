/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.UpdateMobileno {
    &-UpdateMobileNoPopUpBtn {
        cursor: pointer;
        border: none;
        @include desktop {
            // margin-top: 2em;
            // background-color: var(--primary-alt-light-color);
            // padding: 15px 20px;
            // width: auto;
            // color: white;
            // font-size: 15px;
            // font-weight: 600;
            // letter-spacing: 1px;
            // border-radius: 10px;
            // &:hover {
            //     background-color: var(--primary-alt-light-color);
            //     color: white;
            // }
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @include mobile {
            // border-radius: 10px;
            // color: var(--primary-alt-light-color);
            // padding: 15px 20px;
            // width: 100%;
            // font-size: 15px;
            // font-weight: 600;
            // letter-spacing: 1px;
            // border: 1px solid var(--primary-alt-light-color);
            // &:hover {
            //     border: 1px solid var(--primary-alt-light-color);
            //     color: var(--primary-alt-light-color);
            // }
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
