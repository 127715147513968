/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}
.SugarImpacts {
    padding-inline: 20px;
    &-SugarImpactsContainer {
    }
    &-SugarImpactsWrapper {
    }
    &-SugarImpactRow {
        padding: 10px 0;
        border-radius: 15px;
        margin: 20px 0;
        background-image: radial-gradient(
            circle at center center,
            beige,
            rgba(43, 76, 50, 0.3)
        );
        box-shadow: 0px 0px 20px 0px rgb(145, 145, 145);
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 0.001fr 4fr;
            align-items: center;
            justify-content: center;
        }
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    &-SugarImpactIconHeading {
        h3 {
            text-align: center;
            margin: 10px 0 !important;
            letter-spacing: 1.5px;
            color: black;
            font-size: 16px;
        }
        @include desktop {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        @include mobile {
            width: 100%;
            text-align: center;
            h3 {
                background-color: rgb(226,236,233);
                color: var(--primary-alt-base-color);
                padding: 7px 0;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 1px;
            }
        }
    }
    &-SugarImpactIcon {
        width: 100%;
        text-align: center;
        @include desktop {
            img {
                padding: 10px;
                width: 50%;
            }
        }
        @include mobile {
            img {
                padding: 10px;
                width: 30%;
            }
        }
        // @include narrow-tablet {
        //     width: 100%;
        //     text-align: center;
        //     img {
        //         padding: 10px;
        //         width: 30%;
        //         border-radius: 50%;
        //     }
        // }
    }
    &-VerticalLine {
        @include desktop {
            border-right: 1px solid rgb(227, 227, 227);
            height: 100%;
        }
    }
    &-SugarImpactInfo {
        @include desktop {
            left: 5%;
            width: 90%;
            ul {
                li {
                    &::before {
                        content: "\2713";
                        font-size: 20px;
                        left: -4%;
                        top: 6px;
                        color: var(--primary-light-color);
                    }
                    p {
                        font-size: 17px;
                        line-height: 30px;
                        margin: 0;
                        letter-spacing: 1px;
                        color: var(--primary-base-color);
                    }
                }
            }
        }
        @include mobile {
            left: 20px;
            width: 85%;
            ul {
                li {
                    &::before {
                        content: "\2713";
                        font-size: 25px;
                        left: -12%;
                        top: 7px;
                        color: var(--primary-base-color);
                    }
                    p {
                        font-size: 17px;
                        line-height: 30px;
                        margin: 0;
                        color: var(--primary-base-color);
                    }
                }
            }
        }
    }
    &-ErrorHandler {
        text-align: center;
        top: 100px;
        p {
            font-size: 18px;
            background-color: rgba(43, 76, 50, 0.3);
            line-height: 22px;
            font-weight: 600;
            letter-spacing: 3px;
            font-family: "Riffic";
            padding: 10px;
            color: var(--primary-alt-base-color);
            @include mobile {
                line-height: 30px;
            }
        }
    }
}
