/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --expandable-content-color: var(--color-karaka);
    --expandable-content-divider-color: var(--primary-divider-color);
    --toggle-button-size: 18px;
}

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    @include mobile {
        .AddIcon,
        .MinusIcon {
            width: var(--toggle-button-size);
            height: var(--toggle-button-size);
        }
    }

    &-Button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-Header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &-Heading,
    &-SubHeading {
        display: block;
        color: var(--expandable-content-color);
    }

    &-Heading {
        font-weight: 700;
        word-break: break-all;
        letter-spacing: 1px;
        line-height: 1.4;

        @include mobile {
            font-size: 14px !important;
        }

        @include desktop {
            cursor: auto;
            font-size: 14px;
            font-weight: bold;
            text-transform: none;
        }
    }

    &-SubHeading {
        font-weight: 400;
        font-size: 10px;
        line-height: 1.1;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity;
        transition-duration: 200ms;

        @include mobile {
            transition: all 0.2s linear;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
        }

        @include desktop {
            margin-block-start: 12px;
        }

        &_isContentExpanded {
            @include mobile {
                @include expanded-content;
            }
        }
    }

    @include mobile {
        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }
    &-ItemCounterContainer {
        @include mobile {
            width: 18px;
            height: 18px;
            background-color: rgb(255, 77, 77);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease-in-out;
            position: absolute;
            right: 40px;
        }
    }

    &-ItemCounter {
        color: white;
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        top: 1px;
    }
}

// .ShippingPageExpandableButton {
//     @include mobile {
//         background-color: antiquewhite;
//         padding: 14px !important;
//         border-radius: 10px;
//     }
// }
// .ProductPageExpandableButton {
//     @include mobile {
//         background-color: antiquewhite;
//         padding: 14px !important;
//         border-radius: 10px;
//     }
// }
