/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.RewardPointTab {
    &-Item {
        transition: all 0.2s linear;
        margin: 0;
        padding-inline-start: 0;
        @include desktop {
            overflow-x: hidden;
        }
        &:last-child {
            margin: 0;
            padding: 0;
        }

        &::before {
            @include desktop {
                content: "";
                border-top: 2px solid var(--primary-alt-base-color);
                width: 100%;
                transition: all 0.4s linear;
                transform: translateX(-150px);
                opacity: 0;
            }
            @include mobile {
                content: "";
            }
        }

        &_isActive {
            background-color: rgb(229,239,229);
            box-shadow: 0px 0px 5px lightgray;
            &::before {
                @include desktop {
                    content: "";
                    border-top: 2px solid var(--primary-alt-base-color);
                    width: 100%;
                    left: 0px !important;
                    transform: translateX(0);
                    opacity: 1;
                }
                @include mobile {
                    content: "";
                }
            }
            .RewardPointTab-Button {
                color: var(--primary-alt-base-color);
            }
        }
    }

    &-Button {
        transition: all 0.2s linear;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 2px;
        margin: 0;
        padding: 16px 24px;
        color: var(--primary-base-color);
    }
}
