/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.RewardPointsNotification {
    &-Message {
        z-index: 100;
        width: 100%;
        left: 0;
        background: #e5efe5;
        box-shadow: 0px 3px 10px rgb(198, 198, 198);
        @include mobile {
            position: fixed;
            top: 56px;
            height: 56px;
            display: flex;
            align-items: center;
        }
        @include desktop {
            display: flex;
            align-items: center;
            padding: 5px 10px;
        }
    }

    &-RewardPointSuccessImg {
        width: 40px;
        height: 40px;
        min-width: 30px;
        min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline: 10px;
        img {
            width: 30px;
            height: 30px;
            @include desktop {
                width: 25px;
                height: 25px;
            }
        }
    }

    &-MessageSuccess {
        font-size: 1.3rem;
        color: var(--primary-light-color);
        letter-spacing: 1px;
        text-transform: capitalize;
        line-height: 20px;
        strong {
            font-size: 13px;
            color: var(--primary-alt-base-color);
        }
    }
}
