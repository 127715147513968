/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.NewVersionPopup {
    @include mobile {
        align-items: flex-end;
        height: 100%;
        inset-block-start: 0;
        z-index: 205;
    }
    p {
        @include desktop {
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 1px;
            line-height: 25px;
        }
        @include mobile {
            font-size: 13px;
            letter-spacing: 0.5px;
            line-height: 20px;
            font-weight: 400;
        }
    }
    &-Heading {
        font-size: 20px;
        text-decoration: none;
        font-weight: 400;
        margin-block-start: 0;
        margin-block-end: 12px;

        @include mobile {
            margin-block-end: 14px;
            color: var(--primary-base-color);
        }

        @include desktop {
            display: none;
        }
    }

    &-Buttons {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }

        @include desktop {
            display: flex;
            justify-content: center;
        }
    }

    &-ReloadButton {
        border-radius: 5px;
        &:hover {
            border-radius: 5px !important;
        }
        @include desktop {
            letter-spacing: 1px;
        }
        @include mobile {
            width: 100%;
        }
    }

    &-DismissButton {
        padding: var(--button-padding);
        @include desktop {
            background-color: white;
            color: var(--primary-base-color);
            border-color: var(--primary-base-color);
            &:hover {
                background-color: var(--primary-alt-light-color) !important;
                border-color: var(--primary-alt-light-color) !important;
            }
        }
        @include mobile {
            width: 100%;
            margin-block-start: 5px;
            background-color: white;
            border-radius: 10px;
            color: var(--primary-alt-base-color);
            border-color: var(--primary-alt-base-color);
        }
    }

    &-Content {
        @include desktop {
            max-width: 500px;
        }
    }

    .Popup {
        &-CloseBtn {
            display: none;
        }

        &-Heading {
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }
}
