/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}
.Panel {
    .panel {
        z-index: 1;
        margin-bottom: 5px;
        border-radius: 15px;
    }
    .menuPanel {
        margin: 0;
    }
    .panel__title,
    .panel__title_empty_content {
        position: relative;
        display: block;
        width: 100%;
        background: none;
        border: none;
        text-align: left;
        padding: 10px 25px 10px 40px;
        font-weight: 500;
        font-size: 17px;
        font-family: inherit;
        transition: all 0.2s linear;
        cursor: pointer;
        letter-spacing: 0.5px;
        line-height: 25px;
        color: var(--primary-alt-dark-color);
        @include desktop {
            padding: 20px 25px 20px 40px;
        }
        @include mobile {
            padding: 20px 10px 20px 40px;
        }
    }
    .panel__title:focus {
        outline: none;
    }
    .panel__title__with__image,
    .panel__title__with__image_empty_content {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        background: none;
        border: none;
        text-align: left;
        font-weight: 500;
        font-size: 17px;
        font-family: inherit;
        transition: all 0.2s linear;
        cursor: pointer;
        letter-spacing: 0.5px;
        line-height: 25px;
        color: var(--primary-alt-dark-color);
        @include desktop {
            padding: 20px 25px 20px 40px;
        }
        @include mobile {
            padding: 20px 10px 20px 15px;
        }
    }

    .panel__parent__title {
        width: 100%;
    }
    .panel__parent__image {
        width: 20%;
        display: flex;
        min-width: 77px;
        min-height: 77px;
        align-items: center;
        @include desktop {
            width: 20%;
            img {
                width: 70%;
            }
        }
        @include mobile {
            width: 20%;
            margin-right: 1em;
            img {
                width: 100%;
                min-width: 50px;
                min-height: 50px;
            }
        }
        @include narrow-tablet {
            width: 20%;
            margin: 0;
            img {
                width: 70%;
                min-width: 77px;
                min-height: 77px;
            }
        }
    }

    .panel__title__with__image:focus {
        outline: none;
    }
    .panel__title:after,
    .panel__title:before {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        width: 10px;
        height: 2px;
        margin-top: -2px;
        background-color: var(--primary-alt-dark-color);
    }
    .panel__title__with__image:after,
    .panel__title__with__image:before {
        content: "";
        position: absolute;
        right: 20px;
        top: 50%;
        width: 10px;
        height: 2px;
        margin-top: -2px;
        background-color: var(--primary-alt-dark-color);
    }
    .panel__title:before,
    .panel__title__with__image:before {
        transform: rotate(-90deg);
        transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    .panel[aria-expanded="true"] {
        // border: 1.5px solid var(--primary-base-color);
        background-color: rgb(251,243,243) !important;
    }
    .panel[aria-expanded="true"] .panel__child__content {
        opacity: 1;
    }
    .panel[aria-expanded="true"] .panel__title,
    .panel[aria-expanded="true"] .panel__title__with__image {
        color: var(--primary-light-color);

        @include desktop {
            letter-spacing: 1.5px;
            font-weight: 600;
        }
    }
    .panel[aria-expanded="true"] .panel__title:before,
    .panel[aria-expanded="true"] .panel__title__with__image:before {
        transform: rotate(0deg);
    }
    .panel__inner {
        overflow: hidden;
        transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
        display: grid;
        transition: height 0.3s linear;
    }
    .panel__child__image {
        text-align: center;
        img {
            width: 20%;
            @include mobile {
                min-width: 50px;
                min-height: 50px;
            }
            @include narrow-tablet {
                min-width: 77px;
                min-height: 77px;
            }
        }
    }
    .panel__child__content {
        margin: 5px 25px 25px;
        font-size: 16px;
        color: gray;
        opacity: 0;
        transition: all 0.3s linear;
        letter-spacing: 1px;
        line-height: 22px;
    }
    .panel:not(:last-child) {
        margin-bottom: 3px;
    }
    .panel__child__horizontalline {
        width: 90%;
        border-bottom: 1px solid var(--primary-alt-light-color);
        margin-bottom: 1em;
        // justify-self: center;
        left: 30px;
    }

    .contentUrlBlock{
        text-align: center;
        padding: 10px;
        letter-spacing: 1px;
        font-size: 15px;
    }

    //MOBILE MENU SECTION START

    .menuContent {
        padding: 15px 10px;
    }

    .menuIcon {
        background-color: rgb(229, 239, 229);
        width: 30%;
        border-radius: 20px;
    }

    .unLaunchedItemIcon {
        background-color: rgb(222, 222, 222);
        width: 30%;
        border-radius: 20px;
        filter: grayscale(100%);
    }

    .unLaunchedItemTitle {
        color: grey;
    }

    .unLaunchedSubItem {
        filter: grayscale(100%);
    }
    

    //MOBILE MENU SECTION END
}
