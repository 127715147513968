/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.RewardProgressBar {
    &-Content {
        --progressbar-bg-color: var(--secondary-base-color);
        --progressbar-active-bg-color: var(--primary-base-color);
        margin-bottom: 3rem;
        @include mobile {
            margin-bottom: 1rem;
            margin-top: 1em;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            padding: 1px 0px;
        }
    }
    &-TierBar {
        margin: 6rem 3.5rem 5rem;
        position: relative;
        height: 11px;
        background: var(--progressbar-bg-color);
        border-radius: 50rem;
    }
    &-Points {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    &-TierPoint {
        width: 2rem;
        height: 2rem;
        background: var(--progressbar-bg-color);
        border-radius: 100%;
        margin-top: -0.5rem;
        position: relative;
        z-index: 1;
        &.Active {
            background-color: var(--progressbar-active-bg-color);
        }
        .Label {
            position: absolute;
            left: 0;
            top: -3rem;
            font-weight: 700;
            font-size: 1.2rem;
            margin-left: -4.5rem;
            width: 11rem;
            text-align: center;
            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            letter-spacing: 1px;
            @include mobile {
                color: var(--primary-base-color);
            }
            @include desktop {
                transition: all 0.2s linear;
                // background-color: var(--primary-alt-light-color);
                box-shadow: 0px 0px 5px 0px grey;
                border-radius: 20px;
                padding: 5px 2px;
            }
            &:hover {
                text-decoration: underline;
                cursor: pointer;
                @include desktop {
                    transform: scale(1.1);
                    box-shadow: 0px 0px 10px 0px grey;
                }
            }
        }
        .Number {
            position: absolute;
            left: 0;
            top: 2.5rem;
            margin-left: -3rem;
            width: 8rem;
            text-align: center;
        }
        .NumberText {
            display: inline-block;
            background: var(--progressbar-active-bg-color);
            color: #fff;
            border-radius: 50rem;
            font-size: 1.1rem;
            padding: 0.3rem 0.5rem 0.1rem 0.5rem;
            white-space: nowrap;
            line-height: 1;
        }
    }
    &-CompleteBar {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        .Progress-Step {
            position: absolute;
            left: 0;
            top: -12.5px;
            height: 0.5rem;
            padding: 0 2px;
            .Progress {
                height: 0.5rem;
                background: var(--progressbar-active-bg-color);
            }
            &.Complete0 {
                width: 33.333333333333%;
                left: 0%;
            }
            &.Complete1 {
                width: 33.333333333333%;
                left: 33.333333333333%;
            }
            &.Complete2 {
                width: 33.333333333333%;
                left: 66.6666666666666%;
            }
        }
    }

    &-ProgressTextCard {
        padding: 10px 0px;
        border-radius: 5px;
    }

    &-ProgressText {
        text-align: center;
        font-size: 1.2rem;
        letter-spacing: 1px;
        line-height: 30px;
        .ProgressTextLink {
            cursor: pointer;
            display: inline;
            font-weight: bold;
            font-size: 13px;
            color: var(--primary-alt-base-color);
            &:hover {
                text-decoration: underline;
            }
        }
        b {
            color: var(--primary-alt-base-color);
        }
    }
}
.bronzeBgColorClass {
    @include desktop {
        background-color: rgb(177, 141, 89);
        color: white;
    }
}
.silverBgColorClass {
    @include desktop {
        background-color: rgb(192, 192, 192);
    }
}
.goldBgColorClass {
    @include desktop {
        color: white;
        background-color: rgb(223, 192, 103);
    }
}
.platinumBgColorClass {
    @include desktop {
        background-color: rgb(229, 228, 226);
    }
}
