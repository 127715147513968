/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}

.LeftArrow {
    cursor: pointer;
    margin: 0;
    height: 20px;
    display: block;
    width: 20px;
    position: relative;
    font-size: 0;
    &:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 1px;
        top: 10px;
        left: 0;
        background-color: var(--text-color);
    }
    &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        top: 6px;
        left: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-top: 1px solid var(--text-color);
        border-left: 1px solid var(--text-color);
    }
}
.RightCheck {
    position: relative;
    &::before {
        --rightcheck-color: var(--text-color);
        content: "";
        position: absolute;
        pointer-events: none;
        background: var(--rightcheck-color);
        width: 0.24rem;
        height: 0.24rem;
        box-shadow: 2px 0 0 var(--rightcheck-color),
            4px 0 0 var(--rightcheck-color), 4px -2px 0 var(--rightcheck-color),
            4px -4px 0 var(--rightcheck-color),
            4px -6px 0 var(--rightcheck-color),
            4px -8px 0 var(--rightcheck-color),
            4px -10px 0 var(--rightcheck-color);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
.hide-element {
    display: none;
}

//OTP Input styling START

.otpInputContainerSignUp {
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 420px) and (max-width: 811px) {
        display: flex !important;
        justify-content: flex-start;
    }
}
.otpInputContainerSignUp > div {
    display: block !important;
    min-height: 55px;
    max-width: 55px;
    height: 55px;
    @include mobile {
        margin: 1px;
        input {
            padding: 15px;
            min-width: 100%;
        }
    }
    @media screen and (min-width: 420px) and (max-width: 811px) {
        min-height: 55px;
        min-width: 55px;
        height: 55px;
        width: 55px;
    }
    @include desktop {
        margin: 1px;
        input {
            min-width: 100%;
            padding: 15px;
        }
    }
}

.otpInputErrorSignUp {
    border-color: #ff9494;
    box-shadow: 0 0 2px 0px #ff0000;
}

.otpInputSignUp {
    font-size: 20px;
    border-radius: 10px;
    @include desktop {
        width: 51px !important;
    }
    @include mobile {
        width: 47px !important;
    }
}

.otpInputFocusSignUp {
    box-shadow: 0 0 5px 0.2rem rgba(186, 132, 22, 0.5);
    border-color: rgba(186, 132, 22, 0.5) !important;
}

//OTP Input styling END

.LeftAngle {
    position: relative;
    &:after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        pointer-events: none;
        top: 0;
        margin: auto;
        border-color: #000;
        border-style: solid;
        border-width: 0 0 1px 1px;
    }
}
.Image.img-wposition {
    padding-bottom: 0 !important;
    height: auto;
    overflow: visible;
    width: auto;
    .Image-Image {
        position: static;
        object-fit: unset;
    }
}
.react-tel-input .form-control:focus {
    box-shadow: 0 0 5px 0.2rem rgba(186, 132, 22, 0.5) !important;
    border-color: rgba(186, 132, 22, 0.5) !important;
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
    box-shadow: 0 0 5px 0.2rem rgba(186, 132, 22, 0.5) !important;
    border-color: rgba(186, 132, 22, 0.5) !important;
}

.Signup {
    &-InnerWrapper {
        @include desktop {
            padding: 5rem 0;
        }
    }
    &-InnerContainer {
        --text-color: #0a0a0a;
        --progress-background-color: #fff;
        --progress-complete-background-color: var(--primary-base-color);
        --progress-border-color: var(--text-color);
        --progress-complete-border-color: var(--text-color);
        margin: 0 auto;

        @include desktop {
            overflow: hidden;
            padding-inline: 4rem;
            max-width: 550px;
            border-radius: 30px;
        }
        @include narrow-tablet {
            width: 100%;
        }
    }
    &-ContentWrapper {
        padding: 2rem 0 3rem;
        display: flex;
        align-self: center;
        justify-content: center;
    }
    &-ContentList {
        margin-bottom: 1.5rem;
        li {
            margin: 0;
            padding: 0 0 1.2rem 2.4rem;
            position: relative;
            line-height: 1.4;
            &:before {
                display: none;
            }
            .RightCheck {
                position: absolute;
                left: 0;
                top: 8px;
                z-index: 0;
            }
        }
    }
    &-FormTitleWrapper {
        margin: 2rem 0;

        // display: grid;
        // grid-template-rows: 1fr auto;
    }
    &-ImageBox {
        text-align: center;
        margin: 3rem 0;
    }
    &-TACLabel {
        a {
            display: inline;
        }
    }
    &-LoginAction {
        text-align: center;
        margin-bottom: 1rem;
        .Signup-SubTitle {
            font-size: 15px;
            margin: 1rem 0 1rem;
            display: inline-block;
            margin-right: 5px;
            letter-spacing: 0.5px;
        }
        button {
            margin-left: 5px;
            font-size: 15px;
            letter-spacing: 0.5px;
        }
        .Button_likeLink {
            text-decoration: underline;
            &:focus,
            &:hover {
                text-decoration: none;
            }
        }
    }
    &-FormWrapper {
        margin: 2rem 0rem 4rem 0rem;
        font-size: 12px;
        @include desktop {
            border: 1px solid rgba(var(--auth-form-border-color), 0.3);
            background-color: var(--auth-form-wrapper-bg-color);
            box-shadow: 3px 0px 19px var(--secondary-dark-color) inset,
                0px 15px 19px var(--secondary-dark-color),
                0px 0px 19px var(--secondary-dark-color),
                0px 15px 19px var(--secondary-dark-color);
            border-radius: 30px !important;
        }
        @include mobile {
            margin-bottom: 11rem;
        }
    }
    &-Fieldset {
        margin-bottom: 2rem;
        position: relative;
        @include mobile {
            margin-bottom: 0;
        }
        .hideMobileField {
            display: none;
        }
        .readonly {
            pointer-events: none;
        }
        &.SetpasswordFieldset {
            margin-bottom: 2rem;
        }
        input,
        textarea,
        select {
            width: 100%;
        }
        .Field_type_checkbox,
        .Field_type_radio {
            input {
                width: auto;
            }
        }
        .Mobile-Field {
            .control {
                position: relative;
                display: inline-block;
                min-height: 69px;
                @include mobile {
                    width: 100%;
                    min-height: 74px;
                }
                &.verified {
                    &:before {
                        @include mobile {
                            --rightcheck-color: #fff;
                            content: "";
                            position: absolute;
                            pointer-events: none;
                            background: var(--rightcheck-color);
                            width: 0.24rem;
                            height: 0.24rem;
                            box-shadow: 2px 0 0 var(--rightcheck-color),
                                4px 0 0 var(--rightcheck-color),
                                4px -2px 0 var(--rightcheck-color),
                                4px -4px 0 var(--rightcheck-color),
                                4px -6px 0 var(--rightcheck-color),
                                4px -8px 0 var(--rightcheck-color),
                                4px -10px 0 var(--rightcheck-color);
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                            right: 24px;
                            top: 52px;
                            z-index: 20;
                        }
                    }
                    &:after {
                        @include mobile {
                            content: "";
                            position: absolute;
                            pointer-events: none;
                            background: var(--primary-success-color);
                            width: 20px;
                            height: 20px;
                            right: 10px;
                            top: 42px;
                            border-radius: 50%;
                            z-index: 10;
                        }
                    }
                }
            }
            .Resend-Count {
                margin-top: 0.5rem;
                text-align: right;
            }
            input {
                border-radius: 0;
                border: 1px solid var(--input-border-color);
                @include desktop {
                    height: 40px;
                    color: black;
                    letter-spacing: 1px !important;
                }
                @include mobile {
                    width: 100%;
                    height: 50px;
                    color: black;
                    // font-weight: 700 !important;
                    font-size: 20px !important;
                    letter-spacing: 1px !important;
                }
            }
            .react-tel-input {
                font-family: "Muli", sans-serif;
                font-size: 14px;
                margin-top: 30px;
                .flag-dropdown {
                    background: none;
                    border-radius: 0;
                    border: none;
                }
                .special-label {
                    background: none;
                    padding: 0;
                    left: 0;
                    top: -30px;
                    display: block;
                    font-weight: normal;
                    @include desktop {
                        font-size: 15px;
                        letter-spacing: 0.5px;
                    }
                    @include mobile {
                        font-size: 16px;
                        letter-spacing: 0.5px;
                        font-weight: 600;
                    }
                }
            }
        }
        .Country-Flag {
            position: absolute;
            top: 32px;
            left: 1px;
            z-index: 10;
            cursor: pointer;
            @include mobile {
                top: 33px;
            }
            img {
                max-width: 26px;
                width: auto;
            }
            .Field_type_select {
                margin: 0;
                select {
                    width: 70px;
                    padding: 11px 0 11px 8px;
                    border: 0;
                }
                .FieldSelect-Option {
                    padding: 5px 10px;
                    line-height: 1.4;
                    max-height: unset;
                }
                .FieldSelect {
                    &:after {
                        right: 10px;
                    }
                }
            }
        }

        .Mobile-OtpField {
            @include mobile {
                margin-top: 4em;
                width: 100%;
            }
            .Mobile-OtpFieldLabel {
                margin-bottom: 10px;
                @include desktop {
                    font-size: 15px;
                    letter-spacing: 1px;
                    margin-top: 15px;
                }
                @include mobile {
                    font-size: 15px;
                    letter-spacing: 1px;
                    font-weight: 700;
                }
            }

            .Mobile-otpResend {
                @include mobile {
                    margin-top: -1em;
                    float: right;
                    right: 0;
                }
            }
            .Resend-Count {
                @include desktop {
                    position: absolute;
                    right: 4px;
                    top: -80px;
                    min-width: 70px;
                }
                @include mobile {
                    margin-top: 1rem;
                    text-align: right;
                    display: -ms-flexbox;
                    display: flex;
                    margin-left: auto;
                    -ms-flex-pack: end;
                    justify-content: flex-end;
                }
            }
            .Resend-Link {
                cursor: pointer;
            }
        }
        .MobileOtp-Field {
            @include desktop {
                display: inline-block;
            }
        }
        .Change-Number {
            @include desktop {
                display: inline-block;
                vertical-align: top;
                margin-top: 42px;
                padding-left: 15px;
                button {
                    letter-spacing: 0.5px;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    font-weight: 400;
                }
            }
            @include mobile {
                position: absolute;
                margin-top: 5px;
                top: -5px;
                right: 0;
                button {
                    letter-spacing: 0.5px;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    font-weight: 400;
                }
            }
        }
        .Step-Back {
            position: absolute;
            top: 50%;
            left: 20px;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;
            @include mobile {
                display: none;
            }
            .LeftAngle {
                &:after {
                    width: 12px;
                    height: 12px;
                }
            }
        }
        .Field-Message {
            margin-top: 0.8rem;
            display: block;
        }
    }
    &-FieldsetMobileContainer {
        margin: 0 auto;
        max-width: 100%;
        @include desktop {
            max-width: max-content;
        }
    }
    &-FieldsetNameContainer {
        @include desktop {
            margin: 0 5rem 3rem 5rem;
        }
        @include mobile {
            margin-top: 3em;
        }
        max-width: 100%;
        label {
            font-size: 16px;
            letter-spacing: 0.5px;
        }
        .Field {
            margin-top: 15px;
        }
        input {
            border-radius: 10px;
            border-color: rgba(186, 132, 22, 0.6) !important;
            &:focus {
                box-shadow: 0 0 5px 0.2rem rgba(186, 132, 22, 0.6);
            }
        }
    }
    &-FieldsetPasswordContainer {
        display: none;
        margin: 0 5rem;
        max-width: 100%;
        .Field {
            margin-top: 15px;
        }
    }

    &-SingUpActionContainer {
    }

    &-SingUpActionButton {
        cursor: pointer;
        @include desktop {
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2px;
            border: none;
            margin-top: 1em;
            color: white;
            background-color: var(--primary-light-color);
            box-shadow: 0px 0px 10px grey;
            transition: all 0.1s linear;
            width: 100%;
            height: 50px;
            &:hover {
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                background-color: var(--primary-light-color);
                color: white;
            }
        }
        @include mobile {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 2px;
            border-radius: 5px;
            border: none;
            margin-top: 1em;
            color: white;
            background-color: var(--primary-light-color);
            box-shadow: 0px 0px 10px grey;
            transition: all 0.1s linear;
            transform: scale(1);
            width: 100%;
            position: fixed;
            bottom: calc(var(--footer-navbar-height) + 2px);
            left: 0;
            height: 50px;
            &:hover {
                background-color: var(--primary-light-color);
                color: white;
            }
        }
    }

    &-Title {
        margin: 0;
        font-size: 2rem;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        @include mobile {
            display: none;
        }
    }
    &-Steps {
        display: flex;
        margin: 2.5rem 0;
        padding: 0px 20px;
        .Signup-Step {
            margin: 0;
            padding: 0;
            width: 33.33%;
            text-align: center;
            overflow: hidden;
            &::before {
                display: none;
            }
            .StepBox {
                position: relative;
                display: inline-block;
                &::before {
                    content: "";
                    position: absolute;
                    left: 50px;
                    top: 14px;
                    z-index: 0;
                    padding: 0;
                    height: 2px;
                    width: 250px;
                    background: var(--secondary-base-color);
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: -151%;
                    top: 14px;
                    z-index: 0;
                    padding: 0;
                    height: 2px;
                    width: 150px;
                    background: var(--secondary-base-color);
                    z-index: 1;
                }
            }
            .StepText {
                display: inline-block;
                padding-top: 40px;
                position: relative;
                z-index: 10;
                font-size: 14px;
                text-align: center;
                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 0;
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    transform: translateX(-50%);
                    background: var(--progress-background-color);
                    border: 1px solid grey;
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                }
            }
            &.Active {
                .StepText {
                    &::before {
                        background: var(
                            --auth-signup-form-progress-bar-bg-color
                        );
                        border: 1px solid
                            var(--auth-signup-form-progress-bar-bg-color);
                    }
                }
                .StepBox {
                    &::after {
                        background-color: var(
                            --auth-signup-form-progress-bar-bg-color
                        );
                    }
                }
            }
            &.Complete {
                .StepText {
                    &::before {
                        background: var(
                            --auth-signup-form-progress-bar-bg-color
                        );
                        border: 1px solid
                            var(--auth-signup-form-progress-bar-bg-color);
                    }
                }
                .StepBox {
                    &::before {
                        background-color: var(
                            --auth-signup-form-progress-bar-bg-color
                        );
                    }
                    &::after {
                        background-color: var(
                            --auth-signup-form-progress-bar-bg-color
                        );
                    }
                }
            }
            &:first-child {
                text-align: left;
                .StepBox {
                    &::after {
                        display: none;
                    }
                }
            }

            &:last-child {
                text-align: right;
                .StepBox {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    &-TermColumn {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 2rem;
        @include desktop {
            padding-left: 5rem;
            top: -32px;
        }
        @include mobile {
            padding: 0;
        }
        .Field_type_checkbox {
            display: -ms-flexbox;
            display: flex;
            margin-top: 10px;
            // > label {
            //     display: -ms-flexbox;
            //     display: flex;
            //     -ms-flex-direction: row-reverse;
            //     flex-direction: row-reverse;
            //     -ms-flex-align: center;
            //     align-items: center;
            // }
            .input-control {
                margin: -2px 1rem 0 0;
            }
            span {
                font-size: 14px;
            }
        }
        .Signup-TermsAndConditions {
            display: -ms-flexbox;
            display: flex;
            margin-top: 1.2rem;
            -ms-flex-align: start;
            align-items: flex-start;
            .Field {
                margin: 0;
            }
            .Button_likeLink {
                text-decoration: underline;
                &:focus,
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .Field-Message {
            margin-top: 0.8rem;
            display: block;
        }
    }
    &-ReferralCodeInput {
        label {
            font-size: 16px;
        }
        border-radius: 10px;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }
}

.PageBack-Column {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}
.PageBack-Link {
    font-size: 1.4rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    .LeftArrow {
        margin-right: 8px;
    }
}
.Button.SuccessBtn {
    --success-button-background: var(--primary-success-color);
    --success-hover-button-background: var(--primary-success-color);
    background-color: var(--success-button-background);
    border-color: var(--success-button-background);
    &:not([disabled]):hover,
    &:not([disabled]):focus {
        background-color: var(--success-hover-button-background);
        border-color: var(--success-hover-button-background);
    }
}
.Header_name_customer_sub_account {
    .Header-Button_isVisible {
        @include mobile {
            width: 30px;
            height: 30px;
            padding: 0;
            margin-top: -2px;
        }
    }
    .Header-Button_type_back {
        &::before {
            @include mobile {
                width: 0;
                height: 0;
            }
        }
        &::after {
            @include mobile {
                width: 10px;
                height: 10px;
                top: 10px;
                left: 5px;
                border-top: 2px solid var(--header-color);
                border-left: 2px solid var(--header-color);
            }
        }
    }
}
