/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ExpandableContentShowMore {
    text-align: start;

    &-ShowAllButton.Button_likeLink {
        --button-height: fit-content;
        --button-hover-height: fit-content;
        --button-hover-padding: 0;

        margin-block-start: 8px;
        font-weight: 700;
        background-color: lavender;
        padding: 5px 10px;
        border-radius: 30px;
        letter-spacing: 1.5px;
        text-transform: capitalize;
        color: var(--primary-light-color);
        font-size: 12px;
        transition: all 0.2s linear;
        box-shadow: 0px 0px 3px rgb(171, 171, 171);
        &:hover {
            box-shadow: 0px 0px 10px rgb(164, 164, 164);
            color: var(--primary-light-color);
            padding: 5px 10px;
            border-radius: 30px;
            letter-spacing: 1.5px;
            background-color: lavender;
            list-style: none;
            text-decoration: none;
            transform: scale(1.08);
        }
        &:focus:not(:hover) {
            text-decoration: unset;
        }
    }

    &-ExpandableChildren {
        transition: 0.5s;
        overflow: hidden;
    }
}
