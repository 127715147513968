/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.WellnessProgramContent {
    &-ActionButtons {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-items: center;
        padding: 10px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
        button {
            background-color: var(--primary-alt-base-color);
            border-radius: 50px;
            padding: 10px 20px;
            color: white;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: capitalize;
            cursor: pointer;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
            &:hover {
                background-color: var(--primary-alt-light-color);
            }
        }
    }
    &-WellnessProgramContentForms {
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

//REGISTRATION
.zf_lB_Dimmer_650996 {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgb(0, 0, 0);
    opacity: 0.8;
    z-index: 10000000;
}

.zf_lB_Container_650996 {
    position: fixed;
    background-color: white;
    margin: 0;
    margin-right: 0px;
    padding: 0;
    height: 100%;
    width: 90%;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 7px solid none;
    max-height: calc(100% - 60px);
    z-index: 999999;
}

p {
    margin-bottom: 10px;
}

.zf_lB_Wrapper_650996 {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: 0;
    margin-top: -180px;
    z-index: 10000001;
}

.zf_main_id_650996 {
    height: calc(100% - 0px);
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
}

.zf_lb_closeform_650996 {
    position: absolute;
    right: -20px;
    background: #2f2e2e;
    padding: 0;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    top: -15px;
    cursor: pointer;
    border: 2px solid #d9d9d9;
}
.zf_lb_closeform_650996:before,
.zf_lb_closeform_650996:after {
    position: absolute;
    left: 14.5px;
    content: " ";
    height: 19px;
    width: 2px;
    top: 6px;
    background-color: #f7f7f7;
}

.zf_lb_closeform_650996:before {
    transform: rotate(45deg);
}
.zf_lb_closeform_650996:after {
    transform: rotate(-45deg);
}

@media screen and (min-device-width: 10px) and (max-device-width: 380px) {
    .zf_lB_Container_650996 {
        width: 270px !important;
    }
}

@media screen and (min-device-width: 360px) and (max-device-width: 480px) {
    .zf_lB_Container_650996 {
        width: 300px !important;
    }
}

@media screen and (min-device-width: 440px) and (max-device-width: 500px) {
    .zf_lB_Container_650996 {
        width: 380px !important;
    }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
    .zf_lB_Container_650996 {
        width: 450px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 700px) {
    .zf_lB_Container_650996 {
        width: 540px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
    .zf_lB_Container_650996 {
        width: 650px;
    }
}

@media screen and (min-device-width: 801px) and (max-device-width: 1268px) {
    .zf_lB_Container_650996 {
        width: 750px !important;
    }
}
//REGISTRATION END

//ONLINE ASSESSMENT START
.zf_lB_Dimmer_965214 {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgb(0, 0, 0);
    opacity: 0.8;
    z-index: 10000000;
}

.zf_lB_Container_965214 {
    position: fixed;
    background-color: white;
    margin: 0;
    margin-right: 0px;
    padding: 0;
    height: 100%;
    width: 90%;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 7px solid none;
    max-height: calc(100% - 60px);
    z-index: 999999;
}

p {
    margin-bottom: 10px;
}

.zf_lB_Wrapper_965214 {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: 0;
    margin-top: -180px;
    z-index: 10000001;
}

.zf_main_id_965214 {
    height: calc(100% - 0px);
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
}

.zf_lb_closeform_965214 {
    position: absolute;
    right: -20px;
    background: #2f2e2e;
    padding: 0;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    top: -15px;
    cursor: pointer;
    border: 2px solid #d9d9d9;
}
.zf_lb_closeform_965214:before,
.zf_lb_closeform_965214:after {
    position: absolute;
    left: 14.5px;
    content: " ";
    height: 19px;
    width: 2px;
    top: 6px;
    background-color: #f7f7f7;
}

.zf_lb_closeform_965214:before {
    transform: rotate(45deg);
}
.zf_lb_closeform_965214:after {
    transform: rotate(-45deg);
}

@media screen and (min-device-width: 10px) and (max-device-width: 380px) {
    .zf_lB_Container_965214 {
        width: 270px !important;
    }
}

@media screen and (min-device-width: 360px) and (max-device-width: 480px) {
    .zf_lB_Container_965214 {
        width: 300px !important;
    }
}

@media screen and (min-device-width: 440px) and (max-device-width: 500px) {
    .zf_lB_Container_965214 {
        width: 380px !important;
    }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
    .zf_lB_Container_965214 {
        width: 450px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 700px) {
    .zf_lB_Container_965214 {
        width: 540px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
    .zf_lB_Container_965214 {
        width: 650px;
    }
}

@media screen and (min-device-width: 801px) and (max-device-width: 1268px) {
    .zf_lB_Container_965214 {
        width: 750px !important;
    }
}
//ONLINE ASSESSMENT END

//DATA LOG START
.zf_lB_Dimmer_475530 {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgb(0, 0, 0);
    opacity: 0.8;
    z-index: 10000000;
}

.zf_lB_Container_475530 {
    position: fixed;
    background-color: white;
    margin: 0;
    margin-right: 0px;
    padding: 0;
    height: 100%;
    width: 90%;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 7px solid none;
    max-height: calc(100% - 60px);
    z-index: 999999;
}

p {
    margin-bottom: 10px;
}

.zf_lB_Wrapper_475530 {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: 0;
    margin-top: -180px;
    z-index: 10000001;
}

.zf_main_id_475530 {
    height: calc(100% - 0px);
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
}

.zf_lb_closeform_475530 {
    position: absolute;
    right: -20px;
    background: #2f2e2e;
    padding: 0;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    top: -15px;
    cursor: pointer;
    border: 2px solid #d9d9d9;
}
.zf_lb_closeform_475530:before,
.zf_lb_closeform_475530:after {
    position: absolute;
    left: 14.5px;
    content: " ";
    height: 19px;
    width: 2px;
    top: 6px;
    background-color: #f7f7f7;
}

.zf_lb_closeform_475530:before {
    transform: rotate(45deg);
}
.zf_lb_closeform_475530:after {
    transform: rotate(-45deg);
}

@media screen and (min-device-width: 10px) and (max-device-width: 380px) {
    .zf_lB_Container_475530 {
        width: 270px !important;
    }
}

@media screen and (min-device-width: 360px) and (max-device-width: 480px) {
    .zf_lB_Container_475530 {
        width: 300px !important;
    }
}

@media screen and (min-device-width: 440px) and (max-device-width: 500px) {
    .zf_lB_Container_475530 {
        width: 380px !important;
    }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
    .zf_lB_Container_475530 {
        width: 450px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 700px) {
    .zf_lB_Container_475530 {
        width: 540px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
    .zf_lB_Container_475530 {
        width: 650px;
    }
}

@media screen and (min-device-width: 801px) and (max-device-width: 1268px) {
    .zf_lB_Container_475530 {
        width: 750px !important;
    }
}
//DATA LOG END

//PRE-WEEK MEAL PLAN START
.zf_lB_Dimmer_618141{ 
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgb(0, 0, 0);
    opacity: 0.8;
    z-index: 10000000;
}

.zf_lB_Container_618141{
	position: fixed;
	background-color: white;
	margin: 0;
	margin-right: 0px;
	padding: 0;
	height: 100%;
	width:  90%;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	border: 7px solid none;
	max-height: calc(100% - 60px);
	z-index: 999999;
}

p{
margin-bottom: 10px;
}

.zf_lB_Wrapper_618141{
	position: fixed;
    top: 50%;
    left: 50%;
    margin-left: 0;
    margin-top: -180px;
    z-index: 10000001;
}

.zf_main_id_618141{
	height: calc(100% - 0px);
	display: flex;
	overflow-y: auto;
	overflow-x: hidden;
}

.zf_lb_closeform_618141 {
    position: absolute;
    right: -20px;
    background: #2f2e2e;
    padding: 0;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    top: -15px;
    cursor: pointer;
    border: 2px solid #d9d9d9;
}
.zf_lb_closeform_618141:before, .zf_lb_closeform_618141:after {
    position: absolute;
    left: 14.5px;
    content: ' ';
    height: 19px;
    width: 2px;
    top: 6px;
    background-color: #f7f7f7;
}

.zf_lb_closeform_618141:before {
  transform: rotate(45deg);
}
.zf_lb_closeform_618141:after {
  transform: rotate(-45deg);
} 



@media screen and (min-device-width: 10px) and (max-device-width: 380px) {  
   .zf_lB_Container_618141 {
  width: 270px !important;
} 
}

@media screen and (min-device-width: 360px) and (max-device-width: 480px) {  
   .zf_lB_Container_618141 {
  width: 300px !important;
} 
}

@media screen and (min-device-width: 440px) and (max-device-width: 500px) {  
   .zf_lB_Container_618141 {
  width: 380px !important;
} 
}

@media only screen  and (min-width:500px) and (max-width:600px)  {  
 	.zf_lB_Container_618141 {
	width: 450px;
 }
}

@media only screen  and (min-width:601px) and (max-width:700px)  {  
 	.zf_lB_Container_618141 {
	width: 540px;
 }
}

@media only screen  and (min-width:700px) and (max-width:800px)  { 
.zf_lB_Container_618141 {
	width: 650px;
 }
}

@media screen and (min-device-width: 801px) and (max-device-width: 1268px) {  
   .zf_lB_Container_618141 {
  width: 750px !important;
} 
}
//PRE-WEEK MEAL PLAN END