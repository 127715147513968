/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$select-width: 160px !default;
$select-height: 45px !default;
$select-option-height: 36px !default;
$select-arrow-height: 14px !default;
$select-arrow-width: 14px !default;

.FieldSelect {
    cursor: pointer;

    &-Clickable {
        display: flex;
        align-items: center;
    }

    .ChevronIcon {
        display: inline-block;
        width: $select-arrow-width;
        height: $select-arrow-height;
        pointer-events: none;

        position: absolute;
        inset-inline-end: 12px;
    }

    &_isExpanded::after {
        transform: rotate(-90deg);
    }

    &-Select {
        min-width: 100%;
        padding-inline-end: 40px;
        width: 100%;

        @include desktop {
            pointer-events: none;
        }

        &_isExpanded {
            border-color: var(--input-border-color-focus);
            opacity: 1;
        }
    }

    &-Options {
        position: absolute;
        width: 100%;
        inset-block-start: 100%;
        inset-inline-start: 0;
        z-index: 40;
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: transparent;

        overflow-y: auto;
        transition: 200ms max-height;
        will-change: max-height;
        max-height: 0;
        -webkit-overflow-scrolling: touch;
        min-width: 100%;

        // Firefox support
        scrollbar-width: none !important;
        scrollbar-color: none !important;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
        }

        &_isExpanded {
            @include desktop {
                z-index: 5;
                max-height: 200px;
                border-color: #0a0a0a;
                border-color: var(--input-border-color);
                overflow-y: auto;
                background-color: #{$white};
            }
        }
    }

    &-Option {
        padding: 0;
        margin: 0;
        z-index: 1;
        transition: 100ms max-height ease-in;
        will-change: max-height;
        max-height: $select-option-height;
        line-height: 36px;
        padding-inline-start: 18px;
        background: var(--color-white);

        @include mobile {
            line-height: 42px;
            padding-inline-start: 21px;
        }

        &::before {
            content: none;
        }

        &:last-of-type {
            margin: 0;
        }

        &:hover,
        &:focus {
            @include desktop {
                background: var(--secondary-base-color);
            }
        }
    }
}
