/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.VoiceSearch {
    top: 50%;
    transform: translateY(-45%);
    height: 220px;
    &-VoiceSearchCloseContainer {
        position: absolute;
        top: -115px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgb(0, 0, 0);
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    &-VoiceResultContainer {
        padding: 20px 10px;
        h2,
        h3 {
            text-align: center;
            color: grey;
            letter-spacing: 1.5px;
        }
    }

    &-VoiceIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2em;
    }
    &-VoiceIconAnimation {
        content: "";
        position: absolute;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        animation: voice 0.6s infinite;
    }

    &-VoiceSearchActionContainer {
        display: flex;
        justify-content: space-evenly;
    }

    &-ClearAction {
        background-color: rgb(239, 79, 95);
    }
    &-ConfirmAction {
        background-color: rgb(111, 220, 158);
    }
    &-ClearAction,
    &-ConfirmAction {
        padding: 10px 20px;
        font-size: 15px;
        letter-spacing: 1.5px;
        border-radius: 30px;
        color: white;
        font-weight: 600;
    }
}
.retryBtn {
    opacity: 0;
}
.showRetryBtn {
    opacity: 1;
}
.hideRetrybtn {
    opacity: 0;
}

@keyframes voice {
    0% {
        width: 50px;
        height: 50px;
        background-color: rgb(173, 183, 181);
    }
    20% {
        width: 70px;
        height: 70px;
        background-color: rgb(187, 195, 193);
    }
    40% {
        width: 90px;
        height: 90px;
        background-color: rgb(198, 210, 208);
    }
    60% {
        width: 110px;
        height: 110px;
        background-color: rgb(209, 222, 219);
    }
    80% {
        width: 130px;
        height: 130px;
        background-color: rgb(219, 234, 231);
    }
    100% {
        width: 150px;
        height: 150px;
        background-color: rgb(226, 235, 233);
    }
}
