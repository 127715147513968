/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, 0.44);
    --search-criteria-search-term-color: #0a0a0a;
    --overlay-border-primary-color: rgba(59, 89, 79, 0.9);
}

.SearchOverlay {
    transition: all 0.4s;
    &_isVisible {
        .SearchOverlay-Results {
            display: block;
            padding: 16px;
        }
    }

    &-Results {
        background: var(--color-white);
        display: none;
        z-index: 110;
        width: 100%;
        @include desktop {
            min-width: 450px;
            border-radius: 20px !important;
            margin-top: 7px;
            position: absolute;
            width: 100%;
            box-shadow: 0px 0px 30px rgb(128, 128, 128);

            ul {
                padding-inline: 10px;
                padding-block: 5px;
                max-height: 400px;
                overflow-y: auto;
            }
        }
    }

    &-CategoryBtnWrapper {
        display: flex;
        justify-content: space-around;
        margin-top: 1em;
        padding-bottom: 10px;
    }

    &-CategoryBtn {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid rgb(227, 227, 227);
        color: grey;
        transition: all 0.2s linear;
        padding-inline: 0px;
        font-size: 12px;
        letter-spacing: 2px;
        &:hover {
            background-color: transparent !important;
            color: var(--primary-alt-base-color) !important;
            border: none !important;
            border-bottom: 2px solid var(--primary-alt-base-color) !important;
        }
    }

    &-CategoryBtnChevron {
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: 0;
        transition: all 0.2s linear;
        opacity: 0;
        transform: translateY(12px);
    }

    &-CategoryListCardContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 0px;
        row-gap: 10px;
        padding: 2em 10px;
        max-height: 413px;
        overflow-y: auto;
        overflow-x: hidden;
        justify-items: center;
    }

    &-CategoryListBtnWrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 2em 10px;
        row-gap: 10px;
        column-gap: 10px;
        
    }

    &-NoResultFound {
        text-align: center;
        img {
            width: 220px;
            height: 220px;
            min-height: 220px;
            min-width: 220px;
        }
    }

    &-CategoryListCard {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
        box-shadow: inset 0px 0px 10px rgb(145, 145, 145);
        border-radius: 15px;
        padding: 5px;
        cursor: pointer;
        transition: all 0.2s linear;
        width: 130px;
        height: 130px;
        transition: all 0.2s linear;
        // @media screen and (min-width: 811px) and (max-width: 950px) {
        //     width: 130px;
        //     height: 130px;
        // }
        &:hover {
            transform: scale(1.06);
            box-shadow: 0px 0px 15px var(--primary-alt-light-color),
                inset 0px 0px 10px rgb(145, 145, 145);
            span {
                font-weight: 600 !important;
            }
        }
    }

    &-CategoryListIcon {
        width: 70px;
        height: 70px;
        img {
            width: 100%;
        }
    }

    &-CategoryListTitle {
        padding-top: 5px;
        color: var(--primary-alt-light-color);
        font-size: 12px;
        letter-spacing: 1.5px;
        text-align: center;
        text-transform: capitalize;
    }

    &-CategoryListBtn {
        background-color: rgb(219, 223, 205);
        border: none;
        border-radius: 30px;
        color: var(--primary-alt-base-color);
        font-size: 13px;
        letter-spacing: 1.5px;
        font-weight: 600;
        padding: 8px 20px;
        text-transform: capitalize;
        transition: all 0.1s linear;
        cursor: pointer;
        &:hover {
            color: var(--primary-light-color);
            transform: scale(1.06);
            background-color: rgb(247, 252, 232);
            box-shadow: 0px 0px 10px grey;
        }
    }
}

.activeList {
    height: var(--button-hover-height);
    color: var(--primary-alt-base-color);
    border-bottom: 2px solid var(--primary-alt-base-color) !important;
    padding-inline: var(--button-padding);
    .SearchOverlay-CategoryBtnChevron {
        opacity: 1;
    }
}
