/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

.SearchField {
    display: none;
    @include mobile {
        @media screen and (min-width: 500px) and (max-width: 810px) {
            width: 55%;
        }
        width: 80%;
    }

    @include desktop {
        @media screen and (min-width: 811px) and (max-width: 950px) {
            margin: 8px 5px 8px 100px;
            width: 55%;
            right: 0rem;
        }
        margin: 8px 5px 8px auto;
        display: block;
        width: 80%;
        max-width: var(--search-bar-max-width);
    }

    &_isVisible {
        display: inline-block;

        @include mobile {
            // margin-block-start: 16px;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
                width: 100%;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        right: 4px !important;
        @include mobile {
            right: 11% !important;
            inset-block-start: calc(50% - 17px);
            width: 35px;
            height: 34px;
            background-color: var(--primary-alt-light-color);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
        }
        @include desktop {
            height: 33px;
            width: 35px;
            inset-block-start: calc(50% - 17px);
            background-color: var(--primary-alt-light-color);
            border-radius: 30px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-Input {
        height: 40px;
        border-radius: 30px;
        
        max-width: var(--search-bar-max-width);
        @include desktop {
            width: 100%;
            &::placeholder{
                letter-spacing: 1px;
            }
            &:focus {
                box-shadow: 0px 0px 10px var(--primary-alt-light-color);
                border: none !important;
            }
        }
        @include mobile {
            width: 90%;
            align-self: center;
            &:focus {
                border: none !important;
            }
        }
    }
}

