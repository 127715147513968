/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --nutrition-fact-grey: rgb(69, 69, 69);
}

.NutritionFacts {
    display: flex;
    justify-content: center;
    p {
        margin: 0;
    }
    .HorizontalLine{
        border-bottom: 1px solid rgb(218, 217, 217);
        width: 50%;
        margin-block: 1em;
    }

    &-NutritionFactsWrapper {
        border: 1px solid var(--primary-alt-light-color);
        margin: 20px;
        float: left;
        width: 100%;
        padding: 5px;
        table {
            border-collapse: collapse;
        }
    }

    &-NutritionHeading {
        border-bottom: 5px solid var(--primary-alt-light-color);
        padding: 0 0 0.25rem 0;
        margin: 0 0 0.5rem 0;
        p {
            margin: 0;
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.5px;
            strong {
                color: var(--primary-base-color);
            }
        }
        span {
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.5px;
            color: var(--nutrition-fact-grey);
        }
    }

    &-NutritionTitle {
        text-align: center;
        font-weight: bold;
        font-size: 2rem;
        line-height: 30px;
        letter-spacing: 1px;
        border-bottom: 5px solid var(--primary-alt-light-color);
    }

    &-DailyCaloriesTable {
        width: 100%;
    }
    &-DailyCaloriesTableSmallIinfo {
        margin: 0;
        font-size: 15px;
        line-height: 25px;
        letter-spacing: 0.5px;
        width: 100%;
        border-bottom: 1px solid black;
    }

    &-CaloriesCount {
        th {
            h2 {
                margin-block: 7px;
                line-height: 25px;
                letter-spacing: 1px;
            }
        }
        td {
            p {
                text-align: right;
                font-size: 20px;
                line-height: 25px;
                letter-spacing: 0.5px;
                font-weight: 700;
            }
        }
    }
    &-NutritionThickRow {
        border-top: 5px solid var(--primary-alt-light-color);
    }
    &-NutritionThickEnd {
        border-bottom: 5px solid var(--primary-alt-light-color);
    }
    &-CaloriesRowInfo,
    &-NutritionRowInfo {
        th {
            padding-block: 2px;
            white-space: nowrap;
            border-top: 1px solid black;
            p {
                display: inline;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.5px;
                font-weight: 700;
            }
            span {
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.5px;
                font-weight: 700;
                margin-left: 1em;
            }
        }
        td {
            padding-block: 2px;
            white-space: nowrap;
            border-block: 1px solid black;
            p {
                text-align: right;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.5px;
                margin-left: 1em;
            }
        }
    }
    &-SubCaloriesRowInfo {
        th {
            padding-block: 0px;
            white-space: nowrap;
            border-top: 1px solid black;
            p {
                display: inline;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.5px;
                color: var(--nutrition-fact-grey);
                strong {
                    margin-left: 1em;
                }
            }
        }
        td {
            padding-block: 0px;
            white-space: nowrap;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            p {
                text-align: right;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.5px;
            }
        }
    }
    &-BlankCell {
        max-width: 30px !important;
        width: 30px !important;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
    }
    &-DailyNutritionTable {
        width: 100%;
        border-bottom: 1px solid var(--primary-alt-base-color);
        th {
            padding-block: 2px;
            strong {
                font-size: 15px;
                line-height: 30px;
                letter-spacing: 0.5px;
                white-space: nowrap;
            }
        }
        td {
            padding-block: 2px;
            p {
                text-align: right;
                font-size: 15px;
                line-height: 30px;
                letter-spacing: 0.5px;
                white-space: nowrap;
            }
        }
    }
    &-NutritionDeclaration {
        border: 5px solid var(--primary-alt-light-color);
        border-bottom-width: 3px;
        p {
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.5px;
            padding: 5px 10px;
            font-weight: bold;
        }
    }
    &-DailyNutritionValue {
        border: 2px solid var(--primary-alt-light-color);
        p {
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.5px;
            padding: 5px 10px;
        }
    }
    &-IngredientsInfo {
        border: 2px solid var(--primary-alt-light-color);
        border-top: 0px;
        padding: 5px 10px;
        h3 {
            margin: 0;
            line-height: 25px;
            letter-spacing: 0.5px;
        }
        p {
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.5px;
            display: inline;
            strong {
                color: var(--primary-alt-base-color);
            }
            span {
            }
        }
    }
    .comma {
        font-weight: bold;
        font-size: 15px;
        padding-inline: 3px;
    }
    &-IngredientWarning {
        border: 2px solid var(--primary-alt-light-color);
        border-top: 0px;
        p {
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.5px;
            padding: 5px 10px;
            strong{
                margin-left: 3px;
                color: var(--primary-alt-base-color);
            }
        }

    }
    &-InformationEmptyNutrition {
        text-align: center;
        top: 100px;
        p {
            font-size: 18px;
            background-color: rgba(43, 76, 50, 0.3);
            line-height: 22px;
            font-weight: 600;
            letter-spacing: 3px;
            font-family: "Riffic";
            padding: 10px;
            color: var(--primary-alt-base-color);
            @include mobile {
                line-height: 30px;
            }
        }
    }
}
