/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.WellnessProgram {
    .ExpandableContent-Button {
        background-color: rgb(246, 246, 246);
        padding: 14px !important;
        border-radius: 10px;
    }
    .ExpandableContent-Button_isContentExpanded {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .ExpandableContent-Content_isContentExpanded {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .ExpandableContent-Heading {
        color: var(--primary-alt-base-color);
    }
    &-ProgramContentContainer {
        @include desktop {
        }
        @include mobile {
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
            margin-bottom: 4em;
        }
        border-radius: 10px;
        overflow: hidden;
    }

    &-ProgramListContainer {
        @include desktop {
        }
        @include mobile {
            padding: 10px;
        }
    }

    &-WellnessProgramHeader {
        padding: 10px 15px;
        letter-spacing: 1px;
        background-color: var(--primary-light-color);
        color: white;
        font-size: 16px;
        font-weight: 600;
    }

    &-Description {
        letter-spacing: 0.3px;
        color: rgb(100, 100, 100);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        padding: 5px 14px !important;
    }

    &-ListWrapper {
        ul {
            li {
                padding-block: 5px;
                border-bottom: 1px solid lightgray;
                display: flex;
                align-items: start;
                margin: 0;

                &::before {
                    content: "";
                    display: none;
                }
                &:last-child {
                    border: 0;
                }
                span {
                    &:before {
                        content: "\2713";
                        color: var(--primary-success-color);
                        left: 0px;
                        position: relative;
                        font-size: 1.4em;
                        font-weight: 700;
                        margin-right: 10px;
                    }
                }
                p {
                    letter-spacing: 0.6px;
                    color: var(--primary-alt-base-color);
                    font-weight: 700;
                    line-height: 20px;
                    font-size: 16px;
                    margin: 0;
                }
                h5 {
                    letter-spacing: 0.3px;
                    color: grey;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    margin: 0;
                }
            }
        }
    }
    &-Content {
        margin-block: 7px;
    }
    &-WellnessProgramActionContainer {
        @include desktop {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            column-gap: 15px;
        }
        @include mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    &-WellnessProgramAction,
    &-WellnessProgramSharingAction,
    &-WellnessProgramCalculatorAction {
        background-color: var(--primary-light-color);
        color: white;
        font-weight: 800;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        margin-block: 10px;
        text-align: center;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        height: 45px;

        @include desktop {
            font-size: 15px;
            padding: 10px 20px;
            border-radius: 10px;
        }
        @include mobile {
            font-size: 15px;
            padding: 10px 0;
            width: 65%;
            border-radius: 40px;
        }

        cursor: pointer;
        &:hover {
            background-color: var(--primary-light-color);
        }
    }
    &-WellnessProgramCalculatorAction {
        background-color: var(--primary-alt-light-color);
        color: white;
        &:hover {
            background-color: var(--primary-alt-light-color);
        }
    }
    &-WellnessProgramSharingAction {
        background-color: var(--primary-light-color);
        color: white;
        &:hover {
            color: white;
            background-color: var(--primary-light-color);
        }
    }
}
