/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}
.SugarVariants {
    padding-inline: 20px;

    &-TypesOfSugarList {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2em;
        margin-bottom: 5em;
        // justify-content: center;
        p {
            cursor: pointer;
            color: var(--primary-alt-base-color);
            border-radius: 20px;
            letter-spacing: 2px;
            padding: 5px 15px;
            margin-inline: 6px;
            box-shadow: 0px 0px 5px rgb(167, 167, 167);
            line-height: 25px;
            font-weight: 600;
            font-size: 13px;
            @include desktop {
                font-size: 15px;
            }
        }
    }
    &-InfoContainer {
        h3 {
            text-align: center;
            border-radius: 20px;
            font-weight: 100;
            letter-spacing: 3px;
            line-height: 30px;
            @include desktop {
                line-height: 35px;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    &-InfoHorizontalLine {
        border-bottom: 1px solid var(--primary-alt-light-color);
        width: 30%;
        left: 35%;
        margin-bottom: 5em;
    }
    &-SugarVariantRow,
    &-TypesOfSugarRow {
        padding: 10px 0;
        border-radius: 15px;
        margin: 20px 0;
        background-image: radial-gradient(
            circle at center center,
            beige,
            rgba(160, 79, 26, 0.3)
        );
        box-shadow: 0px 0px 20px 0px rgb(145, 145, 145);
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 0.1fr 4fr;
            align-items: center;
            justify-content: center;
        }
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    &-SugarVariantIconHeading,
    &-TypesOfSugarIconHeading {
        h3 {
            text-align: center;
            margin: 10px 0 !important;
            letter-spacing: 1px;
            color: black;
        }
        @include desktop {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 10px;
        }
        @include mobile {
            width: 100%;
            text-align: center;
            h3 {
                background-color: var(--primary-alt-dark-color);
                color: white;
                padding: 7px 0;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.5px;
            }
        }
    }
    &-TypesOfSugarIconHeading {
        h3 {
            font-size: 16px;
            letter-spacing: 2px;
            @include mobile {
                background-color: transparent !important;
                color: var(--primary-alt-base-color);
                padding: 7px 0;
                line-height: 25px;
            }
        }
        h4 {
            text-align: center;
            color: var(--primary-alt-base-color);
            letter-spacing: 1px;
            @include mobile {
                letter-spacing: 1.5px;
                padding: 7px 0;
                line-height: 25px;
            }
        }
    }
    &-SugarVariantIcon {
        @include desktop {
            width: 100%;
            text-align: center;
            img {
                padding: 10px;
                width: 105px;
                min-width: 105px;
                min-height: 105px;
            }
        }
        @include mobile {
            width: 100%;
            text-align: center;
            img {
                padding: 10px;
                width: 105px;
                min-width: 105px;
                min-height: 105px;
            }
        }
    }
    &-TypesOfSugarIcon {
        @include desktop {
            width: 100%;
            text-align: center;
            img {
                padding: 10px;
                width: 105px;
                min-width: 105px;
                min-height: 105px;
            }
        }
        @include mobile {
            width: 100%;
            text-align: center;
            img {
                padding: 10px;
                width: 175px;
                min-width: 175px;
                min-height: 175px;
            }
        }
    }
    &-VerticalLine {
        @include desktop {
            border-right: 1px solid rgb(181, 181, 181);
            height: 100%;
        }
    }
    &-SugarVariantInfo {
        @include desktop {
            left: 5%;
            width: 90%;
            ul {
                li {
                    &::before {
                        content: "\2713";
                        font-size: 20px;
                        left: -4%;
                        top: 6px;
                        color: var(--primary-alt-base-color);
                    }
                    p {
                        font-size: 17px;
                        line-height: 30px;
                        margin: 0;
                        letter-spacing: 1px;
                        color: var(--primary-alt-base-color);
                    }
                }
            }
        }
        @include mobile {
            left: 20px;
            width: 85%;
            ul {
                li {
                    &::before {
                        content: "\2713";
                        font-size: 25px;
                        left: -10%;
                        top: 7px;
                        color: var(--primary-alt-base-color);
                    }
                    p {
                        font-size: 17px;
                        line-height: 30px;
                        margin: 0;
                        color: var(--primary-alt-base-color);
                    }
                }
            }
        }
    }
    &-ErrorHandler {
        text-align: center;
        top: 100px;
        p {
            font-size: 18px;
            background-color: rgba(43, 76, 50, 0.3);
            line-height: 22px;
            font-weight: 600;
            letter-spacing: 3px;
            font-family: "Riffic";
            padding: 10px;
            color: var(--primary-alt-base-color);
            @include mobile {
                line-height: 30px;
            }
        }
    }
}
