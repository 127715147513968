/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ReferLifely {
    &-ReferralCardContainer {
        background-color: beige;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        margin-inline: auto;

        @include desktop {
            width: 400px;
        }
        @include mobile {
            width: 96%;
            max-width: 400px;
            margin-bottom: 4em;
        }
    }

    &-ReferralCardSectionOne {
        img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            min-height: 200px;
        }
    }

    &-ReferralCardSectionTwo {
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;

        @include mobile {
            height: 300px;
        }
    }

    &-ReferralUrlDescription {
        ul {
            li {
                color: var(--primary-alt-base-color);
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 1px;
                line-height: 1.7;
                padding-left: 15px;
                text-align: justify;

                @include mobile {
                    font-size: 14px;
                }

                &:before {
                    content: "\2022";
                    left: 0;
                }
            }
        }
    }

    &-ReferralUrlViewContainer {
        background-color: rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 10px;
        @include mobile {
            font-size: 11px;
        }
        p {
            margin: 0;
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 1px;
            color: white;

            @include mobile {
                font-size: 11px;
            }
        }
    }

    &-ReferralSharingOptionsContainer {
        margin-top: 2em;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    &-ReferralSharingOption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80px;
        text-align: center;
        a,
        button {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
            margin-bottom: 5px;
        }

        span {
            font-size: 9px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 1.4;
        }
    }

    &-GuestUserSection {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        p {
            font-size: 16px;
            letter-spacing: 1px;
            font-weight: 600;
            margin-bottom: 3em;
            text-align: center;
            color: var(--primary-alt-base-color);
        }
        .Button {
            border-radius: 10px;
            background-color: var(--primary-alt-light-color);
            border: none;
            letter-spacing: 1px;
            width: 40%;
            &:hover {
                border: none !important;
                background-color: var(--primary-alt-base-color) !important;
                border-radius: 10px !important;
            }
        }
    }

    &-GuestUserAuthSection {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
    }
}
