/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.RewardMyReferral{
    &-Wrapper{
        padding: 0;
    }
    &-Notification{
        background-color: #ecfdff;
        padding: 1rem;
        margin-bottom: 3.5rem;
        p{
            margin-bottom: 0;
        } 
    }
    &-InvitedNot{
        border: 1px dashed var(--expandable-content-divider-color);
        padding: 10px 15px 8px;
        margin-bottom: 20px;
        font-size: 1.4rem;
        @include desktop {
            font-size: 1.2rem;
        }
    }
    &-Card{
        margin-bottom: 3rem;
        &.ReferralsContent{
            margin-bottom: 0;
        }
    }
    &-CardTitle{
        border-bottom: 1px solid var(--expandable-content-divider-color);
        margin-bottom: 25px;
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 1.8rem;
        font-weight: 700;
        @include mobile {
            font-size: 1.6rem;
        }
    }
    &-CardContent{
        .Fieldset-Decsription{
            margin-bottom: 1rem;
        }
    }
    &-Table{
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        max-width: 100%;
        thead{
            @include mobile {
                display: none;
            }
        }
        thead,
        tbody{
            tr{
                border-bottom: none;
                background-color: transparent;
            }
            td{
                border-top:1px solid var(--expandable-content-divider-color);
                padding-bottom: 20px;
                padding-top: 20px;
                .Field{
                    margin-top: 0;
                    input{
                        width: 100%;
                    }
                }
            }
        }
        tbody{
            tr{
                @include mobile {
                    border-bottom: 1px solid var(--expandable-content-divider-color);
                }
                td{
                    @include mobile {
                        display: block;
                        padding: 8px 0;
                        border-top: none;
                    }
                   &::before{
                        @include mobile {
                            padding-right: 10px;
                            content: attr(data-th) ': ';
                            display: inline-block;
                            font-weight: 700;
                            margin-bottom: 5px;
                        }
                   }
                   &:first-child{
                        @include mobile {
                            padding-top: 15px;
                            width: 100%;
                        }
                   } 
                   &:last-child{
                        @include mobile {
                            padding-bottom: 15px;
                        }
                   }
                }
            }
        }
        &.InvitationsTable{
            thead,
            tbody{
                tr{
                    th,
                    td{
                        &:first-child{
                            width: 63px;
                        }
                    }
                }
            }
        }
    }
    &-MessageField{
        margin-top: 0;
        margin-bottom: 10px;
        textarea{
            width: 100%;
            min-height: 68px;
        }
    }
}
