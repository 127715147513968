/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}
.MenuItem {
    &-MenuWrapper {
        padding: 0;
        margin: 0;
        text-align: center;
        @include desktop {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            h4 {
                height: 7vh;
                line-height: 30px;
                letter-spacing: 1px;
                font-weight: 700;
                // width: 90%;
            }
        }
        @include mobile {
            display: flex;
            align-items: center;
            text-align: left;
            h4 {
                font-weight: 700;
                width: 70%;
                letter-spacing: 0;
                line-height: 25px;
                font-size: 15px;
            }
        }
    }
    &-MenuIconWrapper {
        @include mobile {
            width: 30%;
            
        }
        @include desktop {
            padding: 20px 10px;
            width: 100%;
            min-width: 166px;
            img {
            }
        }
    }
    &-MenuIcon {
        border-radius: 50%;
        transition: all 0.25s linear;
        @include desktop {
            padding: 10px;
            width:120px;
            min-width: 120px;
            min-height: 120px;
            border: 1px dashed var(--primary-alt-base-color);
            &:hover {
                box-shadow: 0px 0px 20px 0px var(--primary-alt-light-color);
                transform: scale(1.1);
                background-image: radial-gradient(
                    circle at center center,
                    rgb(235, 233, 229) 20%,
                    rgb(53, 95, 63) 80%
                );
            }
        }
    }
    &-MenuTitle {
        color: var(--primary-alt-dark-color);
    }
    &-MenuItemImagePlaceHolder {
        @include desktop {
            display: flex;
            height: auto;
            width: 10%;
            padding: 10px 0;
            img {
                background-color: $white;
            }
        }
    }

    // MOBILE MENUITEM STYLE START

    &-MobileMenuItemCardWrapper {
        display: grid;
        grid-auto-flow: dense;
        padding: 0 10px;
        padding-bottom: 6em;
        transition: all 0.2s linear;
        @include mobile {
            gap: 10px;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        }
        @media screen and (min-width: 560px) and (max-width: 650px) {
            gap: 13px;
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        }
        @include narrow-tablet {
            gap: 13px;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        }
    }

    &-MobileMenuExpandedItems {
        grid-column: 1 / -1;
        border-radius: 15px;
        transition: all 0.2s linear;
        box-shadow: 0px 0px 5px rgb(171, 171, 171);
        transform: translateY(3px);
        margin-block: 10px;
    }

    &-MobileMenuItemCard,
    &-UnLaunchedCategory {
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px 5px;
        height: auto;
        box-shadow: 4px 4px 5px grey;
        &:active {
            box-shadow: 1px 1px 3px grey;
            transition: all 0.1s linear;
            transform: scale(0.99);
        }
        @include mobile {
            background-image: radial-gradient(
                circle at top center,
                rgba(186, 132, 22, 0.5) 60%,
                beige 60%
            );
            height: 175px;
        }
        @include narrow-tablet {
            background-image: radial-gradient(
                150% 100% at center top,
                rgba(186, 132, 22, 0.5) 60%,
                beige 60%
            );
            height: 165px;
        }

        h5 {
            text-align: center;
            line-height: 17px;
            font-weight: 100;
            letter-spacing: 0.7px;
            color: var(--primary-light-color);
            font-size: 13.5px;
            font-family: "Riffic";
        }
    }

    &-UnLaunchedCategory {
        filter: grayscale(100%);
        @include mobile {
            background-image: radial-gradient(
                circle at top center,
                rgba(186, 132, 22, 0.5) 60%,
                beige 60%
            );
            height: 175px;
        }
        @include narrow-tablet {
            background-image: radial-gradient(
                150% 100% at center top,
                rgb(222, 222, 222) 60%,
                beige 60%
            );
            height: 165px;
        }
    }

    &-MobileMenuIcon {
        border-radius: 50%;
        transition: all 0.25s linear;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mobile {
            box-shadow: inset 0px 0px 10px 0px var(--primary-base-color);
            padding: 5px;
            width: 80px;
            height: 80px;
            min-width: 60px;
            min-height: 60px;
            img {
                width: 60px;
                height: 60px;
            }
        }
        @include narrow-tablet {
            min-width: 90px;
            min-height: 90px;
            width: 100px;
            height: 100px;
        }
    }

    // MOBILE MENUITEM STYLE END
}
.unLaunchedItem {
    filter: grayscale(100%);
    color: grey !important;
}
.unLaunchedCategory {
    filter: grayscale(100%);
    color: grey !important;
}
.CardIsActive {
    transition: all 0.1s linear;
    box-shadow: 0px -5px 5px grey, 0px 2px 5px rgb(197, 197, 197);
    transform: translateY(5px);
}
