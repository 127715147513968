/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}
.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;
    background-color: rgba(250, 240, 240, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        min-height: calc(
            100vh - var(--header-height) - var(--breadcrumbs-height)
        );
        margin-block-end: 0;
        margin-block-start: 0;
    }
    &-404ImageWrapper {
        margin-bottom: 10px;
        
        img {
            @include desktop {
                width: 40%;
                min-width: 300px;
                min-height: 300px;
            }
            @include mobile {
                width: 60%;
                min-width: 220px;
                min-height: 220px;
            }
            @include narrow-tablet {
                width: 60%;
                min-width: 300px;
                min-height: 300px;
            }
        }
    }

    &-Button {
        margin-block-start: 24px;
        background-color: rgb(209, 214, 194);
        border: 1px solid rgb(209, 214, 194);
        color: var(--primary-light-color);
        border-radius: 5px;
        &:hover {
            color: rgb(126, 138, 92) !important;
            border: 1px solid rgb(209, 214, 194) !important;
            border-radius: 5px !important;
            background-color: transparent !important;
        }
        @include desktop {
            letter-spacing: 2px;
        }
        @include mobile {
            width: 100%;
            letter-spacing: 2px;
            text-align: center;
        }
    }

    &-describe {
        margin: 0;
        letter-spacing: 1px;
        line-height: 30px;
        @include desktop {
            font-size: 16px;
        }
        @include mobile {
            font-size: 15px;
        }
        @include narrow-tablet {
            font-size: 20px;
        }
    }

    &-Wrapper {
        text-align: center;
        
        @include desktop {
            box-shadow: 0px 0px 50px rgb(172, 172, 172);
            padding: 20px;
            border-radius: 10px;
            background: #ffffff;
            min-width: 500px;
            width: 500px;
        }
        @include mobile {
            box-shadow: 0px 0px 50px rgb(172, 172, 172);
            padding: 20px;
            border-radius: 10px;
            background: #ffffff;
            min-width: 320px;
            width: 340px;
        }
        @include narrow-tablet {
            min-width: 550px;
            width: 550px;
        }
    }
}
