/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ShareReferralLinkInfo {
    @include mobile {
        padding-bottom: 10em;
        padding-inline: var(--my-account-wrapper-margin-mobile);
        margin-top: 1em;
    }
    .ContentWrapper {
        @include mobile {
            padding: 0;
        }
    }
    &-EditAction {
        cursor: pointer;
        background-color: transparent;
        padding: 0;
        border: none;
        @include desktop {
            padding: 10px 20px;
            background-color: var(--primary-light-color) !important;
            border-radius: 10px;
            h4 {
                color: white !important;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 2px;
            }
        }
    }

    &-ShareLinkWrapper {
        display: flex;
        flex-wrap: wrap;
        @include mobile {
            margin-left: 10px;
            padding-top: 1em;
            justify-content: center;
            column-gap: 20px;
            &::before {
                content: "";
                width: 100%;
                height: 1px;
                background-color: lightgrey;
                margin-top: 2em;
                margin-bottom: 2em;
            }
        }
    }
    &-CopyLink {
        @include mobile {
            box-shadow: 0px 0px 5px lightgray inset, 0px 0px 5px lightgray;
            border-radius: 5px;
        }
        @include desktop {
            box-shadow: 0px 0px 10px lightgray;
            border-radius: 10px;
        }
    }

    &-ShareLinkContainer {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        overflow: hidden;
    }

    &-ShareLinkHeaderContainer {
        background-color: var(--primary-light-color);
    }

    &-ShareLinkHeaderWrapper {
        padding: 10px 15px;
        letter-spacing: 1px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-ShareLinkHeaderTitleWrapper {
        display: flex;
        align-items: center;
        h5 {
            color: white;
            font-size: 16px;
            font-weight: 600;
        }
    }

    &-ShareLinkHeaderTitleImage {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }

    &-WhatsappLinkWrapper {
        @include mobile {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
        @include desktop {
            display: none;
        }
    }

    &-ShareLinkListContainer {
        padding: 10px;
    }

    &-ShareLinkList {
        ul {
            li {
                padding-top: 5px;
                padding-bottom: 10px;
                border-bottom: 1px solid lightgray;
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    display: none;
                }
                &:last-child {
                    margin: 0;
                    border: 0;
                    padding-bottom: 5px;
                }
                span {
                    &:before {
                        content: "\2713";
                        color: var(--primary-success-color);
                        left: 0px;
                        position: relative;
                        font-size: 1.4em;
                        font-weight: 700;
                        margin-right: 10px;
                    }
                }
                p {
                    letter-spacing: 1px;
                    color: var(--primary-alt-base-color);
                    font-weight: 700;
                    line-height: 20px;
                    margin: 0;
                }
            }
        }
    }
}
