/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.slide-pane {
    display: flex;
    flex-direction: column;
    background: #fff;
    min-width: 100px;
    height: 100%;
    box-shadow: 0 8px 8px #00000080;
    transition: all 0.4s;
    will-change: transform;
    // max-width: 550px;
}
.slide-pane:focus {
    outline-style: none;
}
.slide-pane_from_right {
    margin-left: auto;
    transform: translateX(100%);
}
.slide-pane_from_right.content-after-open {
    transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
    transform: translateX(100%);
}
.slide-pane_from_left {
    margin-right: auto;
    transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
    transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
    transform: translateX(-100%);
}
.slide-pane_from_bottom {
    height: 100vh;
    // margin-top: 10vh;
    transform: translateY(100%);
}
.slide-pane_from_bottom.content-after-open {
    transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
    transform: translateY(100%);
}
.slide-pane_from_top {
    height: 100vh;
    // margin-bottom: 10vh;
    transform: translateY(-100%);
}
.slide-pane_from_top.content-after-open {
    transform: translateY(0%);
}
.slide-pane_from_top.content-before-close {
    transform: translateY(-100%);
}
.slide-pane__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 299;
    cursor: pointer;
}
.slide-pane__overlay.overlay-after-open {
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.5s;
}
.slide-pane__overlay.overlay-before-close {
    background-color: rgba(0, 0, 0, 0);
}
.slide-pane__header {
    display: flex;
    flex: 0 0 48px;
    align-items: center;
    background: #2c4c32;
    height: var(--header-nav-height);
    letter-spacing: 1.5px;
}
.slide-pane__title-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 15px;
    min-width: 0;
}
.slide-pane .slide-pane__title {
    text-transform: uppercase;
    color: white;
    font-weight: normal;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
    @include mobile {
        font-size: 14px;
    }
    @include desktop {
        font-size: 15px;
    }
}
.slide-pane__close {
    margin-left: 0px;
    padding: 16px;
    opacity: 0.7;
    cursor: pointer;
}
.slide-pane__close svg {
    width: 8px;
    padding: 0;
    @include mobile {
        width: 8px;
    }
}
.slide-pane__message {
    width: 100%;
    z-index: 100;
}
.slide-pane__content {
    position: relative;
    overflow-y: auto;
    flex: 1 1 auto;
    // @include narrow-desktop {
    //     padding: 0px 9px;
    // }
    // @media screen and (min-width: 1920px) {
    //     padding: 0px 40px;
    // }
}
.slide-pane__subtitle {
    font-size: 12px;
    margin-top: 2px;
}
.slide-pane__footer {
    display: flex;
    flex-direction: column;
    flex: 0 0 40px;
    align-items: center;
    z-index: 100;
    height: 84px;
    width: 100%;
    box-shadow: 2px 0px 6px 0px var(--primary-alt-dark-color);
}
.slide-pane__payableamount {
    margin: 10px 0px;
    letter-spacing: 1px;
    .currancy-code {
        margin-left: 10px;
        font-size: medium;
    }
    .payable-amount {
        margin-left: 2px;
        font-size: 18px;
        color: var(--primary-light-color);
    }
}
.footer-btn {
    width: 100%;
    background-color: var(--primary-alt-dark-color);
    border: none;
    border-radius: 0 !important;
    color: white;
    letter-spacing: 2px;
    &:hover {
        background-color: var(--primary-alt-light-color);
        border: none;
        border-radius: 0 !important;
    }
}
.FooterPrimaryDarkShadow {
    box-shadow: 2px 0px 6px 0px var(--primary-dark-color);
}
.FooterPrimaryBaseShadow {
    box-shadow: 2px 0px 6px 0px var(--primary-base-color);
}
.FooterSecondaryDarkShadow {
    box-shadow: 2px 0px 6px 0px var(--primary-dark-color);
}
.FooterSecondaryBaseShadow {
    box-shadow: 2px 0px 6px 0px var(--primary-base-color);
}
