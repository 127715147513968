/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CheckEstimatedDelivery {
    @include desktop {
        margin-block: 2em;
        max-width: 500px;
        margin-inline: auto;
    }
    @include mobile {
        margin-top: 1em;
        margin-bottom: 2em;
        max-width: 500px;
        margin-inline: auto;
    }
    &-LocationMainContainer {
        // padding: 10px;
        &_isIntoCard {
            @include desktop {
                border-radius: 10px;
                box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.2),
                    6px 0px 10px rgba(0, 0, 0, 0.2);
            }
            @include mobile {
                border-radius: 10px;
                box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.1),
                    0px 0px 10px rgba(0, 0, 0, 0.1);
            }
        }
    }

    &-LocationHeadingWrapper {
        @include desktop {
            display: flex;
            justify-content: baseline;
            align-items: center;
            column-gap: 10px;
        }
        @include mobile {
            display: flex;
            justify-content: baseline;
            align-items: center;
            column-gap: 10px;
        }
        h3 {
            @include desktop {
                text-transform: initial;
                font-weight: 700;
                margin: 0;
                color: white;
                letter-spacing: 1px;
                font-size: 16px;
            }
            @include mobile {
                text-transform: initial;
                font-weight: 700;
                margin: 0;
                color: white;
                letter-spacing: 1px;
                font-size: 14px;
            }
        }
        &_isIntoCard {
            @include desktop {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                background-color: var(--primary-alt-base-color);
                padding: 10px 10px 7px 10px;
            }
            @include mobile {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                background-color: var(--primary-alt-base-color);
                padding: 10px 10px 7px 10px;
            }
        }
    }

    &-Divider {
        background-color: white;
        width: 100%;
        height: 0.5px;
    }

    &-LocationInputFieldContainer {
        @include desktop {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            padding-top: 1.5em;
        }
        @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            padding-top: 1.5em;
        }

        input {
            @include desktop {
                border-radius: 10px;
                margin: 0;
                width: 100%;
                letter-spacing: 1px;
                font-size: 16px;
                border: none;
                text-align: center;
                font-weight: 700;
                height: 50px;
            }
            @include mobile {
                border-radius: 10px;
                margin: 0;
                width: 100%;
                letter-spacing: 1px;
                font-size: 16px;
                border: none;
                text-align: center;
                font-weight: 700;
                height: 50px;
            }
        }

        [type="number"] {
            @include desktop {
                pointer-events: all;
            }
            @include mobile {
                pointer-events: all;
            }
        }
        &_isIntoCard {
            @include desktop {
                padding: 10px;
            }
            @include mobile {
                padding: 10px;
            }
            input {
                @include desktop {
                    background-color: rgb(240, 240, 240);
                }
                @include mobile {
                    background-color: rgb(240, 240, 240);
                }
            }
        }
    }

    &-LocationCheckBtn {
        cursor: pointer;
        height: 50px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        background-color: var(--primary-light-color);
        color: white;
        border-radius: 10px;
        transition: all 0.2s ease-in-out;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4),
            inset 0px 0px 10px rgba(0, 0, 0, 0.3);
        @include desktop {
            padding: 10px 20px;
        }
        @include mobile {
            padding: 10px 20px;
        }
        &:hover {
            &:enabled {
                box-shadow: 0px 0px 0px transparent,
                    inset 0px 0px 10px rgba(0, 0, 0, 0.3);
            }
        }
        &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    &-EstimatedLocationDescriptionContainer {
        &_isIntoCard {
            @include desktop {
                border-radius: 10px;
                box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.2),
                    0px 0px 10px rgba(0, 0, 0, 0.3);
            }
            @include mobile {
                border-radius: 10px;
                box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.2),
                    0px 0px 10px rgba(0, 0, 0, 0.3);
            }
        }

        h4 {
            @include desktop {
                letter-spacing: 1px;
                color: rgb(72, 72, 72);
                margin-block: 5px;
                font-weight: 600;
                &:last-child {
                    margin-bottom: 0px;
                }

                span {
                    color: var(--primary-alt-light-color);
                }
            }
            @include mobile {
                letter-spacing: 1px;
                color: rgb(72, 72, 72);
                margin-block: 10px;
                font-weight: 600;
                &:last-child {
                    margin-bottom: 0px;
                }
                span {
                    color: var(--primary-alt-light-color);
                }
            }
        }
    }

    &-DeliveryOptionContainer {
        @include desktop {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background-color: var(--primary-alt-base-color);
            padding: 10px 10px 7px 10px;
        }
        @include mobile {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background-color: var(--primary-alt-base-color);
            padding: 10px 10px 7px 10px;
        }
    }

    &-MessageDescription {
        @include desktop {
            padding: 0px 10px 10px 10px;
        }
        @include mobile {
            padding: 0px 10px 10px 10px;
        }
    }

    &-EstimatedLocationPinOption {
        @include desktop {
            display: flex;
            justify-content: baseline;
            align-items: center;
            column-gap: 10px;
        }
        @include mobile {
            display: flex;
            justify-content: baseline;
            align-items: center;
            column-gap: 10px;
        }

        h3 {
            @include desktop {
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 1px;
                margin: 0;
                color: white;
                span {
                    margin-left: 5px;
                    font-weight: 800;
                    font-size: 18px;
                }
            }
            @include mobile {
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 1px;
                margin: 0;
                color: white;
                span {
                    margin-left: 5px;
                    font-weight: 800;
                    font-size: 18px;
                }
            }
        }
    }

    &-ChangePinActionBtn {
        @include desktop {
            letter-spacing: 1px;
            cursor: pointer;
            font-size: 16px;
            color: white;
            border-bottom: 1px dashed white;
        }
        @include mobile {
            letter-spacing: 1px;
            cursor: pointer;
            font-size: 16px;
            color: white;
            border-bottom: 1px dashed white;
        }
    }
}
