/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

:root {
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    padding-inline-start: 0;
    margin: 24px 24px 5px 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;

    &::before {
        content: none;
    }

    &-CustomAttribute {
        color: var(--color-black);
        font-size: 12px;
        font-weight: 400;
        opacity: 0.5;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 70px calc(100% - 60px);
        grid-column-gap: 12px;
    }

    &-Image {
        // background-color: beige;
        // border: 1px solid #eee;
        // border-radius: 10px;
    }

    &-Title {
        letter-spacing: 1px;
        color: var(--primary-light-color);
        font-weight: 600;
    }

    &-TagLine {
        font-weight: 100;
        font-size: 12px;
        color: grey;
        letter-spacing: 0.5px;
        font-style: italic;
        margin-bottom: 1em;
    }
    &-Tags {
        display: flex;
        flex-wrap: wrap;
        span {
            font-weight: 700;
            letter-spacing: 1px;
            font-size: 12px;    
            color: rgb(122,122,122);
        }
    }
    .vrLine {
        font-weight: bolder;
        font-size: 15px;
        margin-inline: 5px;
        color: var(--primary-alt-light-color);
    }
    .horizantalLine {
        border-bottom: 1px dashed lightgrey;
        width: 100%;
        margin-bottom: 0.7em;
    }
}
