/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ShopMenu {
    height: 30px;
    width: 96px;
    // position: relative;
    // margin-right: 255px;
    // align-self: start;
    .Menu-SubCategoriesWrapper {
        position: absolute;
        top: 32px;
        width: 94vw;
        left: -32px;
        z-index: 100;
        // top: 10px !important;
        box-shadow: 0px 7px 15px 0px rgba(154, 154, 154, 0.5),
            -5px 0px 20px 0px rgba(154, 154, 154, 0.5),
            5px 0px 20px 0px rgba(154, 154, 154, 0.5);
    }
    .Menu-Item {
        margin-bottom: 0;
        padding-bottom: 30px;
        &:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .Menu-Link {
            &::before {
                content: "\e92b";
                font: normal normal normal 12px/1 linearicons;
                font-weight: 700;
                font-size: 20px;
                margin-right: 10px;
                margin-bottom: 4px;
            }
            .Menu-ItemCaption {
                // color: var(--primary-alt-dark-color);
            }
            .Menu-ItemCaption_type_main {
                // letter-spacing: 2px;
                margin-top: 14px;
            }
        }
    }
}
