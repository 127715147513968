/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.OfferDetailBottomSheet {
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    bottom: 0;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        align-items: stretch;
    }
    &_isVisible {
        pointer-events: all;
        width: 100%;
        height: 100vh;

        @include mobile {
            bottom: 0;
            height: 100%;
        }
    }

    &-AllOffersMainContainer,
    &-SpecificOfferMainContainer {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 65%;
        animation: bottomSheet 0.2s linear;
    }

    &-AllOffersMainContainer {
        height: 80%;
    }
    &-SpecificOfferMainContainer {
        height: 50%;
    }

    &-AllOffersContent,
    &-SpecificOfferContent {
        background-color: var(--popup-content-background);
        padding-top: 0;
        height: 100%;
        overflow-y: scroll;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        @include desktop {
            max-height: 100vh;
            width: 100%;
        }
        @include mobile {
            width: 100%;
            max-height: 100vh;
        }
    }

    &-Header {
        margin: 0;
        // font-size: 20px;
        letter-spacing: 1.5px;
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        box-shadow: 0px 2px 5px rgb(233, 233, 233);
        z-index: 10;
        padding: 15px;
    }

    &-NoOfferDataAvailable {
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: var(--primary-alt-base-color);
        font-weight: 700;
    }

    &-AllOffersOuterContainer,
    &-SpecificOfferOuterContainer {
        padding: 10px 15px;
    }

    &-AllOffersOuterContainer {
        .ExpandableContent-Heading {
            text-transform: capitalize;
            letter-spacing: 1px;
            font-size: 15px !important;
            margin-top: 0;
            margin-bottom: 6px;
        }
        .ExpandableContent-SubHeading {
            text-transform: capitalize;
            letter-spacing: 0.5px;
            font-size: 13px;
            margin-top: 0;
            margin-bottom: 0px;
        }
        &_emptyData {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 80%;
        }
    }

    &-OfferDetailsContainer {
        @include desktop {
            margin-top: 15px;
            margin-bottom: 0px;
        }
        @include mobile {
            margin-top: 15px;
            margin-bottom: 0px;
        }
    }

    &-OfferCouponCodeContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border: 1px solid rgb(233, 233, 233);
        padding-block: 1em;
        padding-inline: 1em;
        border-radius: 10px;
        cursor: pointer;
    }

    &-OfferCouponCode {
        border: 1px dashed var(--primary-base-color);
        background-color: rgba(53, 95, 63, 0.1);
        border-radius: 5px;
        padding: 6px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span {
            display: flex;
            justify-content: center;
            align-self: center;
            text-align: center;
            font-weight: 700;
            color: var(--primary-light-color);
            letter-spacing: 1px;
            top: 2px;
        }
    }

    &-TapToCopy {
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 11px;
        color: var(--primary-alt-dark-color);
        font-weight: 700;
        cursor: pointer;
    }

    &-OfferDescriptionContainer {
        margin-top: 1em;
    }

    &-OfferDescription {
        letter-spacing: 1px;
        font-size: 11px;
        color: gray;
        line-height: 20px;
        &::before {
            content: "\2713";
            position: relative;
            left: 0;
            color: white;
            padding: 2px;
            background-color: var(--primary-light-color);
            font-size: 1em;
            margin-right: 5px;
            border-radius: 50%;
        }
    }

    &-OfferApplicableContainer {
        margin-top: 10px;
        h4 {
            color: gray;
            font-size: 12px;
            letter-spacing: 1px;
            margin-bottom: 7px;
            border-bottom: 1px solid lightgray;
            display: inline-block;
            padding-bottom: 2px;
        }
    }

    &-OfferApplicableCategoryContainer {
        display: flex;
        flex-wrap: wrap;
        column-gap: 2px;
        row-gap: 2px;
    }

    &-OfferApplicable {
        padding: 5px 10px;
        background-color: rgb(250, 237, 211);
        border-radius: 30px;
        letter-spacing: 1px;
    }

    &-AllOffersInnerContainer,
    &-SpecificOfferInnerContainer {
        border-bottom: 1px solid lightgray;
        @include desktop {
            border-bottom: 1px solid rgb(136, 136, 136);
            padding-block: 20px;
        }
        padding-block: 10px;
        &:last-child {
            border: 0;
            @include desktop {
                padding-bottom: 0px;
            }
        }
        &:first-child {
            padding-top: 0;
        }
    }

    &-AllOffersTitle,
    &-SpecificOfferTitle {
        text-transform: capitalize;
        letter-spacing: 1px;
        font-size: 17px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    &-AllOffersSubTitle,
    &-SpecificOfferSubTitle {
        text-transform: capitalize;
        letter-spacing: 0.5px;
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 0px;
    }

    &-CloseIconActionWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    &-CloseIconAction {
        margin-bottom: 1em;
        background-color: rgb(0, 0, 0);
        height: 50px;
        width: 50px;
        min-height: 50px;
        min-width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
}

@keyframes bottomSheet {
    from {
        transform: translateY(500px);
    }
    to {
        transform: translateY(0px);
    }
}
