/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

:root {
    --loader-scale: 1;
}

.Loader {
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    z-index: 75;
    margin: auto;
    animation: appear 1ms 200ms forwards;

    &-Image {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
    }
    &-Main {
        @extend %loader;
    }

    &-Scale {
        transform: translate(-50%, -50%) scale(var(--loader-scale));
        inset-inline-start: 50%;
        inset-block-start: calc(50% - (3px * var(--loader-scale)));
        background-color: beige;
        border-radius: 100px;
        padding: 8px;

        @include desktop {
            position: absolute;
        }
        @include mobile {
            position: fixed;
            top: 50%;
        }
        &:dir(rtl) {
            transform: translate(50%, -50%) scale(var(--loader-scale));
        }
    }
}
