/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.OnlinePartnerStores {
    margin-bottom: 10em;
    @include desktop {
        max-width: 600px;
        margin-inline: auto;
    }
    .FieldSelect-Clickable {
        margin-inline: auto;
        @include desktop {
            width: 100%;
        }
        @include mobile {
            width: 90%;
        }
    }

    .FieldSelect-Select {
        border-radius: 10px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: capitalize;
        padding: 0px 15px 0px 15px;
        line-height: 37px;
    }
    .FieldSelect-Options {
        @include desktop {
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            min-width: 100%;
        }
        @include mobile {
        }
    }

    .FieldSelect-Options_isExpanded {
        li {
            text-transform: capitalize;
        }
        @include desktop {
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            min-width: 100%;
            z-index: 40;
            border-radius: 15px;
            top: 107%;
        }
        @include mobile {
        }
    }

    &-CitySelectionDropdownContainer {
        // position: absolute;
        // top: 78%;
        // bottom: 22%;
        width: 100%;
    }

    &-ZomatoOrderTitle {
        font-weight: bold;
        font-size: 15px;
        text-align: center;
        letter-spacing: 1px;
        margin: 0;
        text-transform: uppercase;
        background-color: #e13744;
        color: white;
        margin-top: 10px;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        @include desktop {
            width: 600px !important;
            margin-inline: auto;
        }
        @include mobile {
            width: 100% !important;
        }
    }

    &-ZomatoLocationWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-ZomatoBgImage {
        height: auto !important;
        margin-bottom: 2em;
        min-height: 250px;
        @include desktop {
            width: 600px !important;
        }
        @include mobile {
            width: 100% !important;
        }
    }

    &-LifeChefImg {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            @include desktop {
                min-width: 150px;
                min-height: auto;
                width: 200px !important;
                height: auto !important;
            }
            @include mobile {
                min-width: 150px;
                min-height: auto;
                width: 200px !important;
                height: auto !important;
            }
        }
    }

    &-ZomatoLocationContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include mobile {
            width: 90%;
            row-gap: 10px;
            margin-inline: auto;
        }

        @include desktop {
            row-gap: 10px;
            width: 100%;
        }
    }
    &-SelectedCityRegionsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        @include desktop {
            flex-direction: column;
        }
    }

    &-ZomatoLocationBlock,
    &-ChatOnWhatsappBlock {
        border-radius: 10px;
        background-color: white;
        padding: 10px;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--primary-base-color);
        span {
            color: var(--primary-base-color);
            font-weight: bold;
            letter-spacing: 1px;
            text-align: center;
            font-size: 13px;
        }
    }
    &-CitySelectionDropdownWrapper {
        .FieldSelect-Select {
            box-shadow: 0px 0px 10px var(--primary-alt-light-color);
        }
        select:focus {
            border-color: transparent;
        }
        .FieldSelect-Select_isExpanded {
            box-shadow: 0px 0px 10px var(--primary-light-color);
            border-color: transparent;
        }
    }
    &-ChatOnWhatsappBlock {
        @include mobile {
            position: fixed;
            bottom: 55px;
            width: 90%;
        }
        @include desktop {
            width: 100%;
            margin-top: 2em;
        }
        background-color: rgb(71, 198, 86);
        margin-inline: auto;
        border: 2px solid rgb(71, 198, 86);
        span {
            color: white;
        }
    }
    &-whatsappIcon {
        width: 25px !important;
        height: 25px !important;
        min-width: 25px;
        min-height: 25px;
        top: -2px;
    }
    &-ChatWhatsapp {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        column-gap: 10px;
    }

    &-MobileShareIcon {
        position: fixed;
        right: 20px;
        top: 15px;
        z-index: 1000;
    }

    &-DesktopShareIcon {
        position: absolute;
        z-index: 1000;
        right: 0;
    }

    .shaking {
        animation: shake 0.2s ease-in-out;
        animation-iteration-count: 5;
    }
}
@keyframes shake {
    // 0% {
    //     transform: scale(1);
    // }
    // 25% {
    //     transform: scale(1.1);
    // }
    // 50% {
    //     transform: scale(1);
    // }
    // 75% {
    //     transform: scale(1.1);
    // }
    // 100% {
    //     transform: scale(1);
    // }
    0% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0px);
    }
}
