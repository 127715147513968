.btnPrimaryDark {
    background-color: var(--primary-dark-color) !important;
    &:hover {
        background-color: var(--primary-base-color) !important;
    }
}
.btnPrimaryBase {
    background-color: var(--primary-base-color) !important;
    &:hover {
        background-color: var(--primary-light-color) !important;
    }
}
.btnSecondaryDark {
    background-color: var(--primary-alt-dark-color) !important;
    &:hover {
        background-color: var(--primary-alt-base-color) !important;
    }
}
.btnSecondaryBase {
    background-color: var(--primary-alt-base-color) !important;
    &:hover {
        background-color: var(--primary-alt-light-color) !important;
    }
}

.btnOutlinePrimaryDark {
    background-color: var(--primary-dark-color) !important;
    color: white !important;
    border-color: var(--primary-dark-color) !important;
    &:hover {
        background-color: white !important;
        color: var(--primary-dark-color) !important;
        border-color: var(--primary-dark-color) !important;
    }
}
.btnOutlinePrimaryBase {
    background-color: var(--primary-base-color) !important;
    color: white !important;
    border-color: var(--primary-base-color) !important;
    &:hover {
        background-color: white !important;
        color: var(--primary-base-color) !important;
        border-color: var(--primary-base-color) !important;
    }
}
.btnOutlinePrimaryLight {
    background-color: var(--primary-light-color) !important;
    color: white !important;
    border-color: var(--primary-light-color) !important;
    &:hover {
        background-color: white !important;
        color: var(--primary-light-color) !important;
        border-color: var(--primary-light-color) !important;
    }
}
.btnOutlineSecondaryDark {
    background-color: var(--primary-alt-dark-color) !important;
    color: white !important;
    border-color: var(--primary-alt-dark-color) !important;
    &:hover {
        background-color: white !important;
        color: var(--primary-alt-dark-color) !important;
        border-color: var(--primary-alt-dark-color) !important;
    }
}
.btnOutlineSecondaryBase {
    background-color: var(--primary-alt-base-color) !important;
    color: white !important;
    border-color: var(--primary-alt-base-color) !important;
    &:hover {
        background-color: white !important;
        color: var(--primary-alt-base-color) !important;
        border-color: var(--primary-alt-base-color) !important;
    }
}
.btnOutlineSecondaryLight {
    background-color: var(--primary-alt-light-color) !important;
    color: white !important;
    border-color: var(--primary-alt-light-color) !important;
    &:hover {
        background-color: white !important;
        color: var(--primary-alt-light-color) !important;
        border-color: var(--primary-alt-light-color) !important;
    }
}

.btnHollowSecondaryBase {
    background-color: transparent !important;
    color: var(--primary-alt-base-color) !important;
    border-color: var(--primary-alt-base-color) !important;
    &:hover {
        background-color: var(--primary-alt-base-color) !important;
        color: white !important;
        border-color: var(--primary-alt-base-color) !important;
    }
}



.ripple {
    background-position: center;
    transition: background 0.8s;
    &:hover {
        background: radial-gradient(
                circle,
                transparent 1%,
                rgba(225, 225, 225, 0.2) 1%
            )
            center/15000%;
    }
    &:active {
        background-color: rgba(255, 255, 255, 0.2);
        background-size: 100%;
        transition: background 0s;
        border-color: rgba(255, 255, 255, 0.4) !important;
    }
}
