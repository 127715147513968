/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ResetAttributes {
    @include desktop {
        margin-block-end: 20px;
    }

    @include mobile {
        // margin: 16px;
        margin-block-end: 0;
    }

    &-AttributeValue {
        margin: 10px 0;
        @include desktop {
            display: grid;
            grid-template-columns: 0.5fr 3fr;
        }
        display: flex;
        align-items: center;
    }

    &-AttributeText {
        inset-inline-start: 10px;
        font-size: 14px;
        color: var(--primary-base-color);
        letter-spacing: 1px;
    }

    &-AttributeLable {
        @include mobile {
            font-weight: 500;
            color: var(--primary-light-color);
        }
    }
    &-SelectedOptionLable {
        color: var(--primary-alt-base-color);
        font-weight: 700;
        @include mobile {
            color: var(--primary-alt-base-color);
            margin-left: 10px;
        }
    }

    &-CloseIcon {
        @include mobile {
            height: var(--checkbox-height);
            width: var(--checkbox-width);
        }
        .CloseIcon {
            color: var(--primary-base-color);
            height: var(--checkbox-height);
            width: var(--checkbox-width);
        }
    }

    &-Title {
        margin-block-start: 0;
        text-transform: none;
        letter-spacing: 1px;
        color: var(--primary-alt-base-color);
    }
    &-HorizontalLine {
        border-bottom: 1px solid lightgray;
        margin-bottom: 1em;
    }

    &-MobileTitle {
        color: grey;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: capitalize;
        text-align: center;
        margin-top: 20px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid gray;
    }
}
