/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.VideoPopup {
    --popup-min-width: none;
    --popup-content-padding: 0;
    --content-wrapper-width: none;
    --popup-max-height: none;
    @include mobile {
        --popup-content-padding: 0;
    }

    &-VideoPlayer {
        overflow: hidden;
        width: 100vw;
        height: calc(100vh - 40px);
        background: black;

        @include desktop {
            width: calc(100vw - 130px);
            height: 0;
            padding-block-start: 56.3%; // 16x9 proportion
            max-width: calc((100vh - 300px) / .563);
        }
    }

    &-PlayerContent {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;

        .vimeo {
            height: 100%;
            width: 100%;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-YouTube,
    &-YouTubeContainer {
        height: 100%;
        width: 100%;
    }

    .Popup-Header {
        display: none;
    }
}
