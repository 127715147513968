/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --popup-background: rgba(0, 0, 0, 0.6);
    @include mobile {
        --popup-content-padding: 14px;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-content-padding: 12px;
    --popup-max-height: 80%;

    @include desktop {
        --popup-min-width: 490px;
        --popup-content-padding: 24px;
    }
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    inset-inline-start: 0;
    inset-block-start: var(--header-total-height);

    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        align-items: stretch;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;
        inset-block-start: 0;

        @include mobile {
            // inset-block-start: var(--header-total-height);
            top: var(--header-nav-height);
            height: calc(100% - var(--header-total-height));
        }
    }

    &-MainPopUpContainer {
        @include desktop {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    &-Heading {
        text-decoration: none;
        font-weight: 400;
        margin-block-start: 0;

        @include desktop {
            margin: 0;
            font-weight: 600;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-size: 14px;
        }
    }

    &-Header {
        @include mobile {
            display: none;
        }
        @include desktop {
            position: sticky;
            top: 0;
            z-index: 50;
            background-color: white;
            padding: 10px 0;
            padding-left: var(--popup-content-padding);
            border-bottom: 1px solid lightgray;
        }
    }

    &-Content {
        background-color: var(--popup-content-background);
        border-radius: 10px;

        min-width: var(--popup-min-width);
        max-width: calc(var(--content-wrapper-width) * 0.8);
        border-radius: 10px;
        overflow-y: auto;
        @include mobile {
            height: 100vh;
            border-radius: 0;
            max-height: 100vh;
            overflow-x: hidden;
            padding: var(--popup-content-padding);
            padding-top: 0;
        }
        @include desktop {
            max-height: 600px;
        }
    }
    .ImageZoomPopup-PopupContent {
        .Popup-ChildrenContainer {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-ChildrenContainer {
        padding: var(--popup-content-padding);
        padding-top: 0;
    }

    &-CloseBtnContainer {
        @include desktop {
            cursor: pointer;
            display: flex;
            justify-content: end;
            align-items: center;
            width: 100%;
        }
    }

    &-CloseBtn {
        @include mobile {
            display: none;
        }
        @include desktop {
            float: right;
            right: 0;
        }

        .CloseIcon {
            background-color: rgb(225, 55, 68);
            border-radius: 50%;
            height: 30px;
            width: 30px;
            color: white;
        }

        @include mobile {
            inset-block-start: 25px;
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }

    .NotificationList {
        width: 100vw;
        inset-block-start: 0;
        inset-inline-start: calc(0px - var(--popup-content-padding));
        position: relative;
    }
}

.scrollDisabled {
    position: fixed;
    margin-block-start: 0;
    width: 100%;
    overflow-y: auto;
}
