/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.RewardHistory {
    .ExpandableContent-Button {
        @include mobile {
            background-color: antiquewhite;
            padding: 14px !important;
            border-radius: 10px;
        }
    }
    &-Wrapper {
        padding: 0;
    }
    &-Notification {
        background-color: #ecfdff;
        padding: 1rem;
        @include desktop {
            margin-bottom: 3.5rem;
        }
        p {
            margin-bottom: 0;
        }
    }
    &-Card {
        margin-bottom: 2.5rem;
        @include mobile {
            margin-top: 10px;
            padding: 7px;
            width: 98%;
            margin-inline: auto;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            margin-bottom: 0.4em;
        }
    }
    &-CardTitle {
        border-bottom: 1px solid var(--expandable-content-divider-color);
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 1.4rem;
        font-weight: 700;
        color: grey;
        letter-spacing: 1px;
        margin-bottom: 0.5em;
        @include mobile {
            margin: 0;
            font-size: 1.3rem;
        }
    }
    &-CardContent {
        li {
            margin-bottom: 1rem;
            line-height: 1.42857143;
            padding-left: 2.5rem;
            &::before {
                top: 0;
                left: 0;
            }
        }
    }
    &-Title {
        margin-bottom: 10px;
    }
    &-TabTitle {
        font-weight: 700;
        letter-spacing: 1px;
        display: flex;
        flex-direction: column;
        color: var(--primary-light-color);
        margin: 0px;
        font-size: 17px;
        width: fit-content;

        &::after {
            content: "";
            border-bottom: 1px solid var(--primary-alt-light-color);
        }
    }
    &-Table {
        width: 100%;
        @include mobile {
            margin: 0;
        }
        thead,
        tbody {
            tr,
            td {
                background-color: transparent;
                border: none;
            }
        }
        thead {
            @include mobile {
                display: none;
            }
            tr {
                th {
                    &.Id {
                        width: 50px;
                    }
                    &.Comment {
                        width: 50%;
                    }
                    &.Points {
                        width: 15%;
                    }
                    &.Created {
                        width: 15%;
                    }
                    &.Description {
                        width: 20%;
                    }
                }
            }
        }
        tbody {
            tr {
                @include mobile {
                    border-bottom: 1px solid #ddd;
                    &:last-child {
                        border: 0;
                    }
                }
            }
            td {
                border-top: 1px solid #ddd;
                padding-bottom: 20px;
                padding-top: 20px;
                @include mobile {
                    border-top: 0;
                    display: block;
                    padding: 5px 0;
                }
                &::before {
                    @include mobile {
                        padding-right: 10px;
                        content: attr(data-th) ": ";
                        display: inline-block;
                        font-weight: 700;
                    }
                }
                &:first-child {
                    @include mobile {
                        padding-top: 15px;
                    }
                }
                &:last-child {
                    @include mobile {
                        padding-bottom: 15px;
                    }
                }
                .Complete {
                    color: var(--primary-success-color);
                    font-weight: 700;
                }
                .Alert {
                    font-weight: 700;
                    color: var(--primary-error-color);
                }
            }
        }
    }
    &-Unsubscribe {
        margin-bottom: 20px;
        line-height: 1.6;
        .Button_likeLink {
            text-decoration: underline;
            width: auto;
            display: inline;
            &:hover {
                text-decoration: none;
            }
        }
    }

    &-Empty {
        // padding: 20px 0;
        text-align: center;
        font-size: 15px;
        line-height: 22px;
        font-weight: 600;
        letter-spacing: 2px;
        font-family: "Riffic";
        padding: 10px;
        color: var(--primary-alt-light-color);
        border-radius: 5px;
        @include mobile {
            line-height: 30px;
        }
    }
}
