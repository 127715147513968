/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

@mixin narrow-tablet {
    @media (min-width: 650px) and (max-width: 811px) {
        @content;
    }
}

.SomethingWentWrong {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    margin-block-start: 0;
    padding: 12px;
    margin-block-end: 0;
    background-color: rgba(250, 240, 240, 0.8);
    @include mobile {
        padding: 14px;
    }

    &-Debug {
        text-align: start;
        white-space: pre-line;
        margin-block-start: 48px;
        font-family: "Droid Sans Mono", "monospace", monospace,
            "Droid Sans Fallback";
        background-color: $black;
        color: $white;
        padding: 12px;
        overflow: scroll;
        max-width: calc(100vw - 24px);

        @include mobile {
            margin-block-start: 56px;
            padding: 14px;
            max-width: calc(100vw - 28px);
        }
    }

    &-Wrapper {
        text-align: center;
        @include desktop {
            box-shadow: 0px 0px 50px rgb(172, 172, 172);
            padding: 20px;
            border-radius: 10px;
            background: #ffffff;
            min-width: 500px;
            width: 500px;
        }
        @include mobile {
            box-shadow: 0px 0px 50px rgb(172, 172, 172);
            padding: 20px;
            border-radius: 10px;
            background: #ffffff;
            min-width: 320px;
            width: 340px;
        }
        @include narrow-tablet {
            min-width: 550px;
            width: 550px;
        }
    }

    &-Heading {
        letter-spacing: 2px;
        color: var(--primary-alt-base-color);
    }

    &-SubHeading {
        letter-spacing: 2px;
        color: var(--primary-alt-base-color);
        @include desktop{
            margin-top: 1em;
        }
    }

    &-Button {
        margin-block-start: 24px;
        background-color: rgb(194, 240, 214) !important;
        border: 1px solid rgb(194, 240, 214) !important;
        color: var(--primary-alt-base-color) !important;
        border-radius: 5px;
        &:hover {
            color: rgb(48,191,113) !important;
            border: 1px solid rgb(194, 240, 214) !important;
            border-radius: 5px !important;
            background-color: transparent !important;
        }
        @include desktop {
            letter-spacing: 2px;
        }
        @include mobile {
            width: 100%;
            letter-spacing: 2px;
            text-align: center;
        }
    }

    &-SomethingWentWrongImageWrapper {
        margin-bottom: 10px;
        img {
            @include desktop {
                width: 40%;
                min-width: 300px;
                min-height: 300px;
            }
            @include mobile {
                width: 60%;
                min-width: 220px;
                min-height: 220px;
            }
            @include narrow-tablet {
                width: 60%;
                min-width: 300px;
                min-height: 300px;
            }
        }
    }
}
