/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.LifelyHomePageMobile {
    &-BestSellerProductsContainer {
        margin-block: 0px;
        margin-inline: 5px;
    }
    &-BestSellerProducts {
        @include mobile {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            align-items: center;
            width: 98%;
            margin-inline: auto;
            grid-gap: 10px;
            margin-bottom: 20px;
        }
    }

    &-ProductImageAsButton {
        box-shadow: 5px 5px 5px gray;
        height: 100%;
        border-radius: 5px;
        transition: all 0.1s linear;
        &:last-child {
            box-shadow: none;
        }
        &:active {
            box-shadow: none;
            transform: scale(0.98);
        }
        img {
            min-height: 150px;
            min-width: 150px;
            height: 100%;
            border-radius: 5px;
        }
    }

    &-ProductImageAsButton {
        cursor: pointer;
    }
}
.EntireRangeProducts {
    @include mobile {
        // width: 85%;
        height: auto !important;
        box-shadow: 7px 7px 5px gray;
    }
}
