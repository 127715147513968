

.progressbarContainer {
    position: fixed;
    width: 100%;
    padding: 0 auto;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;

    .progressbarWrapper {
        opacity: 0;
        justify-content: center;
        background-color: #323f31;
        border: 1px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        width: 40%;
        transition: all 0.8s ease;
        transform: translateY(150px);
        &.visible {
            opacity: 1;
            transform: translateY(0);
        }
        svg {
            margin: 0 auto;
            width: 100%;
            flex-direction: center;
            padding: 5px 0;
        }

        .progressMobActive {
            opacity: 1 !important;
            transition: 1s;
        }
        .progressMobInactive {
            opacity: 0;
            transition: 1s;
        }
        .nodeBckgrnd {
            fill: #b88523 !important;
        }
        .progressStrokesCol {
            stroke: white;
            fill: #ba8416;
        }
        .progressStrokesFill {
            fill: white;
        }
        .progressText {
            fill: white;
            font-size: 18px;
            font-family: sans-serif;
            font-weight: bold;
            text-align: center;
        }
        .progressLine {
            stroke: white;
            stroke-width: 2;
            stroke-dasharray: 800;
            stroke-dashoffset: -800;
        }

        /* change color of the elements */
        @include mobile {
            .progressStrokesCol {
                stroke: white;
                fill: #ba8416;
            }
            .progressStrokesFill {
                fill: white;
            }

            .nodeBckgrnd {
                fill: #b88523 !important;
            }

            .progressText {
                fill: white;
                font-size: 16px;
                font-family: sans-serif;
                font-weight: bold;
                text-align: center;
            }

            /* progress line  */
            .progressLine {
                stroke: white;
                stroke-width: 2;
                stroke-dasharray: 800;
                stroke-dashoffset: -800;
            }
            .progressLineMob {
                stroke: white;
                stroke-width: 2;
                stroke-dasharray: 470;
                stroke-dashoffset: -470;
            }
            #navProg {
                display: block;
            }
        }
    }
}
