@font-face {
    font-family: "Avenir";
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url(/style/core/fonts/avenir/AvenirLTStd-Roman.otf);
    src: url(/style/core/fonts/avenir/AvenirLTStd-Roman.eot?#iefix)
            format("embedded-opentype"),
        url(/style/core/fonts/avenir/AvenirLTStd-Roman.woff2) format("woff2"),
        url(/style/core/fonts/avenir/AvenirLTStd-Roman.woff) format("woff"),
        url(/style/core/fonts/avenir/AvenirLTStd-Roman.ttf) format("truetype");
}
@font-face {
    font-family: "Riffic";
    font-style: normal;
    font-weight: normal;
    font-display: block;
    src: url(/style/core/fonts/riffic/riffic.eot?#iefix)
            format("embedded-opentype"),
        url(/style/core/fonts/riffic/riffic.woff2) format("woff2"),
        url(/style/core/fonts/riffic/riffic.woff) format("woff"),
        url(/style/core/fonts/riffic/riffic.otf),
        url(/style/core/fonts/riffic/riffic.ttf) format("truetype");
}
// $font-avenir: 'Avenir', sans-serif;
// $font-riffic: 'Riffic', sans-serif;

:root {
    --font-avenir: "Avenir", sans-serif;
    --font-riffic: "Riffic", sans-serif;
}
.cms {
    --cms-h2-size: 25px;
    --cms-p-size: 18px;
    --cms-p-line-height: 25px;

    @include mobile {
        --cms-h2-size: 20px;
        --cms-p-size: 15px;
        --cms-p-line-height: 15px;
    }
}
body {
    font-family: var(--font-avenir);
    width: 100vw;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-avenir);
}
