/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.SteviaQuizRegistration {
    @include desktop {
        margin-block: 4em;
    }
    @include mobile {
        margin-top: 6em;
        margin-bottom: 11em;
    }
    &-DesktopMainComtainer {
        @include desktop {
            display: flex;
            // justify-content: center;
            align-items: center;
            background: beige;
            padding: 20px;
        }
    }
    &-SteviaSafetySteps {
        margin-bottom: 3em;
        @include desktop {
            width: 50%;
        }
        img {
            @include desktop {
                width: 100%;
                height: auto;
            }
            @include mobile {
                margin-top: -1em;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
                min-height: 350px;
            }
        }
    }
    &-DesktopFormComtainer{
        @include desktop{
            width: 50%;
        }
    }
    &-SteviaCustomerDetailContainer {
        @include desktop {
            background-color: beige;
            padding: 25px 30px;
            &::before {
                content: "";
                height: 100%;
                width: 1px;
                background-color: rgba(0, 0, 0, 0.08);
                position: absolute;
                top: 0;
                left: -3px;
            }
        }

        @include mobile {
            max-width: 400px;
            margin-inline: auto;
            background-color: beige;
            padding: 25px 30px;
            border-radius: 20px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        }

        @media screen and (max-width: 450px) {
            width: 90%;
        }

        p {
            font-size: 40px;
            text-align: center;
            margin: 0;
            margin-bottom: 10px;
        }

        h3 {
            margin: 0;
            margin-bottom: 15px;
            letter-spacing: 1px;
            font-family: "riffic";
            text-align: center;
            line-height: 30px;
            color: var(--primary-alt-base-color);
        }
    }

    &-SteviaIntruction {
        font-size: 11px;
        text-align: center;
        letter-spacing: 0.5px;
        line-height: 25px;
        color: white;
        font-weight: 800;
        margin-bottom: 8px;
        background-color: var(--primary-alt-base-color);
        @include desktop {
            margin-inline: auto;
            max-width: 400px;
            border-radius: 5px;
        }
    }

    &-SteviaCustomerName,
    &-SteviaCustomerPhone,
    &-SteviaCustomerAge,
    &-SteviaCustomerGender,
    &-SteviaBrand,
    &-SteviaCustomerDiabetic,
    &-SteviaCustomerHeart,
    &-SteviaCustomerCancer,
    &-SteviaCustomerHealthConscious {
        label {
            font-size: 14px;
            letter-spacing: 0.5px;
            margin-bottom: 5px;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        input {
            margin-bottom: 15px;
            border-radius: 5px;
            font-weight: 300;
        }

        select {
            // margin-top: 15px;
            margin-bottom: 25px;
            letter-spacing: 1px;
            width: 100%;
            font-size: 15px;
            text-align: center;
            border-radius: 40px;
            &:focus {
                box-shadow: 0px 0px 5px var(--primary-light-color);
                border-color: transparent;
            }
        }
        span {
            font-size: 15px;
            letter-spacing: 0.5px;
            font-weight: 400;
            // text-transform: capitalize;
        }

        [type="text"] {
            width: 100%;
            height: 40px;
            font-size: 15px;
            letter-spacing: 0.5px;
            text-transform: capitalize;
            border-radius: 40px;
            &:focus {
                box-shadow: 0px 0px 5px var(--primary-light-color);
                border-color: transparent;
            }
        }

        [type="number"] {
            pointer-events: auto;
            border: 1px solid var(--input-border-color);
            height: 40px;
            width: 100%;
            font-size: 15px;
            text-align: left;
            padding-left: 20px;
            border-radius: 40px;
            &:focus {
                box-shadow: 0px 0px 5px var(--primary-light-color);
                border-color: transparent;
            }
        }
        [type="checkbox"] {
            border: 1px solid var(--input-border-color);
            margin-bottom: 0px;
            appearance: button;
            -moz-appearance: button;
            -webkit-appearance: button;
            &:checked {
                accent-color: var(--primary-light-color);
            }
        }
    }

    &-SteviaCustomerInnerCheckbox {
        margin-bottom: 10px;
        display: flex;
        justify-content: baseline;
        align-items: center;
    }

    &-SteviaNextSlideBtn {
        height: 40px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        background-color: var(--primary-alt-light-color);
        color: white;
        border-radius: 10px;
        transition: all 0.2s ease-in-out;
        @include desktop {
            width: 400px;
        }
        @include mobile {
            padding: 0px 20px;
            position: fixed;
            bottom: 55px;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            max-width: 400px;
            margin-inline: auto;
        }
        left: 50%;
        transform: translateX(-50%);
        margin-top: 2em;
        cursor: pointer;
        &:hover {
            &:enabled {
                background-color: white;
                color: var(--primary-alt-light-color);
                border: 2px solid var(--primary-alt-light-color);
            }
        }
        &:disabled {
            cursor: not-allowed;
            opacity: 0;
        }
    }
}
.steviaQuizRequiredField:after {
    content: "*";
    color: rgb(225, 4, 4);
    font-size: 15px;
}
