/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.InstallPromptIOS {
    background: var(--secondary-light-color);
    padding: 12px;
    margin: 12px;

    @include mobile {
        padding: 14px;
        margin: 14px;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        z-index: 500;
        radius: 10px;
        border-radius: 15px;
    }

    &-Heading {
        margin: 6px 0;
        text-align: center;

        @include mobile {
            margin: 7px 0;
            font-size: 17px;
            line-height: 35px !important;
            letter-spacing: 1px;
            text-transform: capitalize;
        }
    }

    &-Content {
        align-items: center;
        display: flex;
        justify-content: center;
        @include mobile {
            margin: 7px 0;
            font-size: 17px;
            line-height: 35px !important;
            letter-spacing: 1px;
            text-transform: capitalize;
        }
    }

    &-Close {
        color: var(--secondary-dark-color);
        width: 100%;
        text-align: end;
        text-decoration: underline;
        margin-block-start: 5px;
        letter-spacing: 1px
    }

    &-Plus {
        @include ios-plus;

        transform: scale(0.9);
        transform-origin: 50% 50%;
    }

    &-Share {
        @include ios-share;

        transform: scale(0.8);
        transform-origin: 50% 50%;
    }

    p {
        line-height: 1;
    }
}
