/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --install-prompt-android-background: white;
}

.InstallPromptAndroid {
    --button-background: var(--homepage-button-background);
    --button-color: var(--homepage-button-color);
    --button-border: transparent;
    --button-hover-background: var(--homepage-button-color);
    --button-hover-color: var(--homepage-button-background);
    --button-hover-border: transparent;
    --button-padding: 40px;
    --button-border-width: 0;

    background: var(--install-prompt-android-background);
    padding: 12px;
    margin: 12px;

    @include mobile {
        padding: 14px;
        margin: 14px;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        z-index: 500;
        radius: 10px;
        border-radius: 15px;
    }

    &-Content {
        font-size: 17px;
        text-align: center;
        padding-block-start: 24px;
        padding-block-end: 12px;
        padding-inline: 0;

        @include mobile {
            padding-block-start: 28px;
            padding-block-end: 14px;
            padding-inline: 0;
            line-height: 28px;
            letter-spacing: 1px;
            text-transform: capitalize;
        }
    }

    &-Close {
        position: absolute;
        z-index: 10;
        inset-block-start: 0;
        inset-inline-end: 0;
        width: 40px;
        height: 40px;

        &::before,
        &::after {
            inset-block-start: 12px;
        }
    }

    &-Button {
        display: block;
        margin: auto;
        width: max-content;
        background-color: var(--primary-base-color);
        color: white;
        letter-spacing: 1px;
        line-height: 20px;
        border-radius: 30px;
        letter-spacing: 2px !important;
        text-transform: capitalize;
    }
}
