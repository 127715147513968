/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.OfflineStorePopup {
    &-OfflineStoreCityContainer {
        @include desktop {
            margin-top: 3em;
        }
    }

    &-OfflineStoreCityWrapper {
        display: grid;
        grid-template-columns: 0.08fr 2fr 0.08fr;
        padding: 5px 0;
        ul {
            display: flex;
            // flex-wrap: wrap;
            max-width: 450px;
            scroll-behavior: smooth;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            row-gap: 5px;
            touch-action: auto;
            li {
                margin: 5px;
                &:before {
                    content: "";
                }
            }
        }
        ul::-webkit-scrollbar {
            display: none;
        }
    }
    &-scrollRight {
        padding-left: 10px;
    }
    &-scrollLeft {
        padding-right: 10px;
    }
    &-LaunchedCityTitle {
        font-size: 15px;
        color: rgb(165, 164, 164);
        font-style: italic;
        font-weight: 600;
        letter-spacing: 1px;
        display: block;
        text-align: center;
        margin-bottom: 2em;
        margin-top: 1em;
        transition: all 0.2s linear;
        &::after {
            content: "";
            width: 60%;
            position: absolute;
            left: 50%;
            height: 1px;
            bottom: -5px;
            transform: translateX(-50%);
            background: rgb(48, 191, 113);
        }
    }

    &-OfflineStoreOptionTabContainer {
        margin: 10px 0px;
    }
    &-OfflineStoreOptionTabWrapper {
        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            li {
                transition: 0.2s;
                margin: 5px;
                &:before {
                    content: "";
                }
            }
        }
    }
    &-OptionButton {
        font-size: 15px;
        margin: 0 10px;
        cursor: pointer;
        border-radius: 5px;
        letter-spacing: 2px;
        font-weight: 600;
        font-style: italic;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        &:hover {
            border-radius: 5px !important;
        }
    }

    &-OfflineStoreContainer {
        margin-block: 2em;
        max-width: 500px;
    }

    &-OfflineStoreWrapper {
        padding: 5px 0;
        ul {
            display: flex;
            flex-wrap: wrap;
            row-gap: 5px;
            li {
                margin: 5px;
                box-shadow: 0px 0px 7px grey;
                padding: 10px 20px;
                border-radius: 10px;
                width: 100%;
                &:before {
                    content: "";
                }
                h3 {
                    color: var(--primary-alt-base-color);
                    line-height: 30px;
                    letter-spacing: 1px;
                    font-weight: 100;
                    margin-bottom: 5px;
                    margin-top: 0px;
                    @include mobile {
                        line-height: 25px;
                    }
                }
                span {
                    display: block;
                    margin-top: 5px;
                    margin-bottom: 15px;
                    border-bottom: 1px solid rgb(181, 181, 181);
                }
                p {
                    color: grey;
                    letter-spacing: 0.7px;
                    margin-bottom: 5px;
                }
                h4 {
                    font-weight: 600;
                    letter-spacing: 1px;
                    color: grey;
                    @include mobile {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    &-StoreAction {
        display: flex;
        justify-content: space-between;
        margin-top: 1em;
        .telNumber {
        }
        .location {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-LocationButton {
        font-size: 15px;
        margin: 0px;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 30px;
        letter-spacing: 2px;
        font-weight: 100;
        transition: all 0.2s linear;
        box-shadow: 0px 0px 5px rgb(48, 191, 113);
        font-weight: 600;
        text-transform: capitalize;
        &:hover {
            border-radius: 30px !important;
            transform: scale(1.01) !important;
            box-shadow: 0px 0px 7px rgb(48, 191, 113);
        }
    }

    &-LocationAvailableInCityCounts {
        background-color: rgb(255, 77, 77);
        border-radius: 50%;
        width: 20px;
        height: 20px;
        transition: all 0.2s linear;
        position: absolute;
        top: -5px;
        right: 0;
        border: 1px solid rgb(255, 77, 77);
        box-shadow: 1px -1px 5px rgb(123, 206, 161);
        @include mobile {
            top: -5px;
            right: 0;
        }
        span {
            color: white;
            font-weight: 600;
            font-size: 14px;
            padding-left: 2px;
        }
    }
}
.activeOfflineStoreCity {
    box-shadow: 0px 0px 5px rgb(252, 124, 124) !important;
    background-color: rgb(252, 124, 124);
    color: white;
    .OfflineStorePopup-LocationAvailableInCityCounts {
        background-color: #ffffff;
        border: 1px solid rgb(252, 124, 124);
        box-shadow: 0px 0px 5px transparent;
        span {
            color: rgb(255, 77, 77);
        }
    }
}
