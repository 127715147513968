/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.LifelyHomePageDesktop {
    &-RTBBannerContainer {
        background-color: beige !important;

        img {
            min-height: 100px;
            min-width: 100%;
        }
    }

    &-AboutLifelyBrandContainer {
        width: 100%;
        padding-block: 3em;
        background: linear-gradient(beige 50%, rgba(53, 95, 63, 0.3) 95%);
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            width: 60%;
        }
        img {
            cursor: pointer;
            border-radius: 20px;
            box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.6);
            transition: all 0.1s ease-in-out;
            min-height: 275px;
            min-width: 100%;
            &:hover {
                box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.6);
                transform: scale(1.01);
            }

            &:active {
                box-shadow: 0px 10px 20px 4px rgba(0, 0, 0, 0.6);
                transform: scale(1);
            }
        }
    }

    &-AboutLifelyBrandSubContainer {
        cursor: pointer;
        @include desktop {
            img {
                min-height: 400px;
            }
        }
    }
    &-BestSellerProductsContainer {
        margin-block: 0px;
        // margin-inline: 5px;
        padding-block: 3em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(rgba(53, 95, 63, 0.3), beige);
    }

    &-BestSellerBanner {
        width: 100%;
        text-align: center;
        margin-bottom: 1em;
        img {
            width: 90%;
            min-height: 95px;
            min-width: 90%;
        }
    }

    &-BestSellerProducts {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            justify-content: center;
            align-items: center;
            width: 90%;
            margin-inline: auto;
            grid-gap: 20px;
            margin-bottom: 20px;
        }
    }

    &-BannersContainer {
        padding-block: 3em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: linear-gradient(beige 50%, rgba(53, 95, 63, 0.3) 95%);
    }

    &-EntireRangeBanner {
        img {
            min-height: 185px;
            min-width: 100%;
        }
    }

    &-AllProductsBannersContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &-AllProductsBannersWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
    }

    &-ProductImageAsButton {
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.1s linear;
        &:active {
            box-shadow: none;
            transform: scale(0.98);
        }
        img {
            box-shadow: 5px 5px 5px gray;
            border-radius: 5px;
            min-width: 168px;
            min-height: 168px;
        }
    }

    &-AllProductAsButton {
        cursor: pointer;
        margin-top: 3em;
        padding: 15px 50px;
        background-color: var(--primary-light-color);
        color: white;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6),
            inset 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
        transition: all 0.1s linear;
        &:hover {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6),
                inset 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
            transform: scale(0.99);
        }
    }

    &-ProductBannerAsButton {
        cursor: pointer;
        border-radius: 10px;
        transition: all 0.1s linear;
        margin: 5px;
        img {
            transition: all 0.1s linear;
            box-shadow: 5px 5px 5px gray;
            height: 100%;
            border-radius: 10px;
            min-width: 150px;
            min-height: 150px;

            @media screen and (min-width: 812px) and (max-width: 950px) {
                min-width: 125px;
                min-height: 125px;
                width: 125px;
                height: 125px;
            }
            &:active {
                box-shadow: none;
                transform: scale(0.98);
            }
        }
    }

    &-ProductImageAsButton {
        cursor: pointer;
    }

    &-SubscriptionOuterContainer {
        background: linear-gradient(rgba(53, 95, 63, 0.3) 25%, beige 50%);
        padding-bottom: 2em;
    }

    &-SubscriptionHeading {
        text-align: center;
        img {
            width: 100%;
        }
    }

    &-SubscriptionContainer {
        margin: 5px;
        display: flex;
        align-items: center;
        column-gap: 20px;
        background-color: rgb(53, 95, 63);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.4);
        margin-bottom: 3em;
        width: 90%;
        margin-inline: auto;
    }

    &-SubscriberInformationImg {
        img {
            min-height: 300px;
        }
        width: 60%;
    }
    &-NewsLetterContainer {
        width: 40%;
    }
}
