/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.InsulinCalculator {
    height: 100%;
    &-MainContainer {
    }
    &-InnerContainer {
        margin-top: -30px;
        background: white;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        padding: 10px;
        text-align: center;
    }
    &-CalculateDosesContainer {
        @include desktop {
            //     display: flex;
            //     justify-content: baseline;
            //     align-items: center;
        }
    }
    &-TotalDoseContainer {
        background-color: var(--primary-base-color);
        height: 140px;
        color: white;
        padding-inline: 10px;
        padding-top: 10px;
    }
    &-TotalDoseInnerWrapper {
        display: flex;
        justify-content: center;
    }
    &-SuccessImg {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline: 10px;
        img {
            width: 50px;
            height: 50px;
            @include desktop {
                width: 25px;
                height: 25px;
            }
        }
    }
    &-DoseCount {
        font-size: 25px;
        font-weight: 700;
        font-family: "riffic";
        margin: 0;
        color: var(--primary-alt-light-color);
    }
    &-DoseTitle {
        padding-block: 5px;
        width: auto;
        font-weight: 700;
        color: whitesmoke;
        font-size: 15px;
        display: inline;
    }
    &-DoseDescription {
        color: whitesmoke;
        font-size: 13px;
        font-style: italic;
        margin: 0;
    }
    &-CurrentBloodGlucose,
    &-CorrectionFactor,
    &-CarbsIntake,
    &-CarbsRatio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block: 20px;
        padding-inline: 10px;
        input {
            padding-inline: 0;
            text-align: center;
            border: 0;
            border-radius: 5px;
            width: 75px;
            box-shadow: 0px 0px 10px lightgray;
            height: 35px;
            font-weight: 900;
            font-size: 20px;
            font-family: "riffic";
            letter-spacing: 0.4px;
            border: 1px dashed var(--primary-alt-light-color);
        }
        input[type="number"] {
            pointer-events: all;
        }
        label {
            padding-top: 5px;
            font-size: 14px;
            font-weight: 600;
            color: grey;
            letter-spacing: 0.3px;
            text-align: left;
        }
    }

    &-CalculateHandlerContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
    }
    &-Calculate {
        background-color: var(--primary-alt-light-color);
        color: white;
        font-weight: 800;
        letter-spacing: 0.5px;
        margin-block: 10px;
        text-align: center;
        width: 50%;
        box-shadow: 0px 5px 10px gray;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        gap: 10px;

        @include desktop {
            padding: 10px 20px;
            border-radius: 10px;
        }
        @include mobile {
            padding: 10px 15px;
            border-radius: 40px;
        }

        cursor: pointer;
        &:hover {
            background-color: var(--primary-alt-light-color);
        }
    }
}
