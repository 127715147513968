/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ReviewStar {
    --star-outline-color: var(--secondary-dark-color);

    // reset from input default styles
    display: inline;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;

    transition: color 150ms ease-in;
    color: $white;
    text-shadow: -1px 0 var(--star-outline-color),
        0 1px var(--star-outline-color), 1px 0 var(--star-outline-color),
        0 -1px var(--star-outline-color);

    line-height: 1;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: Times; // make sure ★ appears correctly
    letter-spacing: 3px;
    @include desktop {
        font-size: 40px;
    }
    @include mobile {
        font-size: 30px;
    }
    &::after {
        content: "★";
        font-family: serif;
    }

    &:checked,
    &:hover,
    &[checked],
    &:checked ~ input,
    &:hover ~ input,
    &[checked] ~ input {
        --star-outline-color: var(--primary-alt-light-color);

        color: var(--primary-alt-light-color);
    }

    &:focus {
        border: 0;
    }

    &:first-of-type {
        margin-block-start: 12px;
    }
}
