/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

html {
    scroll-behavior: smooth;
}

.Faqs {
    &-EmptyFaqSection {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        top: 200px;
    }
    @include mobile {
        // overflow-x: hidden;
        &:after {
            display: block;
            content: " ";
            margin-bottom: 100px;
            height: 100px;
            visibility: hidden;
            pointer-events: none;
        }
    }
    h2 {
        text-align: center;
        letter-spacing: 2.5px;
        margin: 10px;
        margin-bottom: 15px;
        color: var(--primary-base-color);
    }
    .StickyHeaderFaqs {
        z-index: 10;
        background-color: white;
        margin-bottom: 1em;
        @include mobile {
            position: fixed;
            width: 100%;
            top: var(--header-nav-height);
        }
        @include desktop {
            position: sticky;
            top: 60px;
        }
        ul {
            @include mobile {
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding-inline: 5px;
                padding-block: 5px;
            }
            @include desktop {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                margin-inline: auto;
                width: 90%;
            }
            li {
                margin-block: 5px;
                padding: 5px 20px;
                margin-inline: 5px;
                border: 1px solid grey;
                border-radius: 10px;
                transition: all 0.3s linear;
                &::before {
                    content: "";
                }
                &:hover {
                    border-color: var(--primary-alt-light-color);
                }
                a > h4 {
                    padding: 5px 0px;
                    margin: 0;
                    letter-spacing: 1px;
                    color: grey;
                    text-align: center;
                    font-weight: 600;
                    &:hover {
                        color: var(--primary-alt-light-color);
                    }
                }
            }
        }
    }
    .faqs {
        @include mobile {
            top: 120px;
        }
        @include desktop {
            padding: 10px 50px;
        }
        .FaqsQuestionAnswer {
            .topics {
                @include mobile {
                    padding-inline: 20px;
                    margin-bottom: 2em;
                }
                margin-bottom: 5em;
                h3 {
                    letter-spacing: 2px;
                    margin-bottom: 10px;
                    color: var(--primary-base-color);
                    // color: grey;
                    // text-align: center;
                }
                &:before {
                    display: block;
                    content: " ";
                    margin-top: -124px;
                    height: 124px;
                    visibility: hidden;
                    pointer-events: none;
                    @include mobile {
                        margin-top: -185px;
                        height: 185px;
                    }
                }
            }
            .collapsible {
                background-color: rgb(214, 214, 214);
                color: white;
                cursor: pointer;
                padding: 18px;
                width: 100%;
                border: none;
                text-align: left;
                outline: none;
                font-size: 15px;
                transition: all 0.4s linear;
                position: absolute;
                top: -200px;
            }

            .active,
            .collapsible:hover {
                transition: all 0.4s linear;
                background-color: #555;
            }

            .content {
                padding: 20px 18px;
                display: none;
                overflow: hidden;
                background-color: #f1f1f1;
                transition: all 0.4s linear;
            }
        }
    }
    &-ErrorHandler {
        text-align: center;

        @include mobile {
            top: 100px;
        }
        p {
            font-size: 18px;
            background-color: rgba(43, 76, 50, 0.3);
            line-height: 25px;
            font-weight: 600;
            letter-spacing: 3px;
            font-family: "Riffic";
            padding: 10px;
            color: var(--primary-alt-base-color);
            @include mobile {
                line-height: 30px;
            }
        }
    }

    &-ChatOnWhatsappBlock {
        @include mobile {
            position: fixed;
            top: 11px;
            right: 18px;
            z-index: 100;
        }
        @include desktop {
            display: none;
        }
    }
}
.activeFaqsTab {
    border-color: var(--primary-alt-light-color) !important;
    background-color: var(--primary-alt-light-color);
    a {
        h4 {
            color: white !important;
        }
    }
}
