/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
*/

:root {
    --newsletter-subscription-input-background: var(--input-background-color);
    --newsletter-subscription-placeholder-color: var(--secondary-dark-color);
    --newsletter-subscription-button-background: var(--link-color);
}

.NewsletterSubscription {
    min-width: 230px;

    .FieldForm {
        &-Fieldset {
            &_isLoading {
                opacity: 0.7;
                pointer-events: none;
            }

            .Field {
                width: 100%;
                margin-block-start: 0;

                &-Message {
                    position: absolute;
                    color: wheat;
                }

                input {
                    background-color: var(
                        --newsletter-subscription-input-background
                    );
                    padding: 14px 12px;

                    &::placeholder {
                        color: var(--newsletter-subscription-placeholder-color);
                    }
                }
            }
        }

        &-Fields {
            margin-block-end: 0;
        }
    }

    .Form {
        &_isInvalid {
            margin-block-end: 18px;
        }
    }

    .Button {
        margin-block-start: 32px;
        width: 100%;
        color: white;
        border-color: white;
        border-radius: 10px;
        &:hover {
            background-color: var(--primary-alt-light-color);
            color: white;
            border-color: var(--primary-alt-light-color);
            border-radius: 10px;
        }
    }
}
