/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Ribbon {
    &-ribbon {
        font-size: 16px !important;
        left: -15px;
        width: 100%;
        position: relative;
        background-color: var(--primary-light-color);
        color: #fff;
        text-align: center;
        padding: 5px 0px; /* Adjust to suit */
        // margin: 2px 2px; /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
        // &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            border: 15px solid var(--primary-light-color);
            z-index: -1;
            bottom: 0px;
            right: -1em;
            border-left-width: 1.5em;
            border-right-color: transparent;
        }
    }
    &-ribbon-content {
        letter-spacing: 0.5px;
        font-weight: 400;
        font-style: italic;
    }
    // &-ribbon-content:before,
    // &-ribbon-content:after {
    //     content: "";
    //     position: absolute;
    //     display: block;
    //     border-style: solid;
    //     border-color: var(--primary-dark-color) transparent transparent
    //         transparent;
    //     bottom: -1em;
    // }
    // &-ribbon-content:before {
    //     left: -5;
    //     border-width: 0.6em 0 0 0.9em;
    // }
    // &-ribbon-content:after {
    //     left: 193%;
    //     border-width: 0.6em 0.8em 0 0;
    // }
}
