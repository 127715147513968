/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.NotificationList {
    position: fixed;
    z-index: 401;
    inset-block-start: calc(
        var(--header-height) + var(--breadcrumbs-height) + 20px
    );
    inset-inline-end: 30px;
    max-width: 550px;
    list-style: none;

    @include desktop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    @include mobile {
        overflow: hidden;
        max-width: 100vw;
        width: 100%;
        inset-block-start: var(--header-total-height);
        inset-inline-end: 0;
    }
}
