/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --default-notification-height: 44px;
    --notification-info-color: var(
        --imported_notificationInfo_color,
        var(--secondary-info-color)
    );
    --notification-info-background: var(
        --imported_notificationInfo_background_color,
        var(--primary-info-color)
    );
    --notification-info-height: var(
        --imported_notificationInfo_height,
        var(--default-notification-height)
    );
    --notification-error-color: var(
        --imported_notificationError_color,
        rgb(55, 55, 55)
    );
    --notification-error-background: var(
        --imported_notificationError_background_color,
        rgb(255, 203, 203)
    );
    --notification-error-height: var(
        --imported_notificationError_height,
        var(--default-notification-height)
    );
    --notification-success-color: var(
        --imported_notificationSuccess_color,
        var(--secondary-success-color)
    );
    --notification-success-background: var(
        --imported_notificationSuccess_background_color,
        var(--primary-success-color)
    );
    --notification-success-height: var(
        --imported_notificationSuccess_height,
        var(--default-notification-height)
    );
}
html {
    scroll-behavior: smooth;
}
.Notification {
    --animation-duration: 0; /* to sync CSS animation and JS callback */
    will-change: max-height, opacity, transform, padding, margin;

    align-items: center;
    background: var(--notification-background);
    border-radius: 2px;
    color: var(--notification-color);
    display: inline-flex;
    min-height: var(--notification-height);
    margin-block-end: 5px;
    max-width: 100vw;
    overflow: hidden;
    padding: 6px 26px;
    // position: absolute;
    inset-block-start: 0;
    border-radius: 10px;
    p {
        color: var(--notification-color);
        letter-spacing: 1px;
    }

    @include mobile {
        width: 100%;
        padding: 12px 14px;
        position: fixed;
        top: 70px;
    }

    @include desktop {
        max-width: 550px;
        margin-block-end: 20px;
    }

    &-Text {
        margin-block-start: 0;
        font-weight: bold;
        margin-inline-end: 40px;
        margin-block-end: 0;
    }

    &-Debug {
        white-space: pre-wrap;
        background: white;
        color: black;
        padding: 5px;
        max-height: 150px;
        overflow: scroll;
    }

    &-Button {
        cursor: pointer;
        font-size: 0;
        appearance: none;
        background: none;
        position: absolute;
        inset-inline-end: 0;
        inset-block-start: 0;
        padding: 10px;
        width: 30px;
        height: 30px;
        opacity: 1;

        @include mobile {
            margin: auto;
            inset-block-end: 0;
            inset-inline-end: 14px;
        }

        &:focus {
            outline: none;
        }

        &::before,
        &::after {
            position: absolute;
            inset-block-start: 0;
            inset-block-end: 0;
            inset-inline-end: 0;
            inset-inline-start: 0;
            margin: auto;
            content: " ";
            height: 10px;
            width: 2px;
            background-color: var(--notification-color);
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    &_is {
        &_opening {
            animation: fadeInStart var(--animation-duration)
                cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
        }

        &_closing {
            animation: fadeOutStart var(--animation-duration)
                cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
        }
    }

    &_type {
        &_error {
            --notification-color: var(--notification-error-color);
            --notification-background: var(--notification-error-background);
            --notification-height: var(--notification-error-height);
        }

        &_success {
            --notification-color: var(--notification-success-color);
            --notification-background: var(--notification-success-background);
            --notification-height: var(--notification-success-height);
        }

        &_info {
            --notification-color: var(--notification-info-color);
            --notification-background: var(--notification-info-background);
            --notification-height: var(--notification-info-height);
        }
    }

    @keyframes fadeInStart {
        from {
            opacity: 0;
            // transform: translateY(-300px);
        }

        to {
            opacity: 1;
            // transform: translateY(0);
        }
    }

    @keyframes fadeOutStart {
        from {
            opacity: 1;
            // transform: translateY(0);
            max-height: 100px;
            margin-block-end: 5px;

            @include mobile {
                margin-block-end: 20px;
            }
        }

        to {
            opacity: 0;
            // transform: translateY(-300px);
            max-height: 0;
            padding: 0;
            margin: 0;
        }
    }
}
