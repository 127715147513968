/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --header-height: 60px;
    --header-nav-height: 55px;
    --header-total-height: calc(
        var(--header-height) + var(--offline-notice-height) +
            var(--demo-notice-height) + env(safe-area-inset-top)
    );

    @include desktop {
        --header-logo-width: 194px;
        --header-logo-height: 24px;
        --header-top-menu-height: 40px;
        --content-wrapper-width: none;
        --header-height: calc(
            var(--header-nav-height) + var(--header-top-menu-height) +
                var(--menu-total-height)
        );
    }
    //topbar
    --header-top-bg-color: var(--primary-base-color);
    --header-top-welcome-offer-text-color: var(--color-white);
    --header-top-store-links-text-color: var(--color-white);
    --header-top-store-links-text-hover-color: var(--primary-alt-light-color);

    //menu
    // --header-menu-subcategories-bg-color:var(--primary-light-color);
    // --header-menu-categories-text-color:var(--color-white);
    // --header-menu-categories-text-hover-color:var(--primary-alt-light-color);
    // --header-menu-subcategories-text-color:var(--color-white);
    // --header-menu-subcategories-text-hover-color:var(--primary-alt-light-color);
    --header-menu-subcategories-bg-color: var(--color-white);
    --header-menu-categories-text-color: var(--color-black);
    --header-menu-categories-text-hover-color: var(--primary-alt-light-color);
    --header-menu-subcategories-text-color: var(--color-black);
    --header-menu-subcategories-text-hover-color: var(
        --primary-alt-light-color
    );
    --header-menu-subcategories-border-color: var(--primary-base-color);

    // Non Sticky Header Theme
    --header-nav-bg-color: var(--color-white);
    --header-shop-menu-color: var(--primary-base-color);
    --header-shop-menu-hover-color: var(--primary-alt-light-color);
    --header-account-icon-color: var(--primary-base-color);
    --header-account-icon-hover-color: var(--primary-base-color);
    --header-account-login-register-text-color: var(--color-black);
    --header-account-login-register-text-hover-color: var(--primary-base-color);
    --header-favorites-button-bg-color: var(--primary-alt-dark-color);
    --header-favorites-button-icon-color: var(--primary-base-color);
    --header-favorites-button-icon-hover-color: var(--primary-alt-dark-color);
    --header-cart-button-bg-color: var(--primary-alt-dark-color);
    --header-cart-button-icon-color: var(--primary-base-color);
    --header-cart-button-icon-hover-color: var(--primary-alt-dark-color);

    // Sticky Header Theme
    --header-sticky-nav-bg-color: var(--primary-base-color);
    --header-sticky-shop-menu-color: var(--color-white);
    --header-sticky-shop-menu-hover-color: var(--primary-alt-light-color);
    --header-sticky-account-icon-color: var(--color-white);
    --header-sticky-account-icon-hover-color: var(--color-white);
    --header-sticky-account-login-register-text-color: var(--color-white);
    --header-sticky-account-login-register-text-hover-color: var(
        --primary-alt-light-color
    );
    --header-sticky-favorites-button-bg-color: var(--primary-alt-dark-color);
    --header-sticky-favorites-button-icon-color: var(--color-white);
    --header-sticky-favorites-button-icon-hover-color: var(
        --primary-alt-light-color
    );
    --header-sticky-cart-button-bg-color: var(--primary-alt-dark-color);
    --header-sticky-cart-button-icon-color: var(--color-white);
    --header-sticky-cart-button-icon-hover-color: var(
        --primary-alt-light-color
    );
}

@keyframes slide {
    0% {
        margin-top: -320px;
    }
    10% {
        margin-top: -240px;
    }
    25% {
        margin-top: -240px;
    }
    35% {
        margin-top: -160px;
    }
    50% {
        margin-top: -160px;
    }
    60% {
        margin-top: -80px;
    }
    75% {
        margin-top: -80px;
    }
    85% {
        margin-top: 0px;
    }
    100% {
        margin-top: 0px;
    }
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: 0px;
    height: 0px;
}

@mixin button-visible {
    opacity: 1;
    height: 30px;
    width: 30px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin visible-on-mobile {
    @include button-visible;

    @include desktop {
        @include button-invisible;
    }
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}
.Header {
    width: 100vw;
    @include mobile {
        // display: flex;
        // flex-direction: column;
        // padding-block-start: env(safe-area-inset-top, 0);
        position: fixed;
        z-index: 100;
        inset-block-start: var(--demo-notice-height);
        transition: all 0.5s;
        > * {
            max-width: var(--content-wrapper-width);
            width: 100%;
        }
    }
    @include desktop {
        align-items: center;
        background: var(--color-white);
        display: flex;
        flex-direction: column;
        padding-block-start: env(safe-area-inset-top, 0);
        position: fixed;
        z-index: 100;
        inset-block-start: var(--demo-notice-height);

        > * {
            max-width: var(--content-wrapper-width);
            width: 100%;
        }
    }

    &_menu,
    &_menu_subcategory {
        .Header-Button_type {
            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_menu {
                @include desktop {
                    @include button-invisible;
                }
            }
        }
    }

    &_name {
        &_menu,
        &_menu_subcategory {
            border-block-end: none;
        }

        &_category {
            position: fixed;

            @include mobile {
                border-block-end: none;
            }
        }
    }

    &-Button {
        @include button-invisible;
        transition: all 0.2s;
        &_isVisible {
            @include mobile {
                // margin-block-start: 16px;
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.1;
        }

        &_type {
            &_menu,
            &_minicart {
                @include button-visible;

                @include desktop {
                    margin-inline-start: 35px;
                }
            }

            &_account {
                @include button-visible;
            }

            &_home {
                @include visible-on-mobile;
            }

            &_minicart {
                @include desktop {
                    margin-inline-start: 35px;
                }
            }

            &_back {
                .ChevronIcon {
                    width: 15px;
                    height: 15px;
                    // transform: translateY(5px);
                }
            }

            &_close {
                .CloseIcon {
                    width: 27px;
                    height: 27px;
                }
            }

            &_share {
                @include button-visible;
            }

            &_shareIcon {
                @include button-visible;
            }

            &_compare {
                @include button-visible;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }
    &-Top {
        z-index: 10;
        height: var(--header-top-menu-height);
        //     //max-width: none !important;
        background-color: var(--header-top-bg-color);
        padding-inline: 32px;
        + .Header-Nav {
            background-color: var(--header-nav-bg-color);
            @include desktop{
                height: 100px;
            }
            .Header-MyFavorites_button span {
                background-color: var(--header-favorites-button-bg-color);
            }
            .Header-MyCart_button span {
                background-color: var(--header-cart-button-bg-color);
            }
            .Header-MyFavorites_button > i {
                color: var(--header-favorites-button-icon-color);
                &:hover {
                    color: var(--header-favorites-button-icon-hover-color);
                }
            }
            .Header-MyCart_button > i {
                color: var(--header-cart-button-icon-color);
                &:hover {
                    color: var(--header-cart-button-icon-hover-color);
                }
            }
            a {
                color: var(--color-black);
                &:hover {
                    color: var(--primary-base-color);
                }
            }
            .Menu-ItemCaption {
                color: var(--header-shop-menu-color);
                &:hover {
                    color: var(--header-shop-menu-hover-color);
                }
            }
            .Header-MyAccount_left i {
                color: var(--header-account-icon-color);
                display: flex;
                &:hover {
                    color: var(--header-account-icon-hover-color);
                }
            }

            .Header-MyAccount_right a {
                color: var(--header-account-login-register-text-color);
                text-align: center;
                padding-right: 5px;
                &:hover {
                    color: var(
                        --header-account-login-register-text-hover-color
                    );
                }
            }
            .Header-MyAccount_right span {
                color: var(--header-account-login-register-text-color);
                font-size: 17px;
                padding-right: 2px;
            }
        }
    }

    &-Container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    &-Right {
        text-align: right;
        max-width: 50%;
        height: 100%;
    }

    // &-Left {
    //     // max-width: 100%;
    //     height: 100%;
    //     position: relative;
    //     display: block;
    //     width: 60%;
    //     font-size: 2em;
    //     p {
    //         margin-bottom: 0;
    //         margin-top: 0;
    //         color: var(--header-top-welcome-offer-text-color);
    //         height: 100%;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //     }
    // }
    // @include desktop {
    &-slider-wrapper {
        font-size: 15px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mobile {
            width: 100%;
        }
    }

    &-slider {
        font-weight: 700;
        height: 40px;
        overflow: hidden;
    }

    &-slider div {
        height: 40px;
        color: var(--header-top-welcome-offer-text-color);
        margin-bottom: 40px;
        text-align: center;
        border-radius: 1rem;
        letter-spacing: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: none;
        box-sizing: border-box;
        font-weight: 700;
    }
    &-slider-text:first-child {
        animation: slide 10s linear infinite;
    }
    // }

    &-Links {
        width: 100%;
        height: 100%;
        display: flex;
    }

    &-Link {
        display: flex;
        align-items: center;
        margin-right: 16px;
        padding-right: 15px;
        position: relative;
        color: var(--header-top-store-links-text-color);
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.5px;
        &:after {
            content: "";
            position: absolute;
            top: 30%;
            right: 0;
            height: 15px;
            width: 0;
            border-right: 1px solid #e1e1e1;
        }

        > a {
            // color: var(--header-top-store-links-text-color);
            &:hover {
                color: var(--header-top-store-links-text-hover-color);
            }
        }
        > button {
            &:hover {
                color: var(--color-white);
            }
        }

        &:last-child {
            margin-right: 0;
            padding-right: 0;

            &:after {
                display: none;
            }
        }
    }

    &-MyAccount_left {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        @include mobile {
            margin-left: 5px;
        }

        i {
            font-size: 25px;
            line-height: 1em;
            @include mobile {
                font-size: 22px;
            }
            color: var(--primary-base-color);
        }
    }

    &-MyAccount_right {
        margin-right: 5px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        a {
            display: block;
            font-weight: 700;
            line-height: 18px;
            color: var(--color-black);
            @include mobile {
                font-size: 12px;
            }

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-MyFavorites_button,
    &-MyCart_button {
        display: block;
        position: relative;
        width: 25px;
        height: 42px;
        transition: all 0.4s ease;
        // margin-right: 5px;

        > i {
            font-size: 25px;
            line-height: 42px;
            color: var(--primary-base-color);

            @include mobile {
                font-size: 22px;
            }
        }
        span {
            position: absolute;
            bottom: 0;
            right: -3px;
            display: flex;
            justify-content: center;
            align-items: center;
            vertical-align: top;
            width: 20px;
            height: 20px;
            color: var(--color-white);
            background-color: var(--primary-base-color);
            border-radius: 50%;
            i {
                font-size: 12px;
                font-style: normal;
                line-height: 1em;
                font-weight: 500;
            }
        }
    }
    &-MyFavorites {
        button {
            cursor: pointer;
        }
    }
    &-MyFavorites,
    &-MyCart {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > * {
            margin: 0 10px;
            @include mobile {
                margin: 0 10px;
            }
        }
    }
    &-Product-Categories {
        position: relative;
        height: 100%;
        margin-right: 60px;
    }

    &-Toggle {
        position: relative;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;

        i {
            font-size: 24px;
            margin-right: 10px;
        }

        span {
            color: #000;
            font-size: 16px;
            font-weight: 800;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #000;
            transition: transform 0.75s cubic-bezier(0.7, 0, 0.3, 1);
            transform: scale3d(0, 1, 1);
            transform-origin: 100% 50%;
        }

        &:hover,
        &.active {
            &:before {
                transform-origin: 100% 50%;
                transform: scale3d(1, 1, 1);
            }
        }
    }

    &-MyAccount {
        display: flex;
        flex-flow: row nowrap;
        text-align: left;
        justify-content: center;
    }

    &-MinicartButtonWrapper {
        cursor: pointer;
        display: flex;
    }

    &-CompareButtonWrapper {
        margin-inline-start: 35px;
    }

    &-LogoWrapper {
        height: var(--header-logo-height);
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        width: var(--header-logo-width);
        @include desktop {
            width: 20%;
            // max-width: 160px !important;
            height: 100px;
        }
        // margin-right: 60px;
        transition: all 0.5s;

        @media screen and (min-width: 811px) and (max-width: 950px) {
            height: 50px;
        }
        @include mobile {
            margin: 0;
            margin-right: 10px;
            top: -3px;
            // display: flex;
            // justify-content: center !important;
        }

        @include desktop {
            @include logo-visible;
        }

        &_isVisible {
            @include logo-visible;
        }
    }

    &-Minicart {
        // &ButtonWrapper {
        //     // height: 100%;
        //     // width: 100%;
        // }

        &ItemCount {
            background: var(--primary-base-color);
            border-radius: 50%;
            color: var(--color-white);
            height: 17px;
            padding: 2px;
            position: absolute;
            width: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 80%;
            font-weight: 700;

            @include mobile {
                inset-block-start: 20%;
                inset-inline-end: 38%;
                @media screen and (min-width: 550px) and (max-width: 810px) {
                    inset-inline-end: 40%;
                }
                @media screen and (min-width: 450px) and (max-width: 550px) {
                    inset-inline-end: 35%;
                }
                @media screen and (min-width: 300px) and (max-width: 450px) {
                    inset-inline-end: 23%;
                }
                @media screen and (min-width: 200px) and (max-width: 300px) {
                    inset-inline-end: 20%;
                }
            }

            @include desktop {
                inset-block-start: -10px;
                inset-inline-end: -12px;
            }
        }
    }

    &-Nav {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        height: var(--header-nav-height);
        background-color: var(--header-nav-bg-color);
        @include mobile {
            display: none;
            background-color: var(--primary-base-color);
            color: var(--color-white);
        }
        @include desktop {
            padding: 30px;
            transition: all 0.7s;
            background-color: var(--header-sticky-nav-bg-color);

            .Header-MyFavorites_button span {
                cursor: pointer;
                background-color: var(
                    --header-sticky-favorites-button-bg-color
                );
            }
            .Header-MyCart_button span {
                background-color: var(--header-sticky-cart-button-bg-color);
            }
            .Header-MyFavorites_button > i {
                color: var(--header-sticky-favorites-button-icon-color);
                &:hover {
                    color: var(
                        --header-sticky-favorites-button-icon-hover-color
                    );
                }
            }
            .Header-MyCart_button > i {
                color: var(--header-sticky-cart-button-icon-color);
                &:hover {
                    color: var(--header-sticky-cart-button-icon-hover-color);
                }
            }
            a {
                color: var(--header-sticky-shop-menu-color);
                &:hover {
                    color: var(--header-sticky-shop-menu-hover-color);
                }
            }
            .Menu-ItemCaption {
                color: var(--color-white);
                &:hover {
                    color: var(--primary-alt-light-color);
                }
            }
            .Header-MyAccount_left i {
                color: var(--header-sticky-account-icon-color);
                display: flex;
                &:hover {
                    color: var(--header-sticky-account-icon-color);
                }
            }
            .Header-MyAccount_right a {
                color: var(--header-sticky-account-login-register-text-color);
                text-align: center;
                padding-right: 5px;
                &:hover {
                    color: var(
                        --header-sticky-account-login-register-text-hover-color
                    );
                }
            }
            .Header-MyAccount_right span {
                color: var(--header-sticky-account-login-register-text-color);
                font-size: 17px;
                padding-right: 2px;
            }
            .Menu-Item:first-child .Menu-Link {
                &::before {
                    color: var(--header-sticky-shop-menu-color);
                }
                .Menu-ItemCaption {
                    color: var(--header-sticky-shop-menu-color);
                }
                &:hover {
                    &::before {
                        color: var(--header-sticky-shop-menu-hover-color);
                    }
                    .Menu-ItemCaption {
                        color: var(--header-sticky-shop-menu-hover-color);
                    }
                }
            }
        }
    }

    &-News {
        align-items: center;
        display: flex;

        &Button {
            font-size: 12px;
            margin-inline-start: 5px;
        }

        .ExclamationMarkIcon {
            inset-block-end: 1px;
            height: 16px;
            margin-inline-end: 10px;
            width: 16px;
        }
    }

    &-Title {
        color: var(--header-color);
        margin: auto;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 0;
        // text-align: center;
        text-overflow: ellipsis;
        inset-block-start: -1px;
        inset-inline-start: 0;
        inset-block-end: 0;
        transition-duration: 200ms;
        transition-property: opacity;
        white-space: nowrap;
        width: 100%;
        text-transform: inherit;
        line-height: 30px;
        height: 30px;
        letter-spacing: 1px;

        &_isVisible {
            @include mobile {
                opacity: 1;
                font-size: 15px;
                max-width: calc(100% - 80px);
            }
        }
    }

    &-Welcome {
        display: none;
        font-size: 14px;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        @include desktop {
            align-self: center;
            color: #ba8416;
            font-weight: 700;
            letter-spacing: 1px;
            margin-right: 10px;
        }
        @include wide-desktop {
            display: block;
        }
    }

    &-TopMenu,
    &-Nav {
        width: 100vw;
        display: flex;
        margin: 0px;
        // justify-content: center;
        max-width: var(--content-wrapper-width);
        transition: all 0.8s;
    }

    &-TopMenu {
        display: flex;
        justify-content: space-between;
        height: var(--header-top-menu-height);
        padding-inline: 32px;
        padding-block-start: 16px;
    }

    &-Switcher {
        align-items: center;
        display: flex;
        color: #e1e1e1;
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
            }

            &-Button_type_close,
            &-Button_type_back {
                @include desktop {
                    display: none;
                }
                @include mobile {
                    display: inline-block;
                }
            }

            &-MyAccount {
                @include desktop {
                    margin-inline-start: auto;
                    height: 24px;
                }

                @include mobile {
                    position: absolute;
                    inset-inline-end: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include mobile {
                inset-block-start: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: var(--header-total-height);
        @include mobile {
            // margin-bottom: calc(var(--header-total-height) - 5px);
        }
        &_isPrerendered {
            @include mobile {
                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    & > * {
                        display: none;
                    }

                    .Header {
                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    &_name_popup {
        z-index: 400;
    }

    &-MyAccountContainer {
        display: flex;
        margin-inline-start: auto;
        margin-inline-end: 10px;
    }

    &-ShareBtn {
        position: absolute;
        right: 15px;
        opacity: 0;
        display: none;
        .ShareIcon {
            fill: white;
        }
        &_isVisible {
            @include mobile {
                display: block;
                opacity: 1;
            }
        }
    }

    &-VoiceBtn {
        position: absolute;
        right: 10px;
        top: 8px;
        opacity: 0;
        display: none;
        .ShareIcon {
            fill: white;
        }
        @media screen and (max-width: 360px) {
            right: 10px;
        }
        &_isVisible {
            @include mobile {
                display: block;
                opacity: 1;
            }
        }
    }
    &-ListenVoiceSearch {
        position: fixed;
        width: 100%;
        bottom: 40px;
        height: 0;
        transition: all 0.3s linear;
        transform: translateY(50px);
        background-color: rgb(226, 235, 233);
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        background-image: radial-gradient(
            circle at top center,
            rgb(226, 235, 233) 63%,
            white 63%
        );
    }

    .isActive {
        height: 300px;
        transform: translateY(-10px);
        z-index: 98;
    }
}
