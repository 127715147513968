.LifelyTheory {
    &-in {
        padding: 20px 50px;
        background-color: beige;
        @include mobile {
            padding: 10px 30px;
        }
        @import "./DnaRings.style.scss";
        .title {
            padding: 5px;
            @include desktop{
                padding-top: 3em;
                padding-bottom: 5em;
            }
            h3 {
                text-align: center;
                text-transform: uppercase;
                padding: 10px;
                padding-left: 25px;
                margin: 0px;
                font-family: "Riffic";
                letter-spacing: 3.5px;
                color: var(--primary-alt-base-color);
                font-size: 25px;
                line-height: 50px;
                @include mobile {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
            .content {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                h2 {
                    color: #2c4c32;
                    font-family: "Riffic", sans-serif;
                    font-size: var(--cms-h2-size);
                    letter-spacing: 3px;
                    height: 80px;
                    @include mobile {
                        font-size: 1.6em;
                        height: 50px;
                    }
                }

                img {
                    width: 200px !important;
                    @include mobile {
                        width: 130px !important;
                    }
                    padding: 0px 5px;
                }
            }
        }
        .divider {
            height: 2px;
            width: 50vw;
            margin: auto;
            background-color: #2c4c32;
            justify-content: center;
            opacity: 0.5;
        }
        .contentGrid {
            text-align: center;
            @include desktop() {
                display: grid;
                grid-template-columns: 1fr 4fr;
                grid-gap: 70px;
                @media screen and (min-width: 811px) and (max-width: 900px) {
                    grid-template-columns: 2fr 3fr;
                }
            }
            .parts {
                height: fit-content;
                @include desktop {
                    display: grid;
                    grid-template-rows: repeat(3, 1fr);
                    grid-gap: 10px;
                }
                @include mobile {
                    grid-gap: 0px;
                    margin-top: 2em;
                }
                .part {
                    padding: 5px;
                    &.reverse {
                        // flex-direction: row-reverse;
                        // .content-text {
                        //     align-content: flex-end;
                        // }
                    }
                    @include mobile {
                        height: fit-content;
                        flex-direction: column;
                        justify-content: center;
                        // &.reverse {
                        //     flex-direction: column;
                        // }
                    }
                    display: flex;
                    align-items: center;
                    @media screen and (min-width: 811px) and (max-width: 900px) {
                        margin-left: 10%;
                        flex-direction: column;
                    }
                    img {
                        @include desktop {
                            width: 150px !important;
                            height: auto !important;
                            margin-right: 30px;
                        }
                        @include mobile() {
                            width: 130px !important;
                            height: auto !important;
                        }
                        padding: 20px 20px;
                    }
                    .content-text {
                        // padding: 5px 30px;
                        width: 100%;
                        @include desktop {
                            .desktopEquation {
                                display: block;
                            }
                            .mobileEquation {
                                display: none;
                            }
                        }
                        @include mobile {
                            .desktopEquation {
                                display: none;
                            }
                            .mobileEquation {
                                display: block;
                            }
                        }
                        h2 {
                            font-size: var(--cms-h2-size);
                            color: #923e16;
                            font-weight: bold;
                            font-family: "Avenir", sans-serif;
                            text-transform: none !important;
                            letter-spacing: 1.5px;
                            text-align: left;
                            sub {
                                letter-spacing: 1px;
                                font-size: 15px;
                            }
                            @include mobile {
                                margin-bottom: 0px !important;
                                margin-top: -1em;
                                text-align: center !important;
                                line-height: 27px;
                            }
                        }
                        span {
                            font-size: var(--cms-p-size);
                            color: grey;
                            letter-spacing: 1px;
                            @include desktop {
                                display: block;
                                text-align: left;
                            }
                        }
                        p {
                            font-size: var(--cms-p-size);
                            line-height: var(--cms-p-line-height);
                            text-align: left;
                            font-weight: 500;
                            letter-spacing: 1px;
                            strong {
                                font-size: 20px;
                            }
                            @include desktop {
                                margin-top: 1em;
                                line-height: 27px;
                            }
                            @include mobile {
                                margin-top: 2em;
                                line-height: 22px;
                            }
                            &.eq1 {
                                opacity: 0.9;
                                color: #355f3f;
                                padding: 10px;
                                background: url('data:image/svg+xml,\
                                <svg xmlns="http://www.w3.org/2000/svg">\
                                <style>@keyframes foo {to {stroke-dashoffset: -15px}}<\/style>\
                                <rect width="100%" height="100%" style="stroke:darkolivegreen; stroke-width: 4px; fill:beige;\
                                stroke-dasharray: 10px 5px; animation: foo 2s infinite linear" />\
                                </svg>');
                                line-height: 22px;
                                font-size: 18px;
                                @include desktop {
                                    text-align: left;
                                }
                                text-align: center;
                            }
                            &.eq2 {
                                opacity: 0.9;
                                color: #ba8416;
                                padding: 10px;
                                background: url('data:image/svg+xml,\
                                <svg xmlns="http://www.w3.org/2000/svg">\
                                <style>@keyframes foo {to {stroke-dashoffset: -15px}}<\/style>\
                                <rect width="100%" height="100%" style="stroke:peru; stroke-width:4px; fill:beige;\
                                stroke-dasharray: 10px 5px; animation: foo 2s infinite linear reverse" />\
                                </svg>');
                                line-height: 22px;
                                font-size: 16px;
                                @include desktop {
                                    text-align: left;
                                }
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        .svgline {
            top: 10px;
            height: 100vh;
            overflow: visible;
            visibility: hidden;
            @include ultra-narrow-desktop {
                height: 100vh;
            }
            @include narrow-desktop {
                height: 80vh;
            }
            @include wide-desktop {
                height: 74vh;
            }
            @media screen and (min-width: 1180px) and (max-width: 1280px) {
                height: 74vh;
            }
            @include mobile {
                display: none;
            }
        }

        .ball {
            fill: #a04f1a;
            visibility: hidden;
        }

        .line {
            fill: none;
            stroke: darkgrey;
            stroke-width: 2px;
            display: none;
        }
        text {
            fill: black;
            font-size: 15px;
            font-family: "Signika Negative", sans-serif;
            visibility: hidden;
        }
        .text01,
        .text03,
        .text05 {
            fill: #2c4c32;
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
}
