/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.CookiePopup {
    --cookie-popup-bottom-margin: 10px;

    
    position: fixed;
    inset-block-end: var(--cookie-popup-bottom-margin);
    display: flex;
    opacity: 0.9;
    align-items: center;
    width: fit-content;
    z-index: 200;
    background-color: black;
    @include desktop {
        inset-inline-start: 50%;
        transform: translateX(-50%);
        padding: 5px 10px;
        border-radius: 15px;
        
        &:dir(rtl) {
            transform: translateX(50%);
        }
    }
    
    @include mobile {
        border-radius: 15px;
        inset-block-end: calc(
            var(--footer-total-height) + var(--cookie-popup-bottom-margin)
        );
        flex-direction: column;
        width: calc(100% - 32px);
        margin-inline: 16px;
        padding: 8px 16px;
    }

    &-Content {
        color: white;
        font-size: 12px;
        font-weight: 700;
        margin-block-end: 0;
        text-align: center;
        @include desktop{
            font-size: 13px;
            letter-spacing: 1px;
            padding: 10px 15px;
        }
        @include mobile{
            font-size: 13px;
            letter-spacing: 1px;
            padding: 5px 10px;
            font-weight: 400;
            line-height: 20px;
        }
        &::after {
            content: ".";
            color: var(--color-white);
        }
    }

    &-Link {
        font-size: 15px;
        color: var(--primary-alt-light-color);
        @include desktop{
            margin-left: 1em;
        }
        &:hover{
            color: var(--primary-alt-light-color);
        }
    }

    &-CTA {
        color: var(--primary-base-color);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;
        white-space: nowrap;

        @include desktop {
            background-color: var(--primary-alt-light-color);
            padding: 10px 15px;
            border-radius: 15px;
            // margin-inline-start: 41px;
        }

        @include mobile {
            margin-block-start: 12px;
            background-color: var(--primary-alt-light-color);
            padding: 10px;
            border-radius: 15px;
        }

        &:hover {
            color: var(--primary-dark-color);
        }
    }
}
