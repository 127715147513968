/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.LifelyStoryMobile {
    //colors
    --grey-color: rgb(110, 108, 108);
    --unhealthy-bg-color: #f6ede8;
    --healthy-bg-color: beige;

    //font-size
    --p-font-size: 16px;
    --span-font-size: 16px;
    --li-font-size: 16px;
    --h2-font-size: 1.2em;
    --h3-font-size: 18px;

    // line height
    --h2-line-height: 30px;
    --h3-line-height: 27px;
    --span-line-height: 25px;
    --p-line-height: 30px;
    --li-line-height: 27px;

    //letter spacing
    --span-letter-spacing: 1px;
    --p-letter-spacing: 1px;
    --li-letter-spacing: 1px;
    --h2-letter-spacing: 1px;
    --h3-letter-spacing: 1px;
    margin-bottom: 4em;
    #MobileProgressbarWrapper {
        // display: none;
        opacity: 0;
    }

    svg {
        width: 100%;
        margin: 0 auto;
        bottom: -5px;
    }

    .progressMobActive {
        opacity: 1 !important;
        transition: 1s;
    }
    .progressMobInactive {
        opacity: 0;
        transition: 1s;
    }

    /* change color of the elements */
    .progressStrokesCol {
        stroke: #2c4c32;
        fill: none;
    }
    .progressStrokesFill {
        fill: #ba8416;
    }
    .progressbarContainer {
        background-color: #323f31;
        position: fixed;
        width: 100%;
        height: fit-content;
        left: 0;
        top: 0;
        z-index: 200;
        // padding: 5px 0 0 0;
    }
    .nodeBckgrnd {
        fill: #b88523 !important;
    }

    .progressText {
        fill: white;
        font-size: 12px;
        font-family: sans-serif;
    }

    /* progress line  */
    .progressLine {
        stroke: #fff;
        stroke-width: 2;
        stroke-dasharray: 800;
        stroke-dashoffset: -800;
    }
    .progressLineMob {
        stroke: #fff;
        stroke-width: 2;
        stroke-dasharray: 470;
        stroke-dashoffset: -470;
    }
    // #navProgMob {
    //     display: none;
    // }
    // #navProg{
    //     display: block;

    // }
    .Heading {
        background-color: var(--unhealthy-bg-color);
        text-align: center;
        padding-block: 20px;
        h2 {
            margin: 0;
            color: var(--primary-alt-dark-color);
            font-size: 22px;
            line-height: 40px;
            letter-spacing: 3px;
            font-weight: 100 !important;
        }
        span {
            // margin-top: 20px;
            color: var(--grey-color);
            text-align: center;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 2px;
            font-weight: 100 !important;
        }
    }

    //****************************************SECTION 1**********************************************

    //****************************************COMMON-PART SECTION-1*****************************************
    #section1 {
        .s1b1,
        .s1b2,
        .s1b3,
        .s1b4,
        .s1b5,
        .s1b6 {
            padding: 10px 20px;
            background-color: var(--unhealthy-bg-color);
            .HorizontalLine {
                height: 1px;
                width: 50%;
                background-color: var(--primary-light-color);
                left: 25%;
            }
            .StorySectionContent {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: var(--unhealthy-bg-color);
                padding: 40px 0;
                .ImageContent {
                    padding: 0px 10px;
                    margin-block: 2em;
                    text-align: center;
                    width: 100%;
                    img {
                        width: 70%;
                    }
                }

                span {
                    // margin-top: 20px;
                    color: var(--grey-color);
                    text-align: center;
                    font-size: 18px;
                    line-height: 30px;
                    letter-spacing: 1px;
                    font-weight: 100 !important;
                }

                h2 {
                    margin: 0;
                    color: var(--primary-alt-dark-color);
                    font-size: 22px;
                    line-height: 40px;
                    letter-spacing: 3px;
                    font-weight: 100 !important;
                }

                p {
                    color: var(--grey-color);
                    line-height: var(--p-line-height);
                    font-size: var(--p-font-size);
                    letter-spacing: var(--p-letter-spacing);
                }
            }
        }

        //****************************************SEPERATE-PART SECTION-1**************************************

        .s1b1 {
            .StorySectionContent {
                .imgWorld {
                    width: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 70%;
                    }
                    .imgSugerCubes {
                        width: 100%;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 50%;
                        }
                    }
                }
            }
        }

        .s1b3 {
            .VillainsImage {
                width: 70%;
            }
            .StorySectionContent {
                .listBtn {
                    justify-self: center;
                    margin: 20px 0;
                    border: none;
                    border-radius: 30px;
                    width: auto;
                    letter-spacing: 2px;
                    background-color: white;
                    color: var(--primary-alt-base-color);
                    box-shadow: 0px 0px 20px 0px rgb(167, 167, 167);
                }
            }
        }

        .s1b5 {
            .StorySectionContent {
                width: 100%;
                span,
                h2 {
                    align-self: center;
                }
                align-items: start;
                .listbtnWrapper {
                    width: 100%;
                    text-align: center;
                    .listBtn {
                        justify-self: center;
                        margin: 20px 0;
                        border: none;
                        border-radius: 30px;
                        width: auto;
                        letter-spacing: 2px;
                        background-color: white;
                        color: var(--primary-alt-base-color);
                        box-shadow: 0px 0px 20px 0px rgb(167, 167, 167);
                        transition: all 0.2s linear;
                        &:hover {
                            transform: translateY(10px) !important;
                            box-shadow: 2px 10px 15px 0px rgb(121, 121, 121),
                                -2px 10px 10px 0px rgb(121, 121, 121);
                            background-color: var(--primary-alt-base-color);
                            color: var(--color-white);
                        }
                    }
                }
            }
            .IconContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .s1b5Icon1,
                .s1b5Icon2,
                .s1b5Icon3,
                .s1b5Icon4,
                .s1b5Icon5,
                .s1b5Icon6 {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 10px 0;
                    .IconBox {
                        img {
                            box-shadow: -3px -3px 5px rgb(255, 255, 255),
                                3px 3px 5px rgb(160, 154, 150);
                            border-radius: 50%;
                            padding: 10px;
                            min-width: 70px;
                            min-height: 70px;
                            width: 70%;
                            &:hover {
                                box-shadow: -3px -3px 5px rgb(255, 255, 255) inset,
                                    3px 3px 5px rgb(160, 154, 150) inset;
                            }
                        }
                    }
                    .IconText {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        h3 {
                            margin: 0;
                            color: var(--primary-alt-base-color);
                            font-weight: 400 !important;
                            font-size: var(--h3-font-size);
                            letter-spacing: var(--h3-letter-spacing);
                            line-height: var(--h3-line-height);
                        }
                        p {
                            margin: 0;
                            color: var(--grey-color);
                            font-size: var(--p-font-size);
                            letter-spacing: var(--p-letter-spacing);
                            line-height: var(--p-line-height);
                        }
                    }
                }
            }
        }

        //********************************* .S1B6 *******************************************
        .s1b6 {
            .StorySectionContent {
                h2 {
                    margin-bottom: 10px;
                }
                span {
                    margin-top: 10px;
                }
            }
            .CmsFooter {
                p {
                    margin-top: 10px;
                    text-align: center;
                    color: var(--grey-color);
                    background-color: var(--unhealthy-bg-color);
                    font-size: var(--p-font-size);
                    letter-spacing: var(--p-letter-spacing);
                    line-height: var(--p-line-height);
                }
            }
            .IconContainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin: 5em 0;
                .s1b6Icon1,
                .s1b6Icon2,
                .s1b6Icon3,
                .s1b6Icon4,
                .s1b6Icon5 {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 10px 0;
                    .IconBox {
                        img {
                            box-shadow: -3px -3px 5px rgb(255, 255, 255),
                                3px 3px 5px rgb(160, 154, 150);
                            border-radius: 50%;
                            padding: 10px;
                            min-width: 70px;
                            min-height: 70px;
                            width: 70%;
                            &:hover {
                                box-shadow: -3px -3px 5px rgb(255, 255, 255) inset,
                                    3px 3px 5px rgb(160, 154, 150) inset;
                            }
                        }
                    }
                    .IconText {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        h3 {
                            margin: 0;
                            color: var(--primary-alt-base-color);
                            font-weight: 400 !important;
                            font-size: var(--h3-font-size);
                            letter-spacing: var(--h3-letter-spacing);
                            line-height: var(--h3-line-height);
                        }
                        p {
                            margin: 0;
                            color: var(--grey-color);
                            font-size: var(--p-font-size);
                            letter-spacing: var(--p-letter-spacing);
                            line-height: var(--p-line-height);
                        }
                    }
                }
            }
        }
    }

    //****************************************SECTION 2**************************************

    //****************************************COMMON-PART SECTION-2*****************************************

    #section2 {
        .s2b1,
        .s2b2,
        .s2b3 {
            padding: 10px 20px;
            background-color: var(--healthy-bg-color);
            .HorizontalLine {
                height: 1px;
                width: 50%;
                background-color: var(--primary-light-color);
                text-align: center;
                left: 25%;
            }
            .StorySectionContent {
                padding: 40px 0;
                span {
                    display: block;
                    color: var(--grey-color);
                    text-align: center;
                    font-size: 18px;
                    line-height: 30px;
                    letter-spacing: 1px;
                    font-weight: 100 !important;
                }

                h2 {
                    // margin: 5px 0px 10px 0px;
                    color: var(--primary-base-color);
                    margin: 0;
                    font-size: 22px;
                    line-height: 40px;
                    letter-spacing: 3px;
                    font-weight: 100 !important;
                }

                p {
                    margin: 20px 0;
                    text-align: center;
                    color: var(--grey-color);
                    font-size: var(--p-font-size);
                    line-height: var(--p-line-height);
                    letter-spacing: var(--p-letter-spacing);
                }

                .ImageContent {
                    padding: 0px 10px;
                    margin-block: 2em;
                    text-align: center;
                    img {
                        width: 70%;
                    }
                }

                ul {
                    margin-top: 20px;
                    li {
                        color: var(--grey-color);
                        font-size: var(--li-font-size);
                        line-height: var(--li-line-height);
                        letter-spacing: var(--li-letter-spacing);

                        &::before {
                            content: "\2713";
                            position: relative;
                            left: 0;
                            color: var(--primary-base-color);
                            font-size: 1.1em;
                        }
                    }
                }
            }
            .CmsFooter {
                margin-top: 4em;
                bottom: 20px;
                .RoundShape {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    row-gap: 2em;
                    .s2b1Round1,
                    .s2b1Round2,
                    .s2b1Round3,
                    .s2b1Round4,
                    .s2b1Round5 {
                        text-align: center;
                        p {
                            padding-top: 20px;
                            margin: 0;
                            text-align: center;
                            color: var(--grey-color);
                            font-size: var(--p-font-size);
                            line-height: var(--p-line-height);
                            letter-spacing: var(--p-letter-spacing);
                        }
                        img {
                            width: 60%;
                        }
                    }
                }
            }
        }
        .s2b2 {
            .StorySectionContent {
                #s2b2ImageContent {
                    img {
                        width: 60%;
                    }
                }
            }
        }
        .s2b3 {
            .StorySectionContent {
                #s2b3ImageContent {
                    img {
                        width: 60%;
                    }
                }
            }
        }
    }

    //****************************************SECTION 3**************************************

    //****************************************COMMON-PART SECTION-3*****************************************
    #section3 {
        .s3b1,
        .s3b2 {
            padding: 10px 20px;
            background-color: var(--healthy-bg-color);
            .HorizontalLine {
                height: 1px;
                width: 50%;
                background-color: var(--primary-light-color);
                text-align: center;
                left: 25%;
            }
            .StorySectionContent {
                padding: 40px 0;
                p {
                    text-align: center;
                    color: var(--grey-color);
                    font-size: var(--p-font-size);
                    letter-spacing: var(--p-letter-spacing);
                    line-height: var(--p-line-height);
                }

                span {
                    color: var(--grey-color);
                    text-align: center;
                    font-size: 18px;
                    line-height: 30px;
                    letter-spacing: 1px;
                    font-weight: 100 !important;
                }

                h3 {
                    margin: 0;
                    text-align: center;
                    font-size: var(--h3-font-size);
                    letter-spacing: var(--h3-letter-spacing);
                    line-height: var(--h3-line-height);
                    font-weight: 100 !important;
                }
                h2 {
                    text-align: center;
                    margin: 0;
                    color: var(--primary-alt-dark-color);
                    font-size: 22px;
                    line-height: 40px;
                    letter-spacing: 3px;
                    font-weight: 100 !important;
                }
                .ImageContent {
                    display: flex;
                    justify-content: center;
                    padding: 0px 10px;
                    margin-block: 2em;
                    text-align: center;
                    p {
                        font-size: var(--span-font-size);
                        letter-spacing: var(--span-letter-spacing);
                        line-height: var(--span-line-height);
                    }
                }
            }
            .CmsFooter {
                h2 {
                    margin: 0;
                    padding: 40px 5px;
                    text-align: center;
                    color: var(--primary-base-color);
                    font-size: var(--h2-font-size);
                    letter-spacing: var(--h2-letter-spacing);
                    line-height: var(--h2-line-height);
                }
            }
        }
        .s3b1 {
            .StorySectionContent {
                #s3b1ImageContent1 {
                    img {
                        width: 70%;
                    }
                }
            }
        }
        .s3b2 {
            .StorySectionContent {
                .TitleContent {
                    .TitlePart {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding-top: 2em;
                        .IngredientCount {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background-color: var(--primary-alt-light-color);
                            h2 {
                                margin: 0;
                                color: white;
                            }
                        }
                    }

                    .IngredientPart {
                        display: grid;
                        margin-top: 10px;
                        grid-template-columns: repeat(2, 3fr);
                        justify-content: center;
                        column-gap: 1em;
                        row-gap: 1em;
                        .ImgWrapper {
                            width: 100px;
                            height: 100px;
                        }
                        .s3b2Round1,
                        .s3b2Round2,
                        .s3b2Round3,
                        .s3b2Round4,
                        .s3b2Round5,
                        .s3b2Round6 {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            img {
                                background-color: white;
                                padding: 15px;
                                border-radius: 50%;
                            }
                            p {
                                padding-top: 15px;
                                text-align: center;
                                color: var(--grey-color);
                                font-size: var(--p-font-size);
                                letter-spacing: var(--p-letter-spacing);
                                line-height: var(--p-line-height);
                            }
                        }
                    }

                    .ImageContent {
                        display: flex;
                        justify-content: center;
                        img {
                            width: 100%;
                        }
                    }
                }
                .TextContent {
                    display: flex;
                    flex-direction: column;

                    .ImprovesPart {
                        width: 100%;
                        padding-top: 3em;
                        h2 {
                            text-align: center;
                            margin: 0;
                            color: var(--primary-alt-dark-color);
                            font-size: 22px;
                            line-height: 40px;
                            letter-spacing: 2.5px;
                            font-weight: 100 !important;
                        }
                        .ImprovesHeadingHorizontalLine {
                            margin-bottom: 1em;
                            width: 50%;
                            height: 2px;
                            background-color: var(--primary-alt-light-color);
                            left: 50%;
                            transform: translateX(-50%);
                        }
                        .imgbgImprove {
                            text-align: center;
                            width: 100%;
                            position: relative;
                            img {
                                width: 20%;
                            }
                        }
                        .imgthumbsUp {
                            text-align: center;
                            width: 100%;
                            position: absolute;
                            top: 20%;
                            img {
                                width: 10%;
                            }
                        }
                        .Improves {
                            .ImprovesList {
                                ul {
                                    li {
                                        color: var(--grey-color);
                                        font-size: var(--li-font-size);
                                        line-height: var(--li-line-height);
                                        letter-spacing: var(
                                            --li-letter-spacing
                                        );
                                        &::before {
                                            content: "\2713";
                                            position: relative;
                                            left: 0;
                                            color: var(--primary-base-color);
                                            font-size: 1.1em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ControlsPart {
                        width: 100%;
                        padding-top: 3em;
                        h2 {
                            text-align: center;
                            margin: 0;
                            color: var(--primary-alt-dark-color);
                            font-size: 22px;
                            line-height: 40px;
                            letter-spacing: 2.5px;
                            font-weight: 100 !important;
                        }
                        .ControlsHeadingHorizontalLine {
                            margin-bottom: 1em;
                            width: 50%;
                            height: 2px;
                            background-color: var(--primary-alt-light-color);
                            left: 50%;
                            transform: translateX(-50%);
                        }
                        .imgbgControl {
                            text-align: center;
                            width: 100%;
                            position: relative;
                            img {
                                width: 20%;
                            }
                        }
                        .imgthumbsDown {
                            text-align: center;
                            width: 100%;
                            position: absolute;
                            top: 25%;
                            img {
                                width: 10%;
                            }
                        }
                        .Controls {
                            .ControlsList {
                                ul {
                                    li {
                                        color: var(--grey-color);
                                        font-size: var(--li-font-size);
                                        line-height: var(--li-line-height);
                                        letter-spacing: var(
                                            --li-letter-spacing
                                        );
                                        &::before {
                                            content: "\21AF";
                                            position: relative;
                                            left: 0;
                                            color: var(--primary-base-color);
                                            font-size: 1.1em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //****************************************SECTION 4**************************************

    #section4 {
        .s4b1 {
            padding: 10px 20px;
            background-color: var(--healthy-bg-color);
            .HorizontalLine {
                height: 2px;
                width: 50%;
                background-color: var(--primary-alt-light-color);
                left: 50%;
                transform: translateX(-50%);
            }
            .HeroRibbon {
                right: -14%;
                width: 85%;
                position: relative;
                background-color: var(--primary-light-color);
                text-align: center;
                padding: 5px 0px;
                @media screen and (max-width: 350px) {
                    right: 0;
                }
                span {
                    display: block;
                    padding: 0px 5px;
                    color: var(--color-white);
                    line-height: var(--span-line-height);
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
                @media screen and (min-width: 350px) {
                    &:before {
                        content: "";
                        position: absolute;
                        display: block;
                        border: 17.5px solid var(--primary-light-color);
                        bottom: 0px;
                        left: -2.4em;
                        border-right-width: 1.5em;
                        border-left-color: transparent;
                    }
                }
                @media screen and (min-width: 320px) and (max-width: 350px) {
                    &:before {
                        display: none !important;
                    }
                }
            }
            .HeroineRibbon {
                left: 0%;
                width: 85%;
                position: relative;
                background-color: var(--primary-alt-light-color);
                text-align: center;
                padding: 5px 0px;
                span {
                    display: block;
                    padding: 0px 5px;
                    color: var(--color-white);
                    line-height: var(--span-line-height);
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }

                @media screen and (min-width: 350px) {
                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        border: 17.5px solid var(--primary-alt-light-color);
                        bottom: 0px;
                        right: -2.5em;
                        border-left-width: 1.5em;
                        border-right-color: transparent;
                    }
                }
                @media screen and (min-width: 320px) and (max-width: 350px) {
                    &::after {
                        display: none !important;
                    }
                }
            }

            .s4b1spantag {
                margin-bottom: 5px;
                display: block;
                text-align: center;
                color: var(--grey-color);
                font-size: var(--span-font-size);
                letter-spacing: var(--span-letter-spacing);
                line-height: var(--span-line-height);
            }
            h2 {
                text-align: center;
                margin: 0;
                color: var(--primary-base-color);
                font-size: var(--h2-font-size);
                line-height: 40px;
                text-transform: uppercase;
                font-weight: 100;
                letter-spacing: 3px;
            }
            .s4b1SuperHero {
                // display: grid;
                // grid-template-columns: 1.5fr 2fr 1.5fr;
                // justify-content: center;
                // align-items: center;
                padding: 20px 0;
                // .LifelyHero {
                //     text-align: center;
                //     img {
                //         width: 130%;
                //     }
                // }
                // .LifelyHeroine {
                //     text-align: center;
                //     right: 20%;
                //     img {
                //         width: 130%;
                //     }
                // }
            }
            .s4b1SuperHeroin {
                display: grid;
                grid-template-columns: 2fr 2fr;
                justify-content: center;
                align-items: center;
                padding: 10px 0;
                @media screen and (max-width: 350px) {
                    display: flex;
                }
            }
            .ShopNowContent {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transform: translateY(20px);
                .lifelyStoreImg{
                    margin-bottom: 2em;
                }
                .superFoodBtn {
                    border-radius: 30px;
                    width: fit-content;
                    letter-spacing: 2px;
                    background-color: transparent;
                    color: var(--primary-alt-light-color);
                    border-color: rgba(186, 132, 22, 0.6);
                    transition: all 0.3s linear;
                }
                .SuperFoodStore {
                    padding: 15px 5px;
                    text-align: center;
                    color: var(--grey-color);
                    font-size: var(--p-font-size);
                    font-weight: 700;
                    letter-spacing: var(--p-letter-spacing);
                    line-height: var(--p-line-height);
                    margin-bottom: 5px;
                }
            }
        }
    }
}
